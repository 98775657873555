/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import GridSelect from 'components/common/GridSelect';
import store from 'store';
import { setBackground, unsetBackground } from 'actions/scene';
import Grid from 'components/common/Grid';
import classnames from 'classnames';
import Button from 'components/common/Button';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import find from 'lodash/find';
import get from 'lodash/get';
import { setCurrentBackground } from 'api/utils';
import './Backgrounds.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };

const selectBackgroundOptions = [
  {
    value: true,
    label: 'Image',
  },
  {
    value: false,
    label: 'Color',
  },
];

const OptionComponent = ({ value, selected, colors }) => {
  const color = find(colors, { value }) || {};
  return (
    <div className="color-option-container color-picker">
      <svg width="100%" height="100%">
        <circle
          cx="50%"
          cy="50%"
          r={selected ? '42%' : '42%'}
          stroke={selected ? '#362a62' : '#bebfc1'}
          strokeWidth={selected ? '4' : '2'}
          fill="white"
        />
        <circle
          cx="50%"
          cy="50%"
          r="34%"
          stroke="#bebfc1"
          strokeWidth="0.5"
          fill={color.value}
          style={{ cursor: 'pointer' }}
        />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
  );
};

const Photo = ({ id, src, thumbnailSrc, isActive, onClick, ...rest }) => {
  const handleClick = useCallback(() => {
    store.dispatch(setBackground({ id, src, thumbnailSrc, ...rest }));
  }, [id, rest, src, thumbnailSrc]);
  return (
    <Grid.Item key={id} innerClassName={classnames({ checked: isActive })}>
      <div>
        <img
          alt={id}
          src={thumbnailSrc}
          onClick={onClick || handleClick}
        />
      </div>
    </Grid.Item>
  );
};
const UnsetBackground = ({ isActive, thumbnailSrc }) => {
  const onClick = useCallback(() => {
    store.dispatch(unsetBackground());
  }, []);
  return (
    <Grid.Item key="none" innerClassName={classnames({ checked: isActive })}>
      <div>
        <img
          alt="none"
          src={thumbnailSrc}
          onClick={onClick}
        />
      </div>
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);

const Customize = ({ onClickBackButton }) => {
  const { backgroundOptions, currentBackground } = useSelector(({ scene: { config: { backgroundOptions, currentBackground } } }) => ({ backgroundOptions, currentBackground }));
  const photos = useSelector(({ photos: { backgrounds } }) => backgrounds || []);
  const mode = useSelector(({ scene: { config: { mode } } }) => mode);
  const sizeCalculated = photos.length ? '50%' : '100%';
  const currentBackgroundImageId = useSelector(({ scene: { objects } }) => {
    const background = find(objects, { type: 'background' });
    if (background) {
      return background.image.id;
    }
    return false;
  });

  const [selectBackground, setSelectBackground] = useState(!!currentBackgroundImageId);
  return (
    <div className="colors-container">
      {mode !== 'photo-print' && (
        <>
          { onClickBackButton ? (
            <Button type="button" className="go-back-button" size="lg" textSize="md" rounded outline onClick={onClickBackButton}>
              <FontAwesomeIcon.Memo icon={faChevronLeft} style={fontAwesomeIconStyle} />Go back
            </Button>
          )
            : null }
          <MultiOptionButton options={selectBackgroundOptions} onChange={setSelectBackground} value={selectBackground} />
        </>
      )}
      {selectBackground ? (
        <Grid doNotWrapIntoMotionDiv={photos.length > 20} size={sizeCalculated}>
          <UnsetBackground
            isActive={!currentBackgroundImageId}
            id="none"
            thumbnailSrc="https://www.snapfont.com/wp-content/uploads/2020/01/img_0325.jpg"
          />
          {
          photos.map((photo) => (
            <MemoPhoto
              key={photo.id}
              isActive={currentBackgroundImageId === photo.id}
              photo={photo}
              {...photo}
            />
          ))
        }
        </Grid>
      ) : (
        <GridSelect
          options={backgroundOptions}
          OptionComponent={(props) => (
            <OptionComponent colors={backgroundOptions} {...props} />
                )}
          onChange={setCurrentBackground}
          value={get(currentBackground, 'value')}
        />
      )}


    </div>
  );
};

export default Customize;
