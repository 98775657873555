import React, { useRef, useEffect, useMemo } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';
import useTextIsEditing from 'components/editors/Editor/hooks/useTextEditing';
import { TextInSvg } from 'utils/utils';

const Text = ({ object, isEditing, onClick }) => {
  const refG = useRef();
  const updateObject = useUpdateObject();
  useEffect(() => {
    const font = new FontFaceObserver(object.fontFamily);
    font.load().then(() => {
      if (refG.current) {
        // const newTextArr = getTextArr(object);
        // if (!isEqual(newTextArr, textArr)) {
          // setTextArr(newTextArr);
          // updateObject({ id: object.id, textArr: newTextArr });
        // }
      }
    }).catch((e) => console.error('error:', e.message));
  }, [object, object.fontFamily, object.id, object.isBold, object.text, object.width, updateObject]);
  const [textEditing] = useTextIsEditing();
  const visibility = useMemo(() => (isEditing === object.id && textEditing ? 'hidden' : 'visible'), [isEditing, object.id, textEditing]);
  // console.log('visibility:', visibility);
  // const { x, textAnchor } = alignTextSvg(object.textAlign, object.width);
  return (
    <>
      <g id={`calculation-element-${object.id}`} /> {/* need for calculate text height */}
      <TextInSvg object={object} onClick={onClick} visibility={visibility} className={`text${object.id}`} defaultText />
    </>
  );
};


export default Text;
