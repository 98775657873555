import { useEffect, useState } from 'react';
import { useSelector, batch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { fitObjectToCanvas } from 'components/editors/Editor/utils';
import useUpdateObjectByIndex from './useUpdateObjectByIndex';
import useEditorSizePx from './useEditorSizePx';

// Effect to find objects that were not initialized yet and need to be placed on canvas
const useInitObjectsEffect = (objects, layout, viewWidth) => {
  const updateCanvasObjectByIndex = useUpdateObjectByIndex();
  const { width: canvasWidth, height: canvasHeight } = useEditorSizePx(viewWidth);
  const [borderType, dimensions] = useSelector(({ scene: { config: { borderType, dimensions } } }) => [borderType, dimensions], (a, b) => a[0] === b[0] && a[1] === b[1]);

  const [config, setConfig] = useState({ borderType, dimensions });
  useEffect(() => {
    const newConfig = { borderType, dimensions };
    if (!isEqual(config, newConfig)) {
      batch(() => { objects.forEach((object, index) => { updateCanvasObjectByIndex({ index, initialized: false }); }); });
      setConfig(newConfig);
    }
  }, [config, dimensions, objects, updateCanvasObjectByIndex, borderType]);

  useEffect(() => {
    batch(() => {
      objects.forEach((object, index) => {
        const aperture = { ...layout[0] };
        if (object && object.initialized === false && canvasHeight > 1 && canvasWidth > 1) {
          if (borderType === 'retro') aperture.height -= (dimensions.retroBottom / (object.rotate ? dimensions.width : dimensions.height));

          let canvasWidthPx = canvasWidth * aperture.width;
          let canvasHeightPx = canvasHeight * aperture.height;
          if (object.rotate) [canvasWidthPx, canvasHeightPx] = [canvasHeightPx, canvasWidthPx];

          const position = fitObjectToCanvas({ canvasWidthPx, canvasHeightPx, object });
          updateCanvasObjectByIndex({ index, minWidth: position.width, minHeight: position.height, brightness: object.brightness || 0, contrast: object.contrast || 0, saturation: object.saturation || 0, ...position, initialized: true });
        }
      });
    });
  }, [canvasHeight, canvasWidth, dimensions, layout, objects, updateCanvasObjectByIndex, borderType]);
};

export default useInitObjectsEffect;
