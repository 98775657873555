import ImageOverlay from 'components/editors/NewEditor/components/ImageOverlay';
import React from 'react';
import { useSelector } from 'react-redux';

const MaskCropPhoto = ({
  object,
  showOverlay,
  editorWidth,
  editorHeight,
  viewWidth,
  viewHeight,
  type,
}) => {
  const scaleOverlay = useSelector(({ scene: { config: { scaleOverlay } } }) => scaleOverlay);
  const ClippingMask = useSelector(({ scene: { config: { clippingMaskUrl } } }) => clippingMaskUrl);
  const Overlay = useSelector(({ scene: { config: { overlayUrl } } }) => overlayUrl);
  const isRotateMask = useSelector(({ scene: { config: { orientation, maskOrientation } } }) => orientation !== maskOrientation);
  return (
    <g>
      {type === 'mask' ? (
        <defs>
          <mask id="mask-clipping-mask">
            <image xlinkHref={ClippingMask} x={isRotateMask ? -object.y : -object.x} y={isRotateMask ? object.x : -object.y} width={isRotateMask ? editorHeight : editorWidth} height={isRotateMask ? editorWidth : editorHeight} transform={isRotateMask ? `translate(${editorWidth}, 0) rotate(${90}, ${0}, ${0})` : null} />
          </mask>
          <mask id="mask-clip-path">
            <rect width="100%" height="100%" fill="white" />
            <rect x={-object.x} y={-object.y} width={editorWidth} height={editorHeight} transform={`rotate(${-object.rotation || 0})`} fill="black" mask="url(#mask-clipping-mask)" />
          </mask>
          {showOverlay ? (
            <mask id="mask-overlay">
              <rect width="100%" height="100%" fill="white" />
            </mask>
          ) : null }
        </defs>
      ) : null}
      <path
        d={`M${-(viewWidth - object.width) / 2} ${-(viewHeight - object.height) / 2} h${viewWidth} v${viewHeight} h${-viewWidth} z
              M0 0 h${object.width} v${object.height} h${-object.width} z`}
        fill="#fff5"
        style={{ pointerEvents: 'none' }}
        fillRule="evenodd"
      />
      {type === 'mask' ? (
        <>
          <rect x="0" y="0" width={object.width} height={object.height} fill="#fff5" mask="url(#mask-clip-path)" style={{ pointerEvents: 'none' }} />
          {/* <svg width="100%" height="100%"> */}
          <ImageOverlay Overlay={Overlay} scaleOverlay={scaleOverlay} isRotateMask={isRotateMask} object={object} mask="url(#mask-overlay)" style={{ opacity: 0.5 }} />
          {/* </svg> */}
        </>
    ) : null}
    </g>
  );
    };

export default MaskCropPhoto;
