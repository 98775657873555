import { useCallback } from 'react';
import ReactGA from 'react-ga';
import { crop } from 'constants/GA';
import { useSelector, shallowEqual } from 'react-redux';
// import useUpdateConfig from 'hooks/useUpdateConfig';
import store from 'store';
import useUpdateTextEditing from 'hooks/useUpdateTextEditing';

export const useSetTextEditing = () => {
  const updateText = useUpdateTextEditing();
  const setIsEditing = useCallback((newValue) => {
    updateText(newValue);
    if (newValue) { ReactGA.event(crop); }
  }, [updateText]);
  return setIsEditing;
};
export const useToggleIsEditing = () => {
  const setTextEditing = useSetTextEditing();
  const toggleIsEditing = useCallback(() => { setTextEditing(!store.getState().scene.config.textEditing); }, [setTextEditing]);
  return toggleIsEditing;
};

const useTextEditing = () => {
  const textEditing = useSelector(({ scene }) => scene.config.textEditing, shallowEqual);
  const setTextEditing = useSetTextEditing();
  const toggleTextEditing = useToggleIsEditing();
  return [textEditing, toggleTextEditing, setTextEditing];
};

export default useTextEditing;
