/* eslint-disable no-async-promise-executor */
import EXIF from 'exif-js';
import { noPreviewImage } from 'constants/editor';
import sumBy from 'lodash/sumBy';
import doesBrowserRotatesImageByExif from './doesBrowserRotatesImageByExif';
import { getBase64String } from './rotatePreview';

window.URL = window.URL || window.webkitURL;
const preview = (file, { canvas, context, obj: propsObj }) => new Promise((resolve) => {
  const reader = new FileReader();
  // only extract what we need to find the thumbnail exif, keeps memory low
  const doesBrowserRotatesImageByExifBool = doesBrowserRotatesImageByExif();
  const resolveObject = { isFail: true, thumbnailSrc: noPreviewImage };
  reader.readAsArrayBuffer(file);
  reader.onload = async () => {
    let Orientation;
    try {
      Orientation = EXIF.readFromBinaryFile(reader.result).Orientation;
    } catch {
      Orientation = 0;
    }
    let tempSrc;
    // if (EXIFData.ThumbnailImage) tempSrc = EXIFData.ThumbnailImage;
    window.URL = window.URL || window.webkitURL;
    const img = document.createElement('img');
    tempSrc = tempSrc || window.URL.createObjectURL(file);
    img.onload = () => {
      /* prevent memory and performance issues */
      propsObj.debug.naturalWidth = img.naturalWidth;
      propsObj.debug.naturalHeight = img.naturalHeight;
      if ((img.naturalWidth * img.naturalHeight) > 24900000) {
        window.URL.revokeObjectURL(tempSrc);
        return resolve(resolveObject);
      }
      let { naturalWidth, naturalHeight } = img;
      const browserDoesNotSupportAutoRotationAndPhotoIsRotated = !doesBrowserRotatesImageByExifBool && canvas && context && Orientation && Orientation > 1;
      const browserSupportsAutoRotationButOrientationGt4AndBrowserIsBuggy = doesBrowserRotatesImageByExifBool && canvas && context && Orientation && Orientation > 4;
      if (browserDoesNotSupportAutoRotationAndPhotoIsRotated || browserSupportsAutoRotationButOrientationGt4AndBrowserIsBuggy) {
        const { b64 } = getBase64String({ canvas, canvasContext: context, image: img, orientation: Orientation, maxSize: 200, doesBrowserRotatesImageByExifBool });
        tempSrc = b64;
        if (browserDoesNotSupportAutoRotationAndPhotoIsRotated && Orientation > 4) [naturalWidth, naturalHeight] = [naturalHeight, naturalWidth]; // swap the width and height because they are rendered wrong in browser
      }
      const obj = { naturalWidth, naturalHeight, rotate: naturalHeight > naturalWidth, width: 100, height: (naturalHeight / naturalWidth) * 100 };
      return resolve({ ...resolveObject, ...obj, isFail: false, src: tempSrc, thumbnailSrc: tempSrc });
    };

    img.src = tempSrc; // startOnLoad
  };
});

export const generateImagePreview = (file, { doNotCreateThumbnail, canvas, context }) => new Promise(async (resolve) => {
  const { id } = file;
  const obj = { debug: { size: file.size, name: file.name }, id, naturalWidth: 144, naturalHeight: 96, width: 100, height: (96 / 144) * 100, src: noPreviewImage, thumbnailSrc: noPreviewImage };
  if (doNotCreateThumbnail) return resolve(obj);
  const { isFail, ...previewProps } = await preview(file, { canvas, context, obj });
  if (isFail) return resolve(obj);
  return resolve({ ...obj, ...previewProps });
});

export const getPrintLimitMaxMinAndObjectsLengthSync = (scene) => {
  const { config: { printLimitMax, printLimitMin }, objects } = scene;
  const photoCount = sumBy(objects, (o) => ((o && o.id) ? (o.quantity || 1) : 0));
  return [printLimitMax, printLimitMin, photoCount];
};

export const idMap = {};
