import React from 'react';
import { EDGE_WRAP } from 'constants/editor';

const stroke = '#fff';
const strokeWidth = 1;

const EdgesCrop = ({
  object,
  frameWidth,
  type,
  edgeWrap,
}) => {
  if (type === 'canvas' && edgeWrap === EDGE_WRAP && object.type === 'layout') {
  return (
    <g>
      <line x1={frameWidth} y1={0} x2={frameWidth} y2={object.height} stroke={stroke} strokeWidth={strokeWidth} />
      <line x1={object.width - frameWidth} y1={0} x2={object.width - frameWidth} y2={object.height} stroke={stroke} strokeWidth={strokeWidth} />
      <line x1={0} y1={frameWidth} x2={object.width} y2={frameWidth} stroke={stroke} strokeWidth={strokeWidth} />
      <line x1={0} y1={object.height - frameWidth} x2={object.width} y2={object.height - frameWidth} stroke={stroke} strokeWidth={strokeWidth} />
    </g>
  );
}
  return null;
};

export default EdgesCrop;
