import React, { useMemo } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import useIsMobile from 'hooks/useIsMobile';
import { faCheck, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const EditFooterClipart = ({ hide, toggleIsEditing, deleteObject }) => {
  const isMobile = useIsMobile();
  const variantsToolbar = useMemo(() => (
    isMobile ? {
      open: { opacity: 1, y: 0 },
      closed: { opacity: 0, y: '100%' },
    } : {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '-100%' },
      }
  ), [isMobile]);

  return (
    <footer style={{ width: 0 }}>
      <div className="toolbar edit">
        <motion.div className="toolbar-view-container" initial="closed" variants={variantsToolbar} animate="open" transition={{ duration: 0.15 }}>
          <div className={classnames('toolbar-view done-button', { hide })}>
            <div className="toolbar-button" onClick={toggleIsEditing}><FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1em', width: '1em' }} /></div>
            <div onClick={() => deleteObject()}><FontAwesomeIcon.Memo icon={faTrashAlt} style={{ height: '1em', width: '1em' }} /></div>
          </div>
        </motion.div>
      </div>
    </footer>
  );
};

export default EditFooterClipart;
