import { useSelector } from 'react-redux';
import { getDimensionsSync } from './useDimensions';

export const calcAllowAreaSync = (scene, type) => {
  let { scene: { config: { safeMarginText } } } = scene;
  safeMarginText = type === 'text' ? parseInt(safeMarginText, 10) : 0;
  const dimensions = getDimensionsSync(scene);
  return { x: dimensions.frame + safeMarginText, y: dimensions.frame + safeMarginText, width: dimensions.width + dimensions.frame - safeMarginText, height: dimensions.height + dimensions.frame - safeMarginText };
};

export const useAllowArea = (type) => useSelector((scene) => calcAllowAreaSync(scene, type));

export default useAllowArea;
