import React from 'react';
import { pctToPx } from 'components/editors/Editor/utils';
import useBackgroundColor from 'hooks/useBackgroundColor';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import SVGFilter from 'utils/svg-export/components/SVGFilter/SVGFilter';

const PhotoSVG = ({ width, height, photo, index, disableFilters, aperture }) => {
  const currentBackground = useBackgroundColor();
  const [photoWidth, photoHeight] = photo.rotate ? [height, width] : [width, height];
  const newAperture = { ...aperture };
  const apertureWidth = photoWidth * newAperture.width;
  const apertureHeight = photoHeight * newAperture.height;
  const isUploaded = useSelector(({ photos: { photos } }) => {
    const photoFromGallery = find(photos, { id: photo.imageId });
    if (photoFromGallery) {
      return photoFromGallery.uploaded;
    }
    return true;
  });
  return (
    <>
      <LoadingOverlay
        active={!isUploaded}
        spinner
        fadeSpeed={0}
        className="loading-photo-wrapper"
      />
      <svg
        width={`${photoWidth}px`}
        height={`${photoHeight}px`}
        viewBox={`${0} ${0} ${photoWidth} ${photoHeight}`}
      >
        <rect x={0} y={0} width={photoWidth} height={photoHeight} fill={currentBackground} />
        <defs>
          <clipPath id={`canvas-clip${index}`}>
            <rect x={0} y={0} width={apertureWidth} height={apertureHeight} />
          </clipPath>
        </defs>
        {!disableFilters && <SVGFilter id={`filter_${index}`} contrast={photo.contrast} brightness={photo.brightness} saturation={photo.saturation} />}
        <image
          clipPath={`url(#canvas-clip${index})`}
          preserveAspectRatio="xMinYMin slice"
          transform={`translate(${photoWidth * newAperture.x} ${newAperture.y * photoHeight})`}
          x={pctToPx(photo.x, apertureWidth)}
          y={pctToPx(photo.y, apertureWidth)}
          width={pctToPx(photo.width, apertureWidth)}
          height={pctToPx(photo.height, apertureWidth)}
          href={photo.src}
          filter={!disableFilters && `url(#filter_${index})`}
        />
      </svg>
    </>
  );
};

export default PhotoSVG;
