export const sceneTypes = {
  text: 'text',
  layout: 'layout',
  dropZone: 'drop-zone',
  clipart: 'clipart',
  background: 'background',
};

export const isText = (shape) => shape?.type === sceneTypes.text;
export const isLayout = (shape) => shape?.type === sceneTypes.layout;
export const isDropZone = (shape) => shape?.type === sceneTypes.dropZone;
export const isClipart = (shape) => shape?.type === sceneTypes.clipart;
export const isBackground = (shape) => shape?.type === sceneTypes.background;
export const isEmptyShape = (shape) => {
  if (isText(shape)) return !shape.text;
  // TODO: implement other shape types
  return true;
};
