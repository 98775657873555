import { createAction, handleActions } from 'redux-actions';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';

const defaultState = {
  sizes: [],
};

const prefix = 'CONFIG_';

export const setSizes = createAction(`${prefix}SIZES`);
export const setDesigns = createAction(`${prefix}DESIGNS`);
export const addDesign = createAction(`${prefix}ADD_DESIGNS`);
export const deleteDesign = createAction(`${prefix}DELETE_DESIGNS`);

const reducer = handleActions({
  [setSizes]: (state, action) => ({ ...state, sizes: action.payload }),
  [setDesigns]: (state, action) => ({ ...state, designs: action.payload }),
  [addDesign]: (state, action) => {
    const { designs = [] } = state;
    const currentDesign = findIndex(designs, { id: action.payload.id });
    if (currentDesign >= 0) {
      designs[currentDesign] = action.payload;
    } else {
      designs.push(action.payload);
    }
    return { ...state, designs };
  },
  [deleteDesign]: (state, action) => {
    let { designs } = state;
    designs = remove(designs, (design) => design.id !== action.payload);
    return { ...state, designs };
  },
}, defaultState);

export default reducer;
