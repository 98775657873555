import React from 'react';
import './Button.css';
import classnames from 'classnames';

const Button = ({
  children,
  className,
  rounded,
  round,
  size = 'nm',
  color,
  textSize,
  outline,
  disabled,
  ...props
}) => (
  <button
    className={classnames(
      {
        button: true,
        rounded,
        round,
        outline,
        disabled,
        [`${color}-color`]: color,
        [`${size}-size`]: size,
        [`${textSize}-text`]: textSize,
      },
      className,
    )}
    disabled={disabled}
    type="button"
    {...props}
  >
    {children}
  </button>
);

export default Button;
