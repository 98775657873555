import { applyMiddleware, compose, createStore } from 'redux';
import reducer from 'actions/reducer';
// import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import config from 'config';
import LogQueue from 'utils/LogQueue';

export const ReduxActionsLogQueue = new LogQueue(30);

const blacklistedActionTypes = [
  'PHOTOS_SET_BACKGROUNDS',
  'PHOTOS_SET',
  'PHOTOS_SET_FOLDERS',
  'CONFIG_SIZES',
  'PHOTOS_SET_CLIPARTS',
];
window.ReduxActionsLogQueue = ReduxActionsLogQueue;
const logger = (/* store */) => (next) => (action) => {
  if (!blacklistedActionTypes.includes(action?.type)) ReduxActionsLogQueue.enqueue(action);

  const result = next(action);
  return result;
};

let enhancer;
if (config.isDevelopment) enhancer = compose(...[applyMiddleware(logger), composeWithDevTools()].filter(Boolean));
else enhancer = applyMiddleware(logger);

const store = createStore(reducer, enhancer);

export default store;
