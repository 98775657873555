import React from 'react';
import './MainLayout.css';
import Footer from 'components/footer/Footer';
import NavBar from 'components/layout/NavBar';
// import useOnboarding from 'hooks/useOnboarding';
import Loading from './Loading';

const MainLayout = ({ children, loading }) => {
  // const [, completed] = useOnboarding();
  const completed = true;

  return (
    <div className="main-layout mobile" style={{ ...!completed && { '--footer-height': 0 } }}>
      <NavBar isMobile loading={loading} />
      <Loading loading={loading}>
        <div className="content-wrapper">
          {/* <Toolbar /> */}
          <main className="content">
            {children}
          </main>
        </div>
        {completed && <Footer />}
      </Loading>
    </div>
  );
};

export default MainLayout;
