import React, { forwardRef, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraAlt } from '@fortawesome/pro-regular-svg-icons';
import min from 'lodash/min';
import useDimensions from 'hooks/useDimensions';
import Image from './Image';


const selectStyles = (isOver, canDrop, image) => {
  if (canDrop) {
    if (isOver) {
      return image ? { opacity: '0.9', fill: '#555' } : { fill: '#555' };
    }
    return image ? { opacity: '0.7', fill: '#6c6c6c' } : { fill: '6c6c6c' };
  }
  return image ? { display: 'none' } : { fill: '#999' };
};

const selectStyleText = (isOver) => {
  if (isOver) {
    return { color: 'var(--accent-light-color)' };
  }
  return { color: '#fff' };
};

const ImageWithDropZone = ({ object, src, onClick, quality }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'image',
    drop: () => ({
      name: object.id,
      allowedDropEffect: 'any',
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const dimensions = useDimensions();
  const style = useMemo(() => selectStyles(isOver, canDrop, object.image), [canDrop, object.image, isOver]);
  const textStyle = useMemo(() => selectStyleText(isOver), [isOver]);
  const size = useMemo(() => {
    let size = min([dimensions.width, dimensions.height]);
    if (size / object.height > 7) {
      size = 7 * object.height;
    }
    if (size / object.width > 2) {
      size = 2 * object.width;
    }
    return size;
  }, [dimensions.height, dimensions.width, object.height, object.width]);
  const widthIcon = useMemo(() => (size / 20), [size]);
  const fontSize = useMemo(() => size / 20, [size]);
  return (
    <>
      {
        object.image ? (
          <Image object={object} src={src} onClick={onClick} quality={quality} />
        ) : null
      }
      <g ref={drop} transform={`translate(${object.x} ${object.y}) rotate(${object.rotation || 0})`} className={`rect${object.id}`} style={{ ...style }} onClick={onClick}>
        <rect x={0} y={0} width={object.width} height={object.height} opacity="0.8" strokeWidth="0.1" stroke="#fff" />
        <text fontSize={fontSize} x={object.width / 2} y={object.height / 2} dominantBaseline="hanging" textAnchor="middle" fill={textStyle.color}>Drag photo here</text>
        <FontAwesomeIcon.Memo x={object.width * 0.5 - widthIcon / 2} y={object.height * 0.5 - widthIcon} icon={faCameraAlt} height={widthIcon} width={widthIcon} color={textStyle.color} />
      </g>
    </>
  );
};

const Ref = forwardRef(ImageWithDropZone);

export default Ref;
