import store from 'store';
import { getCanvasDimensions } from 'components/editors/Editor/utils';
import useIsMobile from 'hooks/useIsMobile';

export const defaultPropsTransformer = (isMobile) => {
  const { scene: { config: { dimensions: { width }, dimensions, orientation, mode, scaleUnderlay }, state: { sceneWidthPx, sceneHeightPx } } } = store.getState();
  const sizes = getCanvasDimensions(dimensions, orientation, true, mode, [sceneWidthPx, sceneHeightPx], scaleUnderlay);
  if (isMobile) {
    return {
      strokeWidth: 0.1 * (width / sizes[0]),
      rotationHeight: 50 * (width / sizes[0]),
      radius: 8 * (width / sizes[0]),
      hitRadius: 15 * (width / sizes[0]),
      stroke: '#00c3d2',
    };
  }
  return {
  strokeWidth: 0.1 * (width / sizes[0]),
  rotationHeight: 40 * (width / sizes[0]),
  radius: 6 * (width / sizes[0]),
  hitRadius: 6 * (width / sizes[0]),
  stroke: '#00c3d2',
  };
};

export const useDefaultPropsTransformer = () => {
  const isMobile = useIsMobile();
  return defaultPropsTransformer(isMobile);
};
