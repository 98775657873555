import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { fitObjectToCanvas } from '../utils';
import useUpdateObject from './useUpdateObject';
import useEditorSizePx from './useEditorSizePx';

export function usePrevious(value, initialValue = {}) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useDimensionsChangeEffect = (objects, layout) => {
  const { dimensions, edgeWrap, orientation, initialized } = useSelector(({ scene }) => scene.config);
  const updateCanvasObject = useUpdateObject();
  const [canvasWidth, canvasHeight, frameWidthPx] = useEditorSizePx();
  const prev = usePrevious({ initialized });
  return useEffect(() => {
    if (!prev.initialized && initialized) { return; }
    layout.forEach((aperture, index) => {
      const object = objects[index];
      if (object && object.id) {
        const canvasWidthPx = canvasWidth * aperture.width;
        const canvasHeightPx = canvasHeight * aperture.height;
        const position = fitObjectToCanvas({ canvasWidthPx, canvasHeightPx, frameWidthPx, edgeWrap, object });
        updateCanvasObject({ id: object.id, minWidth: position.width, minHeight: position.height, ...position });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edgeWrap, dimensions.width, dimensions.height, dimensions.frame, orientation, layout]);
};

export default useDimensionsChangeEffect;
