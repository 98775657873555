import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { transformObject } from 'components/editors/Editor/utils';
import { useToolbar } from 'hooks';
import Background from './Background';
import Layout from './Layout';
import DropZone from './DropZone';
import Clipart from './Clipart';
import Text from './Text';

const ObjectContainer = ({ id, editorWidth, editorHeight, isEditing, setIsEditing, scaleText }) => {
  const object = useSelector(({ scene: { objects } }) => {
    const object = find(objects, { id });
    return object ? transformObject(object, editorWidth, editorHeight) : {};
  });
  const { closeFooter } = useToolbar();
  const onClick = useCallback(() => { closeFooter(); setIsEditing(object.id); }, [closeFooter, object.id, setIsEditing]);
  if (object.type === 'background') {
    return (<Background object={object} />);
  }
  if (object.type === 'layout') {
    return (<Layout object={object} onClick={onClick} />);
  }
  if (object.type === 'drop-zone') {
    return (<DropZone object={object} onClick={onClick} />);
  }
  if (object.type === 'clipart') {
    return (<Clipart object={object} onClick={onClick} />);
  }
  if (object.type === 'text') {
    return (<Text key={object.id} object={object} isEditing={isEditing} onClick={onClick} scaleText={scaleText} />);
  }
  return null;
};


export default ObjectContainer;
