import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import useUpdateConfig from 'hooks/useUpdateConfig';

const useView3d = () => {
  const updateConfig = useUpdateConfig('view3d');
  const view3d = useSelector(({ scene: { config: { view3d } } }) => view3d, shallowEqual);
  const setView3d = useCallback((newValue) => {
    updateConfig(newValue);
  }, [updateConfig]);
  const toggleView3d = useCallback(() => { setView3d(!view3d); }, [view3d, setView3d]);
  return [view3d, toggleView3d, setView3d];
};

export default useView3d;
