import { createAction, handleActions } from 'redux-actions';
import merge from 'lodash/merge';

const defaultState = {
  isOpen: null,
  type: '',
  modalProps: {},
};

const prefix = 'MODAL_';
export const close = createAction(`${prefix}CLOSE`);
export const open = createAction(`${prefix}OPEN`);
export const update = createAction(`${prefix}UPDATE`);
export const updateModalProps = createAction(`${prefix}UPDATE_MODAL_PROPS`);
export const setModalProps = createAction(`${prefix}SET_MODAL_PROPS`);

const reducer = handleActions({
  [close]: (state) => ({ ...state, isOpen: null/* , type: '' */ }),
  [open]: (state, action) => ({ ...state, isOpen: true, ...action.payload }),
  [update]: (state, action) => merge({}, state, action.payload),
  [setModalProps]: (state, action) => ({ ...state, modalProps: action.payload }),
  [updateModalProps]: (state, action) => ({ ...state, modalProps: merge({}, state.modalProps, action.payload) }),
}, defaultState);

export default reducer;
