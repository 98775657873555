import React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrop } from 'react-dnd';
import ReactFileReader from 'react-file-reader';
import { uploadAndWriteToStore } from 'components/common/PhotoLibrary/PhotoLibrary';
import { useToolbar } from 'hooks';
import './AddNewPhoto.scss';
import { MozillaAndroidDoesNotSupportMultiFileSelect } from 'constants/editor';

const selectStyles = (isOver, canDrop) => {
  if (canDrop) {
    if (isOver) {
      return { background: '#0000004d' };
    }
    return { background: '#0000001a' };
  }
  return { background: 'transparent' };
};

const AddNewPhoto = ({ width, height, show }) => {
  const { openToolbar, openFooter } = useToolbar();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'image',
    drop: () => ({
      allowedDropEffect: 'any',
      mode: 'photo-print',
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const handleFileUpload = React.useCallback((files) => { uploadAndWriteToStore(files); }, []);
  const style = React.useMemo(() => selectStyles(isOver, canDrop), [canDrop, isOver]);
  return show ? (
    <div className="photo-container">
      <ReactFileReader
        multipleFiles={!MozillaAndroidDoesNotSupportMultiFileSelect}
        fileTypes={['.png', '.jpg', '.jpeg', '.heic']}
        handleFiles={handleFileUpload}
      >

        <div ref={drop} className="add-photo" style={{ width, height, ...style }} onClick={() => { openToolbar('photos'); openFooter('photos'); }}>
          <div className="dot" style={{ width: width / 3, height: width / 3 }}>
            <FontAwesomeIcon.Memo icon={faPlus} style={{ height: `${width / 6}px`, width: `${width / 6}px` }} />
          </div>
        </div>
      </ReactFileReader>
    </div>
  ) : null;
};

const MemoAddNewPhoto = React.memo(AddNewPhoto);
export default MemoAddNewPhoto;
