export const defaultColors = [
  {
    value: '#ffffff',
    default: true,
  },
  {
    value: '#000000',
  },
  {
    value: '#de4d44',
  },
  {
    value: '#9e3744',
  },
  {
    value: '#ff842a',
  },
  {
    value: '#fc766a',
  },
  {
    value: '#c83e74',
  },
  {
    value: '#8d9440',
  },
  {
    value: '#fed65e',
  },
  {
    value: '#2e5d9f',
  },
  {
    value: '#755841',
  },
  {
    value: '#daa03d',
  },
  {
    value: '#616247',
  },
  {
    value: '#e7b7cf',
  },
  {
    value: '#d7c49e',
  },
  {
    value: '#3b3a50',
  },
  {
    value: '#f2edd7',
  },
  {
    value: '#615550',
  },
];
