import React from 'react';

const ContrastFilter = ({ value = 0 }) => {
    const a = (value / 100 + 1) ** 2;
    return (
      <feComponentTransfer>
        <feFuncR type="linear" slope={a} intercept={-(0.5 * a) + 0.5} />
        <feFuncG type="linear" slope={a} intercept={-(0.5 * a) + 0.5} />
        <feFuncB type="linear" slope={a} intercept={-(0.5 * a) + 0.5} />
      </feComponentTransfer>
    );
  };

  const BrightenFilter = ({ value = 0 }) => {
    const a = value / 100 + 1;
    return (
      <feComponentTransfer>
        <feFuncR type="linear" slope={a} />
        <feFuncG type="linear" slope={a} />
        <feFuncB type="linear" slope={a} />
      </feComponentTransfer>
    );
  };

  const SaturationFilter = ({ value = 0 }) => {
    const a = value / 100 + 1;
    const b = 0.3086 * (1 - a),
      c = 0.6094 * (1 - a),
      d = 0.082 * (1 - a);

    return (
      <feColorMatrix
        type="matrix"
        values={[b + a, c, d, 0, 0, b, c + a, d, 0, 0, b, c, d + a, 0, 0, 0, 0, 0, 1, 0].join(' ')}
      />
    );
  };

const SVGFilter = ({ id, contrast, brightness, saturation }) => (
  <filter id={id} colorInterpolationFilters="sRGB">
    <feBlend />
    <ContrastFilter value={contrast} />
    <BrightenFilter value={brightness} />
    <SaturationFilter value={saturation} />
  </filter>
  );

export default SVGFilter;
