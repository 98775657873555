import React from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faSave, faFilePlus } from '@fortawesome/pro-regular-svg-icons';
import { faUniversalAccess } from '@fortawesome/pro-solid-svg-icons';
import { downloadSvg } from 'api/utils';
import useOneImageLoaded from 'hooks/useOneImageLoaded';
import pluralize from 'pluralize';
import config from 'config';
import every from 'lodash/every';
import { useSelector, shallowEqual } from 'react-redux';
import { open, close, update, updateModalProps } from 'actions/modal';
import store from 'store';
import PixaPrints from 'api';
import DevModalButton from 'components/modal/DevModalButton';
import { getPrintLimitMaxMinAndObjectsLengthSync } from 'components/common/PhotoLibrary/utils';
import { countLowQualityImage, isAllImageLoaded } from 'utils/utils';
import { addTextArrToTextObjects } from 'actions/scene';

const handleClose = () => store.dispatch(close());
const SaveSVGButton = ({ download }) => {
  const handleSave = React.useCallback(() => {
    store.dispatch(addTextArrToTextObjects());
    downloadSvg(store.getState().scene, download, true);
  }, [download]);
  return (
    <Button onClick={handleSave} className="download-button">
      <FontAwesomeIcon.Memo icon={faUniversalAccess} style={{ height: '1.4em', width: '1.4em' }} />
    </Button>
  );
};

const initialResponseStatus = { status: null, message: null };
const handleOrder = async () => {
  store.dispatch(update({ type: 'loading', modalProps: initialResponseStatus }));
  try {
    await PixaPrints.orderItem();
    store.dispatch(updateModalProps({ success: true }));
  } catch (e) {
    store.dispatch(updateModalProps({ success: false, message: e.fakeMessage || 'Error while ordering', cancellable: e.dismissible }));
  }
};

const openOrderModal = ({ warnings = [] }) => store.dispatch(open({ type: 'order', modalProps: { onConfirm: handleOrder, onClose: handleClose, warnings } }));
const openMorePhotosNeededModal = (printLimitMin) => store.dispatch(open({ type: 'warning', modalProps: { message: `Minimum of ${ printLimitMin } photos needed`, onClose: handleClose } }));
const openPhotosLoadingModal = () => store.dispatch(open({ type: 'warning', modalProps: { message: 'Please wait until your photos are uploaded', onClose: handleClose } }));
const isAllPhotosLoaded = () => ((every(store.getState().photos.photos, 'uploaded')) ? true : !!(openPhotosLoadingModal() && false));

const handleSave = async () => {
  await store.dispatch(addTextArrToTextObjects());
  if (!isAllPhotosLoaded()) return;
  store.dispatch(open({ type: 'loading', modalProps: { initialResponseStatus, onClose: handleClose } }));
  try {
    await PixaPrints.saveState();
    store.dispatch(updateModalProps({ success: true, message: 'Successfully saved' }));
  } catch (e) {
    store.dispatch(updateModalProps({ success: false, message: e.fakeMessage || 'Error while saving design', cancellable: e.dismissible }));
  }
};
const handleSaveAs = () => {
  store.dispatch(addTextArrToTextObjects());
  if (!isAllPhotosLoaded()) return;
  const onSave = async ({ title, description }) => {
    await PixaPrints.saveDesign({ title, description });
    store.dispatch(open({ type: 'loading', modalProps: { success: true, message: 'Successfully saved', onClose: handleClose } }));
  };
  store.dispatch(open({ type: 'save-design', modalProps: { onConfirm: onSave, onClose: handleClose } }));
};

const onClickOrder = () => {
  store.dispatch(addTextArrToTextObjects());
  const { scene, photos } = store.getState();
  const [, printLimitMin, photoCount] = getPrintLimitMaxMinAndObjectsLengthSync(scene);
  if (printLimitMin > photoCount && scene.config.mode === 'photo-print') return openMorePhotosNeededModal(printLimitMin);
  if (!isAllPhotosLoaded()) return null;
  const warnings = [];
  if (!isAllImageLoaded(scene)) warnings.push('Some boxes are missing photos.');
  const lowQualityImage = countLowQualityImage({ scene, photos });
  if (lowQualityImage) warnings.push(`You have ${pluralize('photo', lowQualityImage, true)} that is low resolution`);
  return openOrderModal({ warnings });
};
const NavBarContent = ({ loading, isMobile, canvasId, onClickOrder, isOneImageLoaded, isDisabledSaveButton, admin, isDisabledSaveAsButton }) => (
  <div className="navbar">
    <a href={`${process.env.REACT_APP_API_URL}`} title="Pixa Prints">
      <img className="logo" alt="Pixa Prints" src="/icon.svg" height="50" />
      {!isMobile ? <img className="logo" alt="Pixa Prints" src="/light-logo.png" height="50" /> : null}
    </a>
    {admin ? (<div className="navbar-admin">Admin</div>) : null}
    {!loading && (
      <div className="navbar-right">
        <div className="design-id">{canvasId ? `ID ${canvasId}` : ''}</div>
        {config.showDevButtons && <SaveSVGButton download />}
        {config.showDevButtons && <DevModalButton isMobile={isMobile} />}
        {admin ? (
          <Button onClick={handleSaveAs} rounded disabled={isDisabledSaveAsButton} className="download-button">
            <FontAwesomeIcon.Memo icon={faFilePlus} style={{ height: '1.5em', width: '1.5em' }} />
          </Button>
        ) : null}
        <Button onClick={handleSave} rounded disabled={isDisabledSaveButton} className="download-button">
          <FontAwesomeIcon.Memo icon={faSave} style={{ height: '1.5em', width: '1.5em' }} />
        </Button>
        {!admin ? (
          <Button onClick={onClickOrder} rounded disabled={!isOneImageLoaded} outline className="order-button">
            <FontAwesomeIcon.Memo icon={faShoppingCart} style={{ height: '1.5em', width: '1.5em', marginRight: 10 }} />
            <div>
              Order
            </div>
          </Button>
        ) : null}
      </div>
    )}
  </div>
);
const NavBar = ({ isMobile, loading }) => {
  const canvasId = useSelector(({ app }) => app.canvasId, shallowEqual);
  const [admin, designId, objects] = useSelector(({ scene: { config: { admin, designId }, objects } }) => [admin, designId, objects?.length], shallowEqual);
  const isOneImageLoaded = useOneImageLoaded();
  const isDisabledSaveButton = (!isOneImageLoaded && !admin) || (admin && !designId);
  const isDisabledSaveAsButton = !objects;

  return (
    <NavBarContent
      loading={loading}
      isMobile={isMobile}
      canvasId={canvasId}
      admin={admin}
      onClickOrder={onClickOrder}
      isOneImageLoaded={isOneImageLoaded}
      isDisabledSaveButton={isDisabledSaveButton}
      isDisabledSaveAsButton={isDisabledSaveAsButton}
    />
  );
};

export default NavBar;
