import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import store from 'store';
import useTextIsEditing from 'components/editors/Editor/hooks/useTextEditing';
import './TextInput.scss';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';
import { getTextStyles, useNormalizeObject } from 'utils/utils';
import ContentEditable from 'react-contenteditable';
import { addTextArrToTextObjects } from 'actions/scene';

const TextInput = () => {
  const ref = useRef();
  const updateObject = useUpdateObject();
  const normalizeObject = useNormalizeObject();
  const [, toggleTextEditing] = useTextIsEditing();
  const object = useSelector(({ scene: { config: { isEditing, textEditing }, objects } }) => {
    const editingObject = textEditing ? find(objects, { id: isEditing }) : false;
    if (!editingObject || editingObject.type !== 'text') return false;
    return cloneDeep(editingObject);
  }, isEqual);
  const onChange = useCallback((props) => { updateObject({ id: object.id, ...props }); store.dispatch(addTextArrToTextObjects()); }, [object.id, updateObject]);

  // disable paste https://github.com/yabwe/medium-editor/issues/543
  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById('textInput');
      if (elem) {
        elem.addEventListener('paste', (e) => {
          // cancel paste
          e.preventDefault();
        });
        elem.focus();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!object]);

  if (!object) return null;
  return (
    <>
      <foreignObject style={{ pointerEvents: 'none' }} className={`text${object.id}`} transform={`translate(${object.x},${object.y}) rotate(${object.rotation})`} width={object.width} height="100%" overflow="visible" onClick={(e) => { toggleTextEditing(); e.stopPropagation(); }}>
        <div xmlns="http://www.w3.org/1999/xhtml" style={{ background: '#0000', overflow: 'visible', ...getTextStyles(object, 1) }}>
          <div style={{ width: '100%', height: 'min-content', minHeight: `${object.fontSize}px`, pointerEvents: 'all' }}>
            <ContentEditable
              innerRef={ref}
              id="textInput"
              html={object.text} // innerHTML of the editable div
              className="textWrapper cursorText"
              onClick={(e) => e.stopPropagation()}
              autoFocus
              onChange={(e) => {
                const text = e.target.value;
                const shape = { ...object, text };
                const newTextProps = normalizeObject(shape);
                if (newTextProps) {
                  onChange({ text, ...newTextProps });
                }
              }} // handle innerHTML change
            />
          </div>
        </div>
      </foreignObject>
    </>
  );
};


export default TextInput;
