import { useMemo } from 'react';
import { EDGE_WRAP } from 'constants/editor';
import { getDimensions } from 'utils/utils';
import includes from 'lodash/includes';
import merge from 'lodash/merge';

export const getCanvasDimensions = (dimensions, orientation, isFullScreen, mode, [maxWidth, maxHeight], scaleUnderlay = 1) => {
  const newDimensions = getDimensions({ dimensions, orientation, isFullScreen, mode });

  const frame = mode === 'canvas' ? newDimensions.frame : 0;

  const scale = Math.min((maxWidth / scaleUnderlay) / (newDimensions.width + 2 * frame), (maxHeight / scaleUnderlay) / (newDimensions.height + 2 * frame));

  return [newDimensions.width * scale, newDimensions.height * scale, frame * scale, newDimensions.halfBleed * scale];
};

export const pctToPx = (canvasWidth, x) => (x * canvasWidth) / 100;
export const pxToPct = (canvasWidth, x) => (x / canvasWidth) * 100;

export const transformObject = (object, editWidth, editHeight) => (includes(['background', 'layout'], object.type)
    ? { ...object,
      x: pctToPx(editWidth, object.x * 100),
      y: pctToPx(editHeight, object.y * 100),
      width: pctToPx(editWidth, object.width * 100),
      height: pctToPx(editHeight, object.height * 100),
    }
    : object);

export const fitObjectToCanvas = ({ canvasWidthPx, canvasHeightPx, frameWidthPx, edgeWrap, object: image }) => {
  const canvasAndFrameWidthPx = canvasWidthPx + 2 * (edgeWrap === EDGE_WRAP ? frameWidthPx : 0);
  const canvasAndFrameHeightPx = canvasHeightPx + 2 * (edgeWrap === EDGE_WRAP ? frameWidthPx : 0);
  const { width, height } = image;
  let x = 0, y = 0;

  const scale = Math.max(canvasAndFrameWidthPx / width, canvasAndFrameHeightPx / height);
  const newObjectWidthPx = width * scale;
  const newObjectHeightPx = height * scale;

  const newWidthPct = pxToPct(canvasWidthPx, newObjectWidthPx);
  const newHeightPct = pxToPct(canvasWidthPx, newObjectHeightPx);
  const centerX = 0.5;
  const centerY = 0.5;
  x = pxToPct(canvasWidthPx, -(centerY * newObjectWidthPx - canvasAndFrameWidthPx / 2));
  y = pxToPct(canvasWidthPx, -(centerY * newObjectHeightPx - canvasAndFrameHeightPx / 2));

  let offsetX = 0;
  let offsetY = 0;
  if (edgeWrap === EDGE_WRAP) {
    offsetX = pxToPct(canvasWidthPx, frameWidthPx);
    offsetY = pxToPct(canvasWidthPx, frameWidthPx);
  }
  return { x, y, width: newWidthPct, height: newHeightPct, centerX, centerY, minWidth: newWidthPct, minHeight: newHeightPct, offsetX, offsetY };
};

export const imagePctToPx = (image = { x: 0, y: 0, width: 100, height: 100 }, width) => ({
  ...image,
  x: pctToPx(width, image.x),
  y: pctToPx(width, image.y),
  height: pctToPx(width, image.height),
  width: pctToPx(width, image.width),
  offsetY: image.offsetY ? pctToPx(width, image.offsetY) : 0,
  offsetX: image.offsetX ? pctToPx(width, image.offsetX) : 0,
});

export const useImagePctToPx = (image = { x: 0, y: 0, width: 100, height: 100 }, editorWidth, edgeWrap, frameWidthPx) => {
  const newProps = useMemo(() => ({
    x: pctToPx(editorWidth, image.x),
    y: pctToPx(editorWidth, image.y),
    height: pctToPx(editorWidth, image.height),
    width: pctToPx(editorWidth, image.width),
    offsetY: edgeWrap === EDGE_WRAP ? frameWidthPx : 0,
    offsetX: edgeWrap === EDGE_WRAP ? frameWidthPx : 0,
  }), [edgeWrap, editorWidth, frameWidthPx, image.x, image.y, image.height, image.width]);
  return merge(image, newProps);
};
