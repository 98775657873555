import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateDropZonePosition } from 'actions/scene';

const useUpdateDropZonePosition = (objectId) => {
  const dispatch = useDispatch();
  return useCallback(({ id, ...modifier }) => dispatch(updateDropZonePosition({ id: id || objectId, modifier })), [dispatch, objectId]);
};

export default useUpdateDropZonePosition;
