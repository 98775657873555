/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { setDesign } from 'actions/scene';
import Grid from 'components/common/Grid';
import PixaPrints from 'api';
import flatten from 'lodash/flatten';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { open, close } from 'actions/modal';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import './Designs.scss';
import Button from 'components/common/Button';

const Photo = ({ id, thumbnailSrc, isActive, design }) => {
  const handleClick = useCallback(() => {
    store.dispatch(setDesign({ ...design }));
  }, [design]);
  return (
    <Grid.Item key={id} innerClassName={classnames({ checked: isActive })}>
      <div>
        <img
          alt={id}
          style={{ objectFit: 'contain' }}
          src={thumbnailSrc}
          onClick={handleClick}
        />
      </div>
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);

const handleClose = () => store.dispatch(close());

const Designs = () => {
  const designs = useSelector(({ config: { designs }, scene: { config: { productId } } }) => designs?.filter((design) => design.product_id === productId) || []);
  const admin = useSelector(({ scene: { config: { admin } } }) => admin);
  const designId = useSelector(({ scene: { config: { designId } } }) => designId);
  const sizeCalculated = designs.length ? '50%' : '100%';
  const cleanDesign = () => {
    store.dispatch(setDesign({ id: 'none', objects: [] }));
  };
  const handleDeleteDesign = useCallback((designId, designTitle) => {
    const onConfirm = () => {
      PixaPrints.deleteDesign(designId);
      store.dispatch(open({ type: 'loading', modalProps: { success: true, message: 'Design deleted', onClose: handleClose } }));
    };
    store.dispatch(open({ type: 'delete-design', modalProps: { onConfirm, onClose: handleClose, title: designTitle } }));
  }, []);
  return (
    <div className="colors-container">
      {admin ? (
        <Button size="lg" color="azure" textSize="md" rounded onClick={cleanDesign}>
          <FontAwesomeIcon.Memo icon={faTrash} style={{ height: '1em', width: '1em', marginRight: '0.8em' }} />
          <span>Reset</span>
        </Button>
) : null }
      <Grid doNotWrapIntoMotionDiv={designs.length > 20} size={sizeCalculated}>
        {
            flatten(
              designs.map((design) => (
                [
                  <MemoPhoto
                    key={`photo${design.id}`}
                    thumbnailSrc={design.preview || 'https://res.cloudinary.com/dt6b7ywnt/image/upload/v1605623652/No_Image_Available_e5swgh.jpg'}
                    isActive={designId === design.id}
                    design={design}
                  />,
                  admin && (
                    <Grid.Item key={`delete${design.id}`}>
                      <div style={{ width: '100%' }}>
                        <div className="delete-design">
                          <FontAwesomeIcon.Memo icon={faTrash} style={{ height: '1.5em', width: '1.5em', marginLeft: 'auto' }} onClick={() => handleDeleteDesign(design.id, design.title)} />
                        </div>
                        <div className="design-title">{design.title}</div>
                        <div className="design-description">{design.description}</div>
                      </div>
                    </Grid.Item>
                  ),
                ]
              )),
            ).filter(Boolean)
          }
      </Grid>
    </div>
  );
};

export default Designs;
