import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import pluralize from 'pluralize';
import { useIsEditing, useIsCrop } from 'components/editors/Editor';
import EditFooter from 'components/footer/EditFooter';
import CropPhotoContainer from 'components/editors/Editor/components/NewCropPhotoComponent';
import { setObjects } from 'actions/scene';
import { useFunctionToRefCB } from 'utils/utils';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import sumBy from 'lodash/sumBy';
import { useToolbar } from 'hooks';
import useCurrentLayout from 'hooks/useCurrentLayout';
import AddNewPhoto from './components/AddNewPhoto';
import useEditorSizePx from './hooks/useEditorSizePx';
import useInitObjectsEffect from './hooks/useInitObjectsEffect';
import useApertureImage from './hooks/useApertureImage';
import Photo, { PhotoSVG } from './components/Photo';
import './PhotoPrint.scss';

const PrePhotoWithHooks = ({ index, width, height, photo, objects, aperture, setIsEditing }) => {
  const setIsEditingCB = useCallback(() => setIsEditing(index + 1), [index, setIsEditing]);
  const dispatch = useDispatch();
  const removeImage = useFunctionToRefCB(() => {
    const newObjects = [...objects];
    newObjects.splice(index, 1);
    dispatch(setObjects(newObjects));
  });
  return <Photo width={width} height={height} photo={photo} setIsEditing={setIsEditingCB} removeImage={removeImage} index={index} aperture={aperture} />;
};

const PhotoPrint = ({ viewWidth }) => {
  const { width, height } = useEditorSizePx(viewWidth);
  const [sceneMaxWidthPx, sceneMaxHeightPx] = useSelector(({ scene: { state } }) => [state.sceneWidthPx, state.sceneHeightPx]);
  const [apertures] = useCurrentLayout();
  const printLimitMax = useSelector(({ scene: { config: { printLimitMax } } }) => printLimitMax, shallowEqual);
  const objects = useSelector(({ scene }) => scene.objects || [], isEqual); // ?
  const photoCount = useMemo(() => sumBy(objects, (o) => o.quantity || 1), [objects]);
  const isShowAddPhoto = useMemo(() => printLimitMax > photoCount, [photoCount, printLimitMax]);
  useInitObjectsEffect(objects, apertures, viewWidth);
  const [isEditing, toggleIsEditing, setIsEditing] = useIsEditing();
  const [isCrop, toggleIsCrop] = useIsCrop();
  const image = useMemo(() => ({ ...objects[isEditing - 1] }), [isEditing, objects]);
  const apertureImage = useApertureImage();
  const [objectsLength, setObjectsLength] = useState(objects.length);
  useEffect(() => { // if image added
    if (objects.length > objectsLength) {
      const x = document.getElementsByClassName('photo-container');
      const scrollTo = x.length > 1 && x[x.length - 2];
      scrollTo && scrollTo.scrollIntoView();
    }
    setObjectsLength(objects.length);
  }, [objects.length, objectsLength]);
  const { openedPageToolbar } = useToolbar();
  if (isCrop) {
    const sizes = image.rotate ? [height, width] : [width, height];
    const aperture = apertureImage({ image });
    return (
      <CropPhotoContainer
        object={{ id: image.id, ...aperture }}
        image={image}
        type="photo-print"
        sizes={sizes}
      />
    );
  }
  if (isEditing) {
    const scale = image.rotate ? Math.min(sceneMaxWidthPx / height, sceneMaxHeightPx / width) : Math.min(sceneMaxWidthPx / width, sceneMaxHeightPx / height);
    return (
      <>
        <div key="edit-container" className={`edit-container image${image.id}`}>
          <PhotoSVG width={width * scale} height={height * scale} photo={image} index={isEditing} aperture={apertureImage({ image })} />
        </div>
        <EditFooter toggleIsCrop={toggleIsCrop} toggleIsEditing={toggleIsEditing} object={{ id: image.id, image }} />
      </>
    );
  }
  return (
    <div className={classnames({ 'toolbar-opened': openedPageToolbar }, 'wrap-photo-print-container')} style={{ width: '100%', height: '100%' }}>
      <div key="photo-print-container" className="photo-print-container">
        <div className="photo-count">
          {pluralize('Print', photoCount, true)}
        </div>
        {objects.map((photo, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PrePhotoWithHooks key={`photo${index}`} width={width} height={height} photo={photo} setIsEditing={setIsEditing} index={index} objects={objects} aperture={apertureImage({ image: photo })} />
        ))}
        <AddNewPhoto width={width} height={height} show={isShowAddPhoto} />
      </div>
    </div>
  );
};


export default PhotoPrint;
