import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/common/Button';
import Lottie from 'react-lottie';
import './Modal.css';
import * as eyeAnimationData from './569-eye.json';
import * as warningAnimationData from './12470-warning.json';
import { isEdge } from '../../utils/utils';

const eyeAnimationOptions = (isEdge) => ({
  loop: !isEdge,
  autoplay: !isEdge,
  animationData: eyeAnimationData.default,
  rendererSettings: {
    viewBoxSize: '345 345 110 110', // 348 364 101 71
  },
});

const warningAnimationOption = (isEdge) => ({
  loop: false,
  autoplay: !isEdge,
  animationData: warningAnimationData.default,
  rendererSettings: {
    viewBoxSize: '75 75 350 350',
  },
});

const useLabels = (isAllImageLoaded) => useMemo(() => (isAllImageLoaded
  ? ['Yes, add to basket', 'Let me check it again']
  : ['Order anyway', 'Let me check it again']), [isAllImageLoaded]);

const header = 'Everything look ok?';
const text = 'Tip: Check important parts of the photo are showing. To adjust cropping, click on photo.';

const OrderModal = ({ onConfirm, onClose, warnings }) => {
  const isNoWarnings = !warnings?.length;
  const [confirmText, cancelText] = useLabels(isNoWarnings);
  return (
    <div className="modal order">
      <h2 className="modal-header">
        <div className="modal-header-icon">
          {/* eslint-disable-next-line global-require */}
          {isEdge() ? <img alt="" src={isNoWarnings ? require('./MSEDGEeye.png') : require('./MSEDGEwarning.png')} /> : (
            <Lottie
              options={isNoWarnings ? eyeAnimationOptions() : warningAnimationOption()}
              height={50}
              width={50}
              isStopped={false}
              isPaused={false}
            />
          )}
        </div>
        <div className="modal-header-text">{header}</div>
      </h2>
      {warnings.map((warning) => (
        <div className="modal-warning">
          {warning}
        </div>
      ))}
      <div className="modal-text">
        {text}
      </div>
      <div className="buttons">
        <Button
          color="azure"
          rounded
          size="lg"
          onClick={onConfirm}
        >
          <FontAwesomeIcon.Memo
            icon={faCheck}
            style={{ height: '1em', width: '1em', marginRight: '0.8em' }}
          />
          {confirmText}
        </Button>
        <Button
          outline
          rounded
          size="lg"
          onClick={onClose}
        >
          {cancelText}
        </Button>
      </div>
    </div>
  );
};

export default OrderModal;
