import React from 'react';
import store from 'store';
import { EDGE_WRAP } from 'constants/editor';
import find from 'lodash/find';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import join from 'lodash/join';
import replace from 'lodash/replace';
import { addBorderToLayout, getDimensions, alignTextSvg } from 'utils/utils';
import { transformObject } from 'components/editors/Editor/utils';
import { isBackground, isClipart, isEmptyShape, isText } from 'utils/sceneObjects';
import { toPercentage, fromPercentage } from '../../utils';
import SVGFilter from '../SVGFilter';

const svgDefaults = {
  xmlns: 'http://www.w3.org/2000/svg',
  'xmlns:xlink': 'http://www.w3.org/1999/xlink',
  version: '1.1',
};

const getFrameX2 = (mode, frame) => (mode === 'canvas' ? frame * 2 : 0);

const getViewBox = (config) => {
  const { edgeWrap, dimensions, orientation, mode, layouts, layoutId, layoutGutterType, borderType } = config;

  const layout = get(find(layouts, { id: layoutId }), 'layout', []);
  let isFullScreen = !(layoutGutterType === 'GUTTER_IN_OUT') || layout.length === 1;
  if (mode === 'photo-print') {
    isFullScreen = borderType === 'no-border';
  }

  const newDimensions = getDimensions({ dimensions, orientation, isFullScreen, mode });

  const frontWidth = 100;
  const frontHeight = toPercentage(newDimensions.height, newDimensions.width);
  const frameWidth = (mode === 'canvas') ? toPercentage(newDimensions.frame, newDimensions.width) : 0;
  const frameBleed = (!isFullScreen) ? toPercentage(newDimensions.halfBleed, newDimensions.width) : 0;

  let width = toPercentage(dimensions.width + 2 * dimensions.halfBleed + getFrameX2(mode, dimensions.frame), newDimensions.width);
  let height = toPercentage(dimensions.height + 2 * dimensions.halfBleed + getFrameX2(mode, dimensions.frame), newDimensions.width);
  if (orientation === 'portrait') {
    [height, width] = [width, height];
  }

  const xOffset = ((edgeWrap !== EDGE_WRAP) ? frameWidth : 0) + frameBleed; // edge wrap dont have offset for frame as photo wraps
  const yOffset = ((edgeWrap !== EDGE_WRAP) ? frameWidth : 0) + frameBleed;

  return { width, height, frontWidth, frontHeight, xOffset, yOffset };
};

const getFullSvgSizeSVG = (config) => { // it is work norm
  const { dimensions, orientation, mode } = config;

  const svgWidth = dimensions.width + 2 * dimensions.halfBleed + getFrameX2(mode, dimensions.frame);
  const svgHeight = dimensions.height + 2 * dimensions.halfBleed + getFrameX2(mode, dimensions.frame);
  if (orientation === 'portrait') {
    return [svgHeight, svgWidth];
  }
  return [svgWidth, svgHeight];
};

const PhotoPrintSVG = (config, objects) => {
  const { photos } = store.getState();
  const { currentBackground: { value: frameColor = 'white' } = {}, dimensions: { border, retroBorder, retroBottom }, orientation, borderType } = config;
  const [svgWidth, swgHeight] = getFullSvgSizeSVG(config);
  let { dimensions: { width: dimensionsWidth, height: dimensionsHeight } } = config;
  const { width, height, frontWidth, frontHeight, xOffset, yOffset } = getViewBox(config);
  const { layouts, layoutId } = config;
  const { layout } = find(layouts, { id: layoutId }) || {};

  // if orientation portrait swap the height and width of the dimensions
  if (orientation === 'portrait') {
    [dimensionsHeight, dimensionsWidth] = [dimensionsWidth, dimensionsHeight];
  }
  const newBorder = borderType === 'retro' ? retroBorder : border;
  const borderHorizontal = (newBorder / dimensionsWidth) * 100;
  const borderVertical = (newBorder / dimensionsHeight) * 100;
  // resize apertures
  const newLayout = addBorderToLayout(layout, borderHorizontal, borderVertical, borderType);
  if (borderType === 'retro') {
    newLayout[0].height -= (retroBottom / dimensionsHeight);
  }
  return (
    <svg
      {...svgDefaults}
      width={`${svgWidth}mm`}
      height={`${swgHeight}mm`}
      viewBox={`${0} ${0} ${width} ${height}`}
    >
      <rect x={0} y={0} width={width} height={height} fill={frameColor} />
      <defs>
        {newLayout.map((aperture, index) => {
          const clipWidth = frontWidth * aperture.width;
          const clipHeight = frontHeight * aperture.height;
          return (
            <clipPath id={`canvas-clip${index}`}>
              <rect
                x={0}
                y={0}
                width={clipWidth}
                height={clipHeight}
              />
            </clipPath>
          );
        })}
      </defs>
      {newLayout.map((aperture, index) => {
        const image = objects[index];
        if (!image) { return (<></>); }
        const { x, y, width, height, src, contrast, brightness, saturation } = image;
        const photo = aperture.type === 'background' ? find(photos.backgrounds, { id: image.imageId }) : find(photos.photos, { id: image.imageId || image.id });
        const apertureWidth = frontWidth * aperture.width;
        return (
          <>
            <SVGFilter id={`filter_${index}`} contrast={contrast} brightness={brightness} saturation={saturation} />
            <image
              key={image.id}
              clipPath={`url(#canvas-clip${index})`}
              transform={`translate(${xOffset + aperture.x * frontWidth} ${aperture.y * frontHeight + yOffset})`}
              preserveAspectRatio="xMinYMin slice"
              x={fromPercentage(x, apertureWidth)}
              y={fromPercentage(y, apertureWidth)}
              width={fromPercentage(width, apertureWidth)}
              height={fromPercentage(height, apertureWidth)}
              xlinkHref={get(photo, 'originalSrc', false) || src}
              filter={`url(#filter_${index})`}
            />
          </>
        );
      })}
    </svg>
  );
};

const SVGPage = ({ config, objects }) => {
  const { mode } = config;
  if (mode === 'photo-print') {
    return PhotoPrintSVG(config, objects);
  }

  const { photos } = store.getState();
  const { edgeWrap, currentBackground: { value: frameColor = 'white' } = {}, dimensions, admin } = config;
  const [svgWidth, svgHeight] = getFullSvgSizeSVG(config);
  // const fonts = ['Kavivanar']
  const ClippingMask = config.clippingMask3dUrl || config.clippingMaskUrl;
  const Overlay = config.overlay3dUrl || config.overlayUrl;
  const scaleOverlay = (config.overlay3dUrl && config.scaleOverlay3d) || config.scaleOverlay || 1;
  const showMask = mode === 'mask' && config.renderMask;
  const isRotateMask = config.orientation !== config.maskOrientation;
  const frame = (mode === 'canvas' && !(edgeWrap === EDGE_WRAP)) ? dimensions.frame : 0;
  const fonts = uniq(objects.filter((object) => object.type === 'text').map((object) => replace(object.fontFamily, ' ', '+')));
  let overlayX = -(svgWidth * scaleOverlay - svgWidth) / 2;
  let overlayY = -(svgHeight * scaleOverlay - svgHeight) / 2;
  let overlayWidth = svgWidth * scaleOverlay;
  let overlayHeight = svgHeight * scaleOverlay;
  if (isRotateMask) {
    [overlayX, overlayY] = [overlayY, overlayX];
    [overlayWidth, overlayHeight] = [overlayHeight, overlayWidth];
  }
  return (
    <svg
      {...svgDefaults}
      width={`${svgWidth}mm`}
      height={`${svgHeight}mm`}
      viewBox={`${-(frame)} ${-(frame)} ${svgWidth} ${svgHeight}`}
      // viewBox={`${0} ${0} ${svgWidth} ${swgHeight}`}
    >
      <rect x={-(frame)} y={-(frame)} width={svgWidth} height={svgHeight} fill={frameColor} />
      {showMask ? (
        <defs>
          <mask id="mask-clip-path">
            <g>
              <image xlinkHref={ClippingMask} width={isRotateMask ? svgHeight : svgWidth} height={isRotateMask ? svgWidth : svgHeight} transform={isRotateMask ? `translate(${svgWidth}, 0) rotate(90, ${0}, ${0})` : null} />
            </g>
          </mask>
        </defs>
      ) : null}
      <defs>
        <style xmlns="http://www.w3.org/2000/svg" type="text/css">@import url({`https://fonts.googleapis.com/css?family=${join(fonts, '|')}`});</style>
        {
          objects.map((object, index) => {
            if (!object.image) {
              return null;
            }

            const newObject = transformObject(object, svgWidth - 2 * frame, svgHeight - 2 * frame);
            return (
              <clipPath id={`canvas-clip${index}`}>
                {edgeWrap === EDGE_WRAP ? (
                  <rect x={0} y={0} width={newObject.width} height={newObject.height} />
                ) : (
                  <rect
                    x={0}
                    y={0}
                    width={newObject.width}
                    height={newObject.height}
                  />
                )}
              </clipPath>
            );
          })
        }
      </defs>
      <g mask="url(#mask-clip-path)">
        {objects.map((object, index) => {
          const newObject = transformObject(object, svgWidth - 2 * frame, svgHeight - 2 * frame);
          if (isText(newObject) && (!isEmptyShape(newObject) || admin)) {
            const { x, textAnchor } = alignTextSvg(object.textAlign, object.width);
            return (
              <text
                fontSize={`${newObject.fontSize}`}
                fontFamily={newObject.fontFamily}
                fontWeight={newObject.isBold ? 'bold' : 'normal'}
                fontStyle={newObject.isItalic ? 'italic' : 'normal'}
                fill={newObject.fill || '#000000'}
                transform={`translate(${newObject.x},${newObject.y}) rotate(${newObject.rotation})`}
                dominantBaseline="hanging"
              >
                {newObject.textArr.map((text, index) => (
                  <tspan y={`${index * newObject.fontSize}px`} x={x} textAnchor={textAnchor}>{text || 'Enter text'}</tspan>
                ))}
              </text>
            );
          }
          const { image } = newObject;
          if (!image) { return (<></>); }
          const { x, y, width, height, src, contrast, brightness, saturation } = image;
          let photo = isBackground(object) ? find(photos.backgrounds, { id: image.imageId || image.id }) : find(photos.photos, { id: image.imageId || image.id });
          if (isClipart(object)) photo = find(photos.clipart, { id: image.imageId || image.id });
          return (
            <>
              <SVGFilter id={`filter_${index}`} contrast={contrast} brightness={brightness} saturation={saturation} />
              <image
                key={image.id}
                clipPath={`url(#canvas-clip${index})`}
                transform={`translate(${newObject.x} ${newObject.y}) rotate(${newObject.rotation || 0})`}
                preserveAspectRatio="xMinYMin slice"
                x={fromPercentage(x, newObject.width)}
                y={fromPercentage(y, newObject.width)}
                width={fromPercentage(width, newObject.width)}
                height={fromPercentage(height, newObject.width)}
                xlinkHref={get(photo, 'originalSrc', false) || src}
                filter={`url(#filter_${index})`}
              />
            </>
          );
        })}
      </g>
      {showMask ? (
        <image style={{ pointerEvents: 'none' }} xlinkHref={Overlay} x={overlayX} y={overlayY} width={overlayWidth} height={overlayHeight} transform={isRotateMask ? `translate(${svgWidth}, 0) rotate(90, ${0}, ${0})` : null} />
      ) : null}
    </svg>
  );
};

export default SVGPage;
