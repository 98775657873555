import PromisePool from 'es6-promise-pool';
import Emmiter from 'tiny-emitter';
import uuid from 'uuid';

const eventTypes = {
  ENQUEUE: 'enqueue',
  UPLOAD_PROGRESS: (id) => `upload_progress:${id}`,
  UPLOAD_ERROR: (id) => `upload_error:${id}`,
  UPLOAD_COMPLETE: (id) => `upload_complete:${id}`,
};

class ImageUploader {
  constructor(options = {}) {
    const { threads = 2 } = options;
    this.threads = threads;
    this._pool = new PromisePool(this._uploadPromiseProducer, this.threads);
    this._pool.start();
  }

  debug = false

  _emmiter = new Emmiter();

  _uploadQueue = []

  // Add
  enqueue = (file, options) => {
    if (!file.id) file.id = uuid();
    this._uploadQueue.push(file);
    this._emmiter.emit(eventTypes.ENQUEUE, file);
    if (options) {
      const { onProgress, onError, onComplete } = options;
      if (typeof onProgress === 'function') {
        this._emmiter.on(eventTypes.UPLOAD_PROGRESS(file.id), onProgress);
      }
      if (typeof onError === 'function') {
        this._emmiter.on(eventTypes.UPLOAD_ERROR(file.id), onError);
      }
      if (typeof onComplete === 'function') {
        this._emmiter.on(eventTypes.UPLOAD_COMPLETE(file.id), onComplete);
      }
    }
    return file.id;
  }

  // Resolves promise once we have a new file to upload
  _idle = () => new Promise((resolve) => {
    const listenEnqueue = () => {
      const file = this._uploadQueue.shift();
      if (file) {
        this.debug && console.log('[ImageUploader] _idle() catched a new file for upload');
        resolve(this.upload(file));
        this._emmiter.off(eventTypes.ENQUEUE, listenEnqueue);
      }
    };
    this._emmiter.on(eventTypes.ENQUEUE, listenEnqueue);
  })

  // If queue is not empty, then it uploads the first file in the queue
  // if the queue is empty, then it waits for the new files
  _uploadPromiseProducer = async () => {
    const file = this._uploadQueue.shift();
    if (file) {
      this.debug && console.log('[ImageUploader] pool is ready. picking next file');
      return this.upload(file);
    }
    this.debug && console.log('[ImageUploader] queue is empty. idling...');
    return this._idle();
  }

  _emitProgress = (id, { progress, ...payload }) => {
    this._emmiter.emit(eventTypes.UPLOAD_PROGRESS(id), { progress, ...payload });
  }

  _emitError = (id, { error, ...payload }) => {
    this._emmiter.emit(eventTypes.UPLOAD_ERROR(id), { error, ...payload });
  }

  _emitComplete = (id, { url, ...payload }) => {
    this._emmiter.emit(eventTypes.UPLOAD_COMPLETE(id), { url, ...payload });
  }

  // Upload simulation
  // It should be overriden!
  upload = (file) => new Promise((resolve) => {
    this.debug && console.log('[ImageUploader] started upload simulation', file);
    setTimeout(() => {
      this.debug && console.log('[ImageUploader] finished upload simulation', file);
      resolve(file);
    }, 5000);
  })
}

export default ImageUploader;

// const iu = new ImageUploader();
// [...new Array(15)].forEach((asdf, i) => iu.enqueue(i + 1));
