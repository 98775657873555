import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import GridSelect from 'components/common/GridSelect';
import { layoutGutterTypeOptions } from 'constants/editor';
import useUpdateConfig from 'hooks/useUpdateConfig';
import VerticalSelect from 'components/common/VerticalSelect';
import find from 'lodash/find';
import './Layouts.css';
import { smartSetLayoutId } from 'api/utils';
import classnames from 'classnames';

const OptionComponent = ({ value: layoutId, selected, layouts }) => {
  const { layout } = find(layouts, { id: layoutId }) || {};
  return (
    <div className="layout-option-container">
      <svg width="100%" height="100%">
        {layout.map(({ x, y, height, width }) => (
          <rect
            key={`${x};${y};${width};${height}`}
            x={`${x}%`}
            y={`${y}%`}
            width={`${width}%`}
            height={`${height}%`}
            style={{
              stroke: `${selected ? '#6b7480' : '#555555'}`,
              strokeWidth: `${selected ? 7 : 5}`,
              fillOpacity: '0',
              strokeOpacity: '1',
            }}
          />
        ))}
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          style={{
            stroke: `${selected ? '#6b7480' : '#555555'}`,
            strokeWidth: `${selected ? 15 : 10}`,
            fillOpacity: '0',
            strokeOpacity: '1',
          }}
        />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
  );
};


const Layouts = () => {
  const { layouts, layoutId, layoutGutterType, showLayoutGutterSwitch, mode } = useSelector(({ scene: { config: { layouts, layoutId, layoutGutterType, showLayoutGutterSwitch, mode } } }) => (
    { layouts, layoutId, layoutGutterType, showLayoutGutterSwitch, mode }
  ));
  const layoutsOptionById = useMemo(() => layouts.map(({ id, ...rest }) => ({ ...rest, value: id })), [layouts]);
  const updateConfig = useUpdateConfig();
  const setLayoutGutterType = useCallback((layoutGutterType) => { updateConfig({ layoutGutterType }); }, [updateConfig]);
  return (
    <div className={classnames('layouts-container', { 'no-layout-switch': !showLayoutGutterSwitch })}>
      {showLayoutGutterSwitch && (mode === 'poster' || mode === 'mask') ? (
        <div>
          <VerticalSelect
            options={layoutGutterTypeOptions}
            onChange={setLayoutGutterType}
            value={layoutGutterType}
            onlyInnerDelimiters
            preservePaddingForCheck
          />
        </div>
      ) : null}
      <GridSelect
        options={layoutsOptionById}
        OptionComponent={(props) => (
          <OptionComponent layouts={layouts} {...props} />
        )}
        onChange={smartSetLayoutId}
        value={layoutId}
      />
    </div>
  );
};

export default Layouts;
