import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orientationOptions } from 'constants/editor';
import GridSelect from 'components/common/GridSelect';
import useCurrentLayout from 'hooks/useCurrentLayout';
import useUpdateConfig from 'hooks/useUpdateConfig';
import find from 'lodash/find';
import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import useBackgroundColor from 'hooks/useBackgroundColor';
import './Sizes.css';
import ReactGA from 'react-ga';

const Sizes = () => {
  const mode = useSelector(({ scene: { config: { mode } } }) => mode);
  const orientation = useSelector(({ scene }) => scene.config.orientation);
  const borderType = useSelector(({ scene: { config: { borderType } } }) => borderType);
  const disableOrientation = useSelector(({ scene: { config: { disableOrientation } } }) => disableOrientation);

  const updateConfig = useUpdateConfig();
  const setOrientation = useCallback((orientation) => updateConfig({ orientation }), [updateConfig]);
  const sizeOptions = useSelector(({ config }) => config.sizes);
  const sizeOptionsById = useMemo(() => sizeOptions.map(({ id, ...rest }) => ({ ...rest, value: id })), [sizeOptions]);
  const sizeId = useSelector(({ scene }) => scene.config.productId);
  const [, layout] = useCurrentLayout();
  const currentBackground = useBackgroundColor();
  const setSizeId = useCallback((sizeId) => {
    const option = find(sizeOptions, { id: sizeId });
    if (option) {
      const value = cloneDeep(option.value);
      value.layoutId = (find(value.layouts, { id: layout.id }) || value.layouts[0]).id;
      value.currentBackground = { value: currentBackground } || value.currentBackground;
      value.borderType = borderType || value.borderType;
      ReactGA.set({ product_id: option.id });
      updateConfig({ ...value, productId: option.id });
    } else console.warn('unknown size option with id ', sizeId);
  }, [borderType, currentBackground, layout.id, sizeOptions, updateConfig]);
  return (
    <div className="sizes-container">
      <div className="orientation-button">
        {includes(['poster', 'canvas', 'mask'], mode) && !disableOrientation ? (<MultiOptionButton options={orientationOptions} onChange={setOrientation} value={orientation} />) : null}
      </div>
      <div className="sizes-select">
        <GridSelect
          options={sizeOptionsById}
          onChange={setSizeId}
          value={sizeId}
          preserveDescriptionHeight
        />
      </div>
    </div>
  );
};

export default Sizes;
