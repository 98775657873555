// this file is edited version of https://github.com/hanagejet/exif-rotate-js

export function getSize(
  width,
  height,
  maxSize,
) {
  const parseWidth = maxSize < width ? maxSize : width;
  const parseHeight = maxSize < height ? maxSize : height;
  if (width > height) {
    return {
      width: parseWidth,
      height: height * (parseWidth / width),
    };
  }
  if (height > width) {
    return {
      width: width * (parseHeight / height),
      height: parseHeight,
    };
  }
  return {
    width: parseWidth,
    height: parseHeight,
  };
}

export function getCanvasOptions(
  width,
  height,
  orientation,
) {
  const options = {
    translate: {
      x: 0,
      y: 0,
    },
    scale: {
      x: 1,
      y: 1,
    },
    rotate: {
      angle: 0,
    },
  };

  switch (orientation) {
    case 2:
      // horizontal flip
      return {
        ...options,
        translate: {
          ...options.translate,
          x: width,
        },
        scale: {
          ...options.scale,
          x: -1,
        },
      };
    case 3:
      // 180° rotate left
      return {
        ...options,
        translate: {
          x: width,
          y: height,
        },
        rotate: {
          angle: Math.PI,
        },
      };
    case 4:
      // vertical flip
      return {
        ...options,
        translate: {
          ...options.translate,
          y: height,
        },
        scale: {
          ...options.scale,
          y: -1,
        },
      };
    case 5:
      // vertical flip + 90 rotate right
      return {
        ...options,
        scale: {
          ...options.scale,
          x: -1,
        },
        rotate: {
          angle: (90 * Math.PI) / 180,
        },
      };
    case 6:
      // 90° rotate right
      return {
        ...options,
        translate: {
          ...options.translate,
          x: width,
        },
        rotate: {
          angle: (90 * Math.PI) / 180,
        },
      };
    case 7:
      // horizontal flip + 90 rotate right
      return {
        ...options,
        translate: {
          x: width,
          y: height,
        },
        rotate: {
          angle: (90 * Math.PI) / 180,
        },
        scale: {
          ...options.scale,
          y: -1,
        },
      };
    case 8:
      // 90° rotate left
      return {
        ...options,
        translate: {
          ...options.translate,
          y: height,
        },
        rotate: {
          angle: -(90 * Math.PI) / 180,
        },
      };
    default:
      return options;
  }
}

export const getBase64String = (
  { canvas,
    canvasContext,
    orientation,
    image,
    doesBrowserRotatesImageByExifBool,
    maxSize },
) => {
  if (doesBrowserRotatesImageByExifBool) {
    const { width, height } = getSize(image.width, image.height, maxSize);
    canvas.setAttribute('width', `${width}px`);
    canvas.setAttribute('height', `${height}px`);
    canvasContext.drawImage(image, 0, 0, width, height);
    return { b64: canvas.toDataURL('image/jpeg'), width, height };
  }

  const { width, height } = getSize(
    orientation > 4 ? image.height : image.width,
    orientation > 4 ? image.width : image.height,
    maxSize,
  );
  canvas.setAttribute('width', `${width}px`);
  canvas.setAttribute('height', `${height}px`);
  const { translate, scale, rotate } = getCanvasOptions(
    width,
    height,
    orientation,
  );
  canvasContext.translate(translate.x, translate.y);
  canvasContext.scale(scale.x, scale.y);
  canvasContext.rotate(rotate.angle);

  // exif orientation values > 4 correspond to portrait orientation.
  // width and height parameters must be swapped for landscape to ensure correct image display
  if (orientation > 4) {
    canvasContext.drawImage(image, 0, 0, height, width);
  } else {
    canvasContext.drawImage(image, 0, 0, width, height);
  }
  return { b64: canvas.toDataURL('image/jpeg'), width, height };
};
