import { createAction, handleActions } from 'redux-actions';
// import config from 'config';
import find from 'lodash/find';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

// const test = config.isDevelopment;
const photos = [];
const showPhotos = [];
const folders = []; // accessible folders
const foldersHistory = []; // stack of Number - history
// if (test) {
//   const loadedPhotos = new Array(18)
//     .fill()
//     .map((value, index) => ({
//       ...photos[0],
//       id: index,
//       uploaded: true,
//       thumbnailSrc: 'https://i.imgur.com/3Een7Yf.jpg',
//       src: 'https://i.imgur.com/3Een7Yf.jpg',
//     }));
//   const loadingPhotos = new Array(5)
//     .fill()
//     .map((value, index) => ({
//       ...photos[0],
//       id: index + 50,
//       progress: 100 - index * 25,
//       uploaded: false,
//       thumbnailSrc: 'https://i.imgur.com/3Een7Yf.jpg',
//     }));
//   photos = [...loadingPhotos, ...loadedPhotos];
// }
const defaultState = {
  photos,
  showPhotos,
  folders, // accessible folders
  foldersHistory, // stack history
};

const prefix = 'PHOTOS_';

export const add = createAction(`${prefix}ADD`);
export const addToStart = createAction(`${prefix}ADD_TO_START`);
export const addPrevPhotos = createAction(`${prefix}ADD_PREV_PHOTOS`);
export const update = createAction(`${prefix}UPDATE`);
export const updateUploadProgress = createAction(`${prefix}PROGRESS`);
export const setBackgrounds = createAction(`${prefix}SET_BACKGROUNDS`);
export const setCliparts = createAction(`${prefix}SET_CLIPARTS`);
export const uploadCompleted = createAction(`${prefix}UPLOADED`);
export const uploadError = createAction(`${prefix}ERROR`);
export const set = createAction(`${prefix}SET`);
export const setFoldersHistory = createAction(`${prefix}SET_FOLDERS_HISTORY`);
export const setFolders = createAction(`${prefix}SET_FOLDERS`);

const reducer = handleActions({
  [setFolders]: (state, action) => ({ ...state, folders: action.payload }),
  [setFoldersHistory]: (state, action) => ({ ...state, foldersHistory: action.payload }),
  [set]: (state, action) => ({ ...state, showPhotos: [...action.payload], photos: uniqBy([...state.photos, ...action.payload], 'id') }),
  [setBackgrounds]: (state, action) => ({ ...state, backgrounds: action.payload }),
  [setCliparts]: (state, action) => ({ ...state, clipart: action.payload }),
  [addToStart]: (state, action) => {
    const boilerplate = { uploaded: false, progress: 0 };
    const newData = Array.isArray(action.payload) ? [...action.payload].reverse().map((obj) => ({ ...obj, ...boilerplate })) : [{ ...action.payload, ...boilerplate }];
    return ({ ...state, showPhotos: [...newData, ...state.showPhotos], photos: [...newData, ...state.photos] });
  },
  [addPrevPhotos]: (state, action) => {
    const boilerplate = { uploaded: true };
    const newData = [...action.payload].map((obj) => (!find(state.photos, { id: obj.id }) ? { ...obj, ...boilerplate } : null)).filter(Boolean);
    return ({ ...state, photos: [...newData, ...state.photos] });
  },
  [update]: (state, action) => {
    const { addFields, replace, updatePreview } = action.payload;
    const photos = state.photos.map((photo) => {
      let addFieldsPhoto = find(addFields, { id: photo.id });
      if (updatePreview && photo.uploaded) addFieldsPhoto = false; // if photo uploaded not replace preview
      const replacePhoto = find(replace, { id: photo.id });
      if (replacePhoto) return replacePhoto;
      if (addFieldsPhoto) return { ...photo, ...addFieldsPhoto };
      return photo;
    });
    const showPhotos = state.showPhotos.map((photo) => {
      const addFieldsPhoto = find(addFields, { id: photo.id });
      const replacePhoto = find(replace, { id: photo.id });
      if (replacePhoto) return replacePhoto;
      if (addFieldsPhoto) return { ...photo, ...addFieldsPhoto };
      return photo;
    });
    return ({ ...state, photos, showPhotos });
  },
  [add]: (state, action) => ({
    ...state,
    photos: [...state.photos, { ...action.payload, uploaded: false, progress: 0 }],
  }),
  [updateUploadProgress]: (state, action) => ({
    ...state,
    photos: state.photos.map((photo) => {
      if (photo.id !== action.payload.id) return photo;
      return { ...photo, progress: action.payload.progress };
    }),
    showPhotos: state.showPhotos.map((photo) => {
      if (photo.id !== action.payload.id) return photo;
      return { ...photo, progress: action.payload.progress };
    }),
  }),
  [uploadCompleted]: (state, action) => ({
    ...state,
    photos: state.photos.map((photo) => {
      if (photo.id !== action.payload.id) return photo;
      const thumbnailSrc = get(action, 'payload.photo.imageVariants.thumbnail');
      return { ...photo, progress: 100, uploaded: true, ...thumbnailSrc && { thumbnailSrc }, ...action.payload.photo };
    }),
    showPhotos: state.showPhotos.map((photo) => {
      if (photo.id !== action.payload.id) return photo;
      const thumbnailSrc = get(action, 'payload.photo.imageVariants.thumbnail');
      return { ...photo, progress: 100, uploaded: true, ...thumbnailSrc && { thumbnailSrc }, ...action.payload.photo };
    }),
  }),
  [uploadError]: (state, action) => ({
    ...state,
    photos: state.photos.filter(({ id }) => id !== action.payload.id),
    showPhotos: state.showPhotos.filter(({ id }) => id !== action.payload.id),
  }),
}, defaultState);

export default reducer;
