import React from 'react';
import isEqual from 'lodash/isEqual';
import Grid from 'components/common/Grid';
import './GridSelect.scss';
import classnames from 'classnames';

const Select = ({ options, value, onChange, disabled, preserveDescriptionHeight, preservePaddingForCheck, OptionComponent }) => (
  <Grid columns={2} size="50%">
    {/* <div className={classnames('grid-select-container', { 'only-inner-delimiters': onlyInnerDelimiters })}> */}
    {options.map((option) => (
      <Grid.Item key={JSON.stringify(option.value)} innerClassName={classnames({ checked: isEqual(option.value, value) })}>
        <Option
          key={JSON.stringify(option.value)}
          {...option}
          selected={isEqual(option.value, value)}
          onChange={onChange}
          disabled={disabled}
          preserveDescriptionHeight={preserveDescriptionHeight}
          preservePaddingForCheck={preservePaddingForCheck}
          OptionComponent={OptionComponent}
        />
      </Grid.Item>
    ))}
    {/* </div> */}
  </Grid>
);

const Option = ({ value, label, image, selected, preserveDescriptionHeight, description = preserveDescriptionHeight && ' ', onChange, preservePaddingForCheck, disabled, OptionComponent }) => (
  <div className="grid-select-option" onClick={() => { !disabled && onChange(value); }}>
    {image && <img alt="" src={image} />}
    {
      OptionComponent
      ? <OptionComponent value={value} selected={selected} />
      : (
        <div>
          <label>
            {label}
          </label>
          <div className={classnames('small', { 'preserve-padding-for-check': preservePaddingForCheck })}>
            <small>{description}</small>
          </div>
        </div>
      )
    }
  </div>
);

export default Select;
