import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import './Toolbar.css';
import { useScrollToTop, useToolbar } from 'hooks';
import { motion } from 'framer-motion';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

const ToolbarItem = ({ children, onClick, isActive, icon: Icon }) => (
  <li>
    <button type="button" onClick={onClick} className={classnames({ active: isActive })}>
      <div className="toolbar-icon">
        <Icon />
      </div>
      {children}
    </button>
  </li>
);

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { x: '-100%' },
};

const ToolbarView = ({ toolbarScrollRef, variants, selectedItem, scrollToTopToolbar, items, toggleToolbar, isVisible }) => (
  <motion.div ref={toolbarScrollRef} variants={variants} transition={{ duration: 0.15 }} animate={isVisible ? 'open' : 'closed'} className={classnames('toolbar-view')}>
    <div className="toolbar-view-component-container">
      {items.map(({ id, component: C, ...rest }) => (
        <C key={id} id={id} isActive={selectedItem === id} scrollToTop={scrollToTopToolbar} {...rest} />
        ))}
      {/* { isVisible && isEditing ? (
          <div className="disable-toolbar" onClick={() => setIsEditing(false)}>
            <div style={{ margin: 'auto' }}>
            if you click here you will exit edit mode
            </div>
          </div>
          ) : null} */}
      <div className="toggle-hide-button" onClick={toggleToolbar} style={{ background: isVisible ? 'white' : 'var(--toolbar-bg)' }}>
        <FontAwesomeIcon.Memo icon={isVisible ? faChevronLeft : faChevronRight} style={{ margin: 'auto' }} />
      </div>
      <KeyboardEventHandler
        handleKeys={['ctrl+b', 'meta+b']}
        onKeyEvent={(key) => {
            if (key === 'ctrl+b' || key === 'meta+b') {
              toggleToolbar();
            }
          }}
      />
    </div>
  </motion.div>
  );
const ToolbarViewMemo = React.memo(ToolbarView);

const Toolbar = ({ items }) => {
  const { openedPageToolbar: selectedItem, openToolbar, closeToolbar, openPreviousToolbar } = useToolbar();
  const [toolbarScrollRef, scrollToTopToolbar] = useScrollToTop();
  const isVisible = useMemo(() => selectedItem !== null, [selectedItem]);
  const toggleToolbar = useCallback(() => (isVisible ? closeToolbar() : openPreviousToolbar()), [closeToolbar, isVisible, openPreviousToolbar]);
  return (
    <div className="toolbar">
      <div className="toolbar-inner">
        <ul>
          {items.map(({ id, title, ...rest }) => (
            <ToolbarItem
              key={id}
              {...rest}
              isActive={selectedItem === id}
              onClick={() => (selectedItem === id ? closeToolbar() : openToolbar(id))}
            >
              {title}
            </ToolbarItem>
          ))}
        </ul>
        <ToolbarViewMemo
          toolbarScrollRef={toolbarScrollRef}
          variants={variants}
          selectedItem={selectedItem}
          scrollToTopToolbar={scrollToTopToolbar}
          items={items}
          toggleToolbar={toggleToolbar}
          isVisible={isVisible}
        />
      </div>
    </div>
  );
};

export default Toolbar;
