import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import EditFooterDropZone from 'components/footer/EditFooterDropZone';
import { useIsCrop } from 'components/editors/Editor';
import EditFooterClipart from 'components/footer/EditFooterClipart';
import { removeObject } from 'actions/scene';
import EditFooter from 'components/footer/EditFooter';
import EditFooterText from 'components/footer/EditFooterText';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';

const Footer = ({
  setIsEditing,
}) => {
  const [isCrop, toggleIsCrop] = useIsCrop();
  const dispatch = useDispatch();
  const objectEdit = useSelector(({ scene: { config: { isEditing }, objects } }) => ({ ...find(objects, { id: isEditing }) }), isEqual);
  const updateObject = useUpdateObject();
  const onChange = useCallback((props) => { updateObject({ id: objectEdit.id, ...props }); }, [objectEdit.id, updateObject]);
  const deleteObject = useCallback(() => dispatch(removeObject({ id: objectEdit.id })), [dispatch, objectEdit.id]);
  return (
    <div style={{ position: 'absolute', bottom: 0, width: '100%', display: 'flex' }}>
      {isCrop ? null : (
        <>
          {objectEdit.type === 'drop-zone' ? (
            <EditFooterDropZone key={objectEdit.id} toggleIsEditing={() => setIsEditing(false)} toggleIsCrop={toggleIsCrop} object={objectEdit} deleteObject={deleteObject} />
          ) : null}
          {objectEdit.type === 'clipart' ? (
            <EditFooterClipart key={objectEdit.id} toggleIsEditing={() => setIsEditing(false)} deleteObject={deleteObject} />
          ) : null}
          {objectEdit.type === 'layout' ? (
            <EditFooter key={objectEdit.id} toggleIsEditing={() => setIsEditing(false)} toggleIsCrop={toggleIsCrop} deleteObject={deleteObject} object={objectEdit} />
          ) : null}
          {objectEdit.type === 'text' ? (
            <EditFooterText toggleIsEditing={() => setIsEditing(false)} object={objectEdit} onChange={onChange} deleteObject={deleteObject} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default Footer;
