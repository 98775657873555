import React from 'react';
import ReactModal from 'react-modal';
import './Modal.css';

const Modal = ({ isOpen, isMobile, children }) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      border: 'unset',
      borderRadius: 10,
      width: '85vw',
      ...isMobile && { padding: '20px' },
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <ReactModal
      closeTimeoutMS={300}
      shouldReturnFocusAfterClose={false} // after closing modal, will not select last used element
      style={modalStyle}
      isOpen={!!isOpen}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
