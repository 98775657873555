import React, { useMemo, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import useIsMobile from 'hooks/useIsMobile';
import find from 'lodash/find';
import { ChromePicker } from 'react-color';
import { faCheck, faTrashAlt, faPalette, faBold, faItalic, faAlignLeft, faAlignRight } from '@fortawesome/pro-regular-svg-icons';
import { faAlignCenter, faBold as faBoldSelect, faItalic as faItalicSelect } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import optionsFontFamily from 'constants/fonts';
import { useClickAway } from 'react-use';
import optionsFontSize from 'constants/fontSizes';
import useTextIsEditing from 'components/editors/Editor/hooks/useTextEditing';
import { isIOS } from 'react-device-detect';
import './EditFooterText.scss';
import textAligns from 'constants/textAligns';
import MultiOptionButton from 'components/common/MultiOptionButton';

import { useNormalizeObject } from 'utils/utils';

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} style={{ width: '100%' }}>
      <img height="30px" alt={data.value} src={`https://res.cloudinary.com/dt6b7ywnt/image/upload/f_auto/assets/editors/font-images/${data.value}`} />
    </div>
  );
};

const CustomAlignLabel = (props) => {
  const { optionValue } = props;
  let icon = faAlignLeft;
  if (optionValue === 'center') icon = faAlignCenter;
  if (optionValue === 'right') icon = faAlignRight;
  return (<FontAwesomeIcon.Memo icon={icon} style={{ height: '1.22em', width: '1.22em', marginRight: '0px' }} />);
};

export const EditFooterText = ({ hide, toggleIsEditing, object, onChange, deleteObject }) => {
  const isMobile = useIsMobile();
  const [textEditing] = useTextIsEditing();
  const [showAdjust, setShowAdjust] = useState(false);
  const normalizeObject = useNormalizeObject();
  const changeText = useCallback((props) => {
    const newTextProps = normalizeObject({ ...object, ...props });
    if (newTextProps) {
      onChange({ ...props, ...newTextProps });
    }
  }, [normalizeObject, object, onChange]);
  const toggleShowAdjust = () => setShowAdjust(!showAdjust);
  const variantsToolbar = useMemo(() => (
    isMobile ? {
      open: { opacity: 1, y: 0 },
      closed: { opacity: 0, y: '100%' },
      openTab: { y: 0 },
    } : {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '-100%' },
        openTab: { y: 0 },
      }
  ), [isMobile]);
  const variantsTab = useMemo(() => ({
    open: { opacity: 1, y: 0, height: 'auto' },
    closed: { opacity: 0, display: 'none', y: isMobile ? '100%' : '-100%', height: 0 },
  }), [isMobile]);

  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);

  const colorPickerRef = useRef(null);
  useClickAway(colorPickerRef, () => {
    if (showAdjust) {
      setTimeout(() => setShowAdjust(false), 100);
    }
  });
  const visibility = useMemo(() => (textEditing && isMobile && !isIOS ? 'hidden' : 'visible'), [isMobile, textEditing]);

  return (
    <footer style={{ width: 0, visibility }}>
      <div className="toolbar edit">
        <motion.div className="toolbar-view-container" initial="closed" variants={variantsToolbar} animate={showAdjust ? 'openTab' : 'open'} transition={{ duration: 0.15 }}>
          <div className={classnames('toolbar-view', 'toolbar-text', { hide })}>
            <div className="done-button" onClick={toggleIsEditing}><FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1.22em', width: '1.22em' }} /></div>
            <div className="text-align-buttons">
              <MultiOptionButton options={textAligns} onChange={(textAlign) => onChange({ textAlign })} value={object.textAlign || 'left'} LabelComponent={CustomAlignLabel} />
            </div>
            <div style={{ minWidth: '140px' }}>
              <Select
                defaultValue={find(optionsFontFamily, { label: object.fontFamily })}
                menuPlacement={menuPlacement}
                components={{ Option: CustomOption }}
                onChange={(select) => changeText({ fontFamily: select.label })}
                options={optionsFontFamily}
                classNamePrefix="text-input"
              />
            </div>
            <div className="font-size-button">
              <div className="select-number">
                <Select
                  defaultValue={find(optionsFontSize, { value: object.fontSize })}
                  menuPlacement={menuPlacement}
                  value={find(optionsFontSize, { value: object.fontSize })}
                  onChange={(select) => changeText({ fontSize: select.value })}
                  options={optionsFontSize}
                  classNamePrefix="number-input"
                />
              </div>
            </div>
            <div className="break" />
            <div className={classnames('bold-button', { highlight: object.isBold })} onClick={() => onChange({ isBold: !object.isBold })}><FontAwesomeIcon.Memo icon={object.isBold ? faBoldSelect : faBold} style={{ height: '1.22em', width: '1.22em' }} /></div>
            <div className={classnames('italic-button', { highlight: object.isItalic })} onClick={() => onChange({ isItalic: !object.isItalic })}><FontAwesomeIcon.Memo icon={object.isItalic ? faItalicSelect : faItalic} style={{ height: '1.22em', width: '1.22em' }} /></div>
            <div className="text-color-button" onClick={toggleShowAdjust}>
              <div>
                <FontAwesomeIcon.Memo icon={faPalette} style={{ height: '1.22em', width: '1.22em' }} />
              </div>
              <div className="colour-selected" style={{ background: object.fill }} />
            </div>
            <div className="delete-button" onClick={() => deleteObject()}><FontAwesomeIcon.Memo icon={faTrashAlt} style={{ height: '1.22em', width: '1.22em' }} /></div>
          </div>

          <motion.div className="" initial="closed" variants={variantsTab} animate={showAdjust ? 'open' : 'closed'} transition={{ duration: 0.15 }}>
            <div className="color-picker-container" style={{ display: 'flex' }}>
              <div ref={colorPickerRef} style={{ width: isMobile ? '100%' : '225px' }}>
                <ChromePicker
                  color={object.fill}
                  width={isMobile ? '100%' : '225px'}
                  onChange={(e) => onChange({ fill: e.hex })}
                  disableAlpha
                  renderers={false}
                />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </footer>
  );
};

export default EditFooterText;
