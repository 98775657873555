import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import './CropFooter.scss';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageQualityToolbar from '../components/ImageQualityToolbar';

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 100 },
};

export const CropFooter = (props) => {
  const { hide, quality, toggleIsCrop } = props;
  return (
    <footer style={{ width: 0 }}>
      <div className="toolbar edit">
        <motion.div className={classnames('toolbar-view', { hide })} initial="closed" variants={variants} animate="open" transition={{ duration: 0.15 }}>
          <div className="done-button" onClick={toggleIsCrop}><FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1em', width: '1em' }} /></div>
          <ImageQualityToolbar quality={quality} />
        </motion.div>
      </div>
    </footer>
  );
};

export default CropFooter;
