import React from 'react';
import store from 'store';
import { faTrash, faRedoAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPen, faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sumBy from 'lodash/sumBy';
import { open, close } from 'actions/modal';
import useUpdateObjectByIndex from '../../hooks/useUpdateObjectByIndex';
import PhotoSVG from './PhotoSVG';
import './Photo.scss';

const Photo = ({ width, height, photo, setIsEditing, index, removeImage, aperture }) => {
  const updateCanvasObjectByIndex = useUpdateObjectByIndex();
  const rotateImage = () => {
    updateCanvasObjectByIndex({ index, rotate: !photo.rotate, initialized: false });
  };
  const incQuantity = () => {
    const quantity = (photo.quantity || 1) + 1;
    const photoCount = sumBy(store.getState().scene.objects, (o) => o.quantity || 1);
    if (photoCount < store.getState().scene.config.printLimitMax) {
      updateCanvasObjectByIndex({ index, quantity });
    } else {
      const handleClose = () => store.dispatch(close());
      const message = 'Print limit reached for this product';
      store.dispatch(open({ type: 'warning', modalProps: { message, onClose: handleClose } }));
    }
  };
  const decQuantity = () => {
    const quantity = (photo.quantity || 1) - 1;
    if (quantity >= 1) {
      updateCanvasObjectByIndex({ index, quantity });
    }
  };
  return (
    <div className="photo-container">
      <div className="photo" onClick={setIsEditing} style={{ width: photo.rotate ? height : width, height: photo.rotate ? width : height }}>
        <PhotoSVG width={width} height={height} photo={photo} index={index} aperture={aperture} />
        <div className="crop" onClick={setIsEditing}><FontAwesomeIcon.Memo icon={faPen} style={{ height: '1em', width: '1em' }} /></div>
      </div>
      <div className="icon-container" style={{ width: photo.rotate ? height : width }}>
        <div className="rotate" onClick={rotateImage}><FontAwesomeIcon.Memo icon={faRedoAlt} style={{ height: '1em', width: '1em' }} /></div>
        <div className="change-quantity" onClick={decQuantity}><FontAwesomeIcon.Memo icon={faMinusCircle} style={{ height: '1.3em', width: '1.3em' }} /></div>
        <div className="quantity">x{photo.quantity || 1}</div>
        <div className="change-quantity" onClick={incQuantity}><FontAwesomeIcon.Memo icon={faPlusCircle} style={{ height: '1.3em', width: '1.3em' }} /></div>
        <div className="delete" onClick={removeImage}><FontAwesomeIcon.Memo icon={faTrash} style={{ height: '1em', width: '1em' }} /></div>
      </div>
    </div>
  );
};

const MemoPhoto = React.memo(Photo);
export default MemoPhoto;
