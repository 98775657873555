import findLast from 'lodash/findLast';
import get from 'lodash/get';
import find from 'lodash/find';
import uuid from 'uuid';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import { fitObjectToCanvas, transformObject } from 'components/editors/Editor/utils';
import { EDGE_WRAP } from 'constants/editor';

export const calculateCurrentLayout = (config, objects) => {
  if (config.layoutId && config.layouts && config.dimensions.height && config.dimensions.width && config.orientation) {
    const layoutOption = findLast(config.layouts, { id: config.layoutId });
    const margin = get(layoutOption, 'margin');
    let layout = get(layoutOption, 'layout');
    layout = layout.map(({ x, y, width, height }) => ({ x: x / 100, y: y / 100, width: width / 100, height: height / 100, type: 'layout' }));
    // ---------------------------------------------------------------------
    if (config.mode === 'poster' || config.mode === 'mask') {
      let { height, width } = config.dimensions;
      if (config.orientation === 'landscape') {
        [height, width] = [width, height];
      }
      const marginHorizontal = (margin / height / 2);
      const marginVertical = (margin / width / 2);
      layout = layout?.map((aperture) => {
        let { x, y, width, height } = aperture;
        const halfMarginHorizontal = marginHorizontal / 2;
        const halfMarginVertical = marginVertical / 2;

        if (config.layoutGutterType === 'GUTTER_IN_OUT') {
          const getDivider = (value) => ((value > 0 && value < 1) ? 2 : 1);
          width -= marginHorizontal / getDivider(x) + marginHorizontal / getDivider(width + x);
          x += marginHorizontal / getDivider(x);
          height -= marginVertical / getDivider(y) + marginVertical / getDivider(height + y);
          y += marginVertical / getDivider(y);
        }

        if (config.layoutGutterType === 'GUTTER_IN') {
          const getMultiplier = (value) => ((value > 0 && value < 1) ? 1 : 0);
          width -= halfMarginHorizontal * getMultiplier(x) + halfMarginHorizontal * getMultiplier(width + x);
          x += halfMarginHorizontal * getMultiplier(x);
          height -= halfMarginVertical * getMultiplier(y) + halfMarginVertical * getMultiplier(height + y);
          y += halfMarginVertical * getMultiplier(y);
        }
        let newAperture = { x, y, width, height, type: 'layout' };
        if (config.admin) {
          newAperture = transformObject(newAperture, config.dimensions.width, config.dimensions.height);
          return { ...newAperture, type: 'drop-zone' };
        }
        return newAperture;
      });
    }
    if (config.mode === 'photo-print') {
      const { height, width, border, retroBorder } = config.dimensions;
      let borderHorizontal = (border / width);
      let borderVertical = (border / height);
      const { borderType } = config;
      if (borderType === 'retro') {
        borderHorizontal = (retroBorder / width);
        borderVertical = (retroBorder / height);
      }
      layout = layout.map((aperture) => {
        let { x, y, width, height } = aperture;
        if (borderType === 'border' || borderType === 'retro') {
          width -= borderHorizontal * 2;
          x += borderHorizontal;
          height -= borderVertical * 2;
          y += borderVertical;
        }
        return { x, y, width, height, type: 'layout' };
      });
    }
    if (objects && config.mode !== 'photo-print') {
      const oldLayout = filter(objects, (element) => element.type === 'layout').map(({ x, y, width, height, type }) => ({ x, y, width, height, type }));
      if (isEqual(oldLayout, layout)) {
        return objects;
      }
      // filter(objects, (element) => element.type === 'layout').forEach((object, index) => {
      //   if (object.image && index < layout.length) {
      //     layout[index].image = { ...object.image, initialized: false };
      //     layout[index].id = object.id;
      //   }
      // });
      layout = layout.map((aperture) => {
        const id = uuid();
        return { id, ...aperture };
      });
      const newCurrentLayout = [...filter(objects, (element) => element.type === 'background'), ...layout, ...filter(objects, (element) => (element.type !== 'background' && element.type !== 'layout'))];
      return newCurrentLayout;
    }
    return layout;
  }
  return undefined;
};

export const getDimensions = ({ orientation, dimensions, isFullScreen, mode }) => {
  const _dimensions = { ...dimensions };
  if (orientation === 'portrait') {
    [_dimensions.width, _dimensions.height] = [_dimensions.height, _dimensions.width];
  }

  // calculate bleed
  if (isFullScreen || mode === 'canvas') {
    _dimensions.width += _dimensions.halfBleed * 2;
    _dimensions.height += _dimensions.halfBleed * 2;
  }
  return _dimensions;
};

export const calculateIsFullScreen = (config) => {
  const { layoutId, layouts, layoutGutterType } = config;
  const layout = get(find(layouts, { id: layoutId }), 'layout', []);
  return !(layoutGutterType === 'GUTTER_IN_OUT') || layout.length === 1;
};

export const editorSizePx = (config) => {
  const { dimensions, orientation, mode, edgeWrap } = config;
  const isFullScreen = calculateIsFullScreen(config);
  const newDimensions = getDimensions({ dimensions, orientation, isFullScreen, mode });
  const frame = (mode === 'canvas' && edgeWrap === EDGE_WRAP) ? newDimensions.frame : 0;
  return [newDimensions.width, newDimensions.height, frame, newDimensions.halfBleed];
};

export const initImage = (object, config) => {
  const { edgeWrap } = config;
  // debugger;
  const [canvasWidth, canvasHeight, frameWidthPx] = editorSizePx(config);
  const { width: apertureWidthPx, height: apertureHeightPx } = transformObject(object, canvasWidth + 2 * frameWidthPx, canvasHeight + 2 * frameWidthPx);
  const position = fitObjectToCanvas({
                    canvasWidthPx: apertureWidthPx,
                    canvasHeightPx: apertureHeightPx,
                    frameWidthPx: 0,
                    edgeWrap,
                    object: object.image,
                  });
  const newObject = object;
  newObject.image = { ...newObject.image, minWidth: position.width, minHeight: position.height, initialized: true, ...position };
  console.groupEnd();
  return newObject;
};

export const shapeToCenter = (shapeWidth, shapeHeight, config) => {
  const { dimensions: { frame }, mode, edgeWrap, orientation } = config;
  let { dimensions: { width, height } } = config;
  const canvasFrame = mode === 'canvas' && edgeWrap === EDGE_WRAP ? 2 * frame : 0;
  if (orientation === 'portrait') {
    [width, height] = [height, width];
  }
  const x = (width + canvasFrame - shapeWidth) / 2;
  const y = (height + canvasFrame - shapeHeight) / 2;
  return { x, y };
};
