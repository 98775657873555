/* eslint-disable max-classes-per-file */
/* eslint-disable camelcase */
import store from 'store';
import axios from 'axios';
import * as configActions from 'actions/config';
import * as appActions from 'actions/app';
import { updateConfig, setScene, setDesign } from 'actions/scene';
import * as onboardingActions from 'actions/onboarding';
import find from 'lodash/find';
import first from 'lodash/first';
import includes from 'lodash/includes';
import qs from 'qs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import config from 'config';
import * as Sentry from '@sentry/browser';
import usePreventLeave from '@sook/use-prevent-leave';
import ReactGA from 'react-ga';
import { saveState as saveStateGAEvent, order as orderGAEvent } from 'constants/GA';
import { addPrevPhotos } from 'actions/photos';
import LogQueue from 'utils/LogQueue';
import { fullScreenLayout } from './layouts';
import { processSizes, downloadSvg, smartSetLayoutId } from './utils';
import Migrations from './migrations';

// eslint-disable-next-line react-hooks/rules-of-hooks
const { disablePrevent, enablePrevent } = usePreventLeave();
enablePrevent();

const throwError = (error, fakeMessage) => {
  error && console.error(error);
  error.fakeMessage = fakeMessage === true ? error.message : fakeMessage;
  throw error;
};
const throwInitError = (error) => throwError(error, 'Error while initializing');
const throwSaveError = (error) => throwError(error, 'Error while saving');
const throwOrderError = (error) => throwError(error, 'Error while ordering');
// const nonCancellable = (error) => { error.cancellable = false; return error; };
const dismissible = (error, onDismiss) => {
  error.dismissible = true;
  error.onDismiss = onDismiss;
  return error;
};

const getURLParams = () => qs.parse(window.location.search, { ignoreQueryPrefix: true }); // get object with url params
const stringifyURLParams = (params = {}) => qs.stringify(params, { addQueryPrefix: true }); // input: object {key:value}, output: '?key=value'
// eslint-disable-next-line no-restricted-globals
const setURLParams = (params = {}) => history.replaceState('', '', `/${stringifyURLParams(params)}`); // set object {key:value} to url
export const updateURLParams = (params = {}) => setURLParams({ ...getURLParams(), ...params }); // update url params

export const ApiCallsLogQueue = new LogQueue();

class PixaPrints {
  baseUrl = `${config.apiUrl}/api/v2/editor`;

  _call = async (props) => {
    ApiCallsLogQueue.enqueue(props);
    const { method = 'get', path, headers = {}, params, data, withCredentials = config.withCredentials, ...rest } = props;
    !config.isProduction && console.log(`[PixaPrints] Calling ${this.baseUrl}${path}`, { headers, params, data, rest });
    try {
      const res = await axios.request({
        url: `${this.baseUrl}${path}`,
        method,
        headers,
        params,
        data,
        withCredentials,
        ...rest,
      });
      !config.isProduction && console.log(`[PixaPrints] Success ${this.baseUrl}${path}`, { res });
      return res.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  /**
   * Get canvas ID param from store, if not exist then from url.
   * @returns {Number} if called with true
   * @returns {String} `/${id}`
   */
  getCanvasId = (returnRawNumber) => {
    const { app } = store.getState();
    const parsedURLQuery = getURLParams(); // http://localhost:3000/?id=14
    const { id } = parsedURLQuery;
    const canvasId = app.canvasId || id;
    if (returnRawNumber) return canvasId;
    return canvasId ? `/${canvasId}` : '';
  };

  /**
   * Set canvas id to store
   * @param {Number} id Canvas ID
   * @returns undefined
   */
  setCanvasId = (id) => {
    store.dispatch(appActions.setCanvasId(id));
    updateURLParams({ id });
  };

  /**
   * 1. get category param from url
   * 2. fetch initial data with category param
   * (initial data includes sizes array)
   * 3. push initial data to store
   * 4. set default size
   * @returns undefined
   */
  fetchEditorConfig = async () => { // fetch sizes
    const parsedURLQuery = getURLParams(); // http://localhost:3000/?category=97&mode=canvas
    const { category, product, productoption, mode: modeFromQuery = 'canvas', design: designIdFromQuery, admin: adminFromQuery = false } = parsedURLQuery;
    const { scene } = store.getState();
    const product_id = get(scene, 'config.productId') || product;
    const productoption_id = get(scene, 'config.productOptionId') || productoption;
    const category_id = get(scene, 'config.categoryId') || category;
    const modeFromState = get(scene, 'config.mode');
    const productModeState = get(scene, 'config.productMode') || 'layout';
    const designIdFromState = get(scene, 'config.designId');
    const orientationFromState = get(scene, 'config.orientation');
    const maskOrientationFromState = get(scene, 'config.maskOrientation');
    let designId = designIdFromState || designIdFromQuery;

    ReactGA.set({ category_id });

    try {
      if (adminFromQuery) {
        await this._call({ path: '/admin' });
      }

      const params = { category: category_id, product: product_id };
      const isUseLocalApi = JSON.parse(localStorage.getItem('useLocalApi'));
      const localApi = JSON.parse(localStorage.getItem('localApi'));
      let data = await this._call({ path: '/initiate', params });
      if (isUseLocalApi) {
        data = { ...data, ...localApi };
      }
      if (!data.products) throw new Error('Products are empty');

      const { products, enableMultiLayout, layoutMode, printLimitMin = 1, printLimitMax = 10,
        showBackgroundPicker = true, defaultBackground, backgroundOptions, layoutGutterType = 'GUTTER_IN_OUT',
        showLayoutGutterSwitch = true, mode: modeFromApi, orientation: orientationFromApi, borderType, designs,
        boundaryRelativeMaxHeight, boundaryRelativeMaxWidth, mobileBoundaryRelativeMaxHeight, mobileBoundaryRelativeMaxWidth,
        safeMarginText = 4, halfBleed = 3, disableOrientation = false, contentOffsetY = 0 } = data;
      const mode = modeFromApi || modeFromState || modeFromQuery;
      const sizes = processSizes(products, enableMultiLayout, layoutMode,
        defaultBackground, backgroundOptions, layoutGutterType, showLayoutGutterSwitch, printLimitMin, printLimitMax, borderType); // some transformation
      store.dispatch(configActions.setSizes(sizes)); // write sizes to config
      store.dispatch(configActions.setDesigns(designs));
      const defaultSize = find(sizes, { default: true }) || first(sizes) || {}; // size marked as default
      const prevProductId = get(store.getState(), 'scene.config.productId'); // size id that was loaded from server
      const admin = adminFromQuery && mode !== 'photo-print';
      if (defaultSize && !prevProductId) { // if size was not selected before, then select default size
        store.dispatch(
          updateConfig({
            ...(defaultSize.value || {}),
            productId: defaultSize.id,
            productMode: (admin) ? 'designs' : productModeState,
            categoryId: category_id,
            admin: !!admin,
            view3d: true,
          }),
        );

        ReactGA.set({ product_id: defaultSize.id });
      }

      if (designIdFromQuery && !designIdFromState) {
        const currentDesign = find(designs, { id: designId });
        if (currentDesign) {
          store.dispatch(setDesign({ ...currentDesign }));
        } else {
          designId = undefined;
        }
      }

      let layouts = get(store.getState(), 'scene.config.layouts'); // layouts from current selected size
      if ((!layouts || mode === 'photo-print') && !admin) {
        layouts = fullScreenLayout;
        store.dispatch(
          updateConfig({
            layouts: fullScreenLayout,
          }),
        );
      }

      const layoutId = get(store.getState(), 'scene.config.layoutId'); // current selected layoutId
      const defaultLayout = find(layouts, { default: true }) || first(layouts) || {}; // default layout in [layouts of selected size]

      if (layouts && (defaultLayout.id || layoutId) && !admin && !designId) { // set current layout to default if it was not selected before
        smartSetLayoutId(layoutId || defaultLayout.id, mode); // this changes edgeWrap etc.
      }

      const productId = get(store.getState(), 'scene.config.productId'); // add missing keys
      const selectedSize = find(sizes, { id: productId });
      const config = get(selectedSize, 'value', {});
      const currentConfig = get(store.getState(), 'scene.config', {});

      const missingKeys = {}; // deal with missing keys in saved design data as editor has developed

      Object.keys(config).forEach((key) => {
        if (key && currentConfig[key] === undefined) {
          missingKeys[key] = config[key];
        }
      });

      if (!isEmpty(missingKeys)) {
        store.dispatch(
          updateConfig(missingKeys),
        );
      }

      const selectedLayoutId = get(store.getState(), 'scene.config.layoutId'); // current selected layoutId
      const selectedLayout = find(layouts, { id: selectedLayoutId });

      if (selectedLayout && selectedLayout.layout && selectedLayout.layout.length > 1) {
        store.dispatch(onboardingActions.complete());
      }

      const orientation = orientationFromState || orientationFromApi || 'landscape';

      store.dispatch(updateConfig({
        mode,
        initialized: true,
        orientation,
        contentOffsetY,
        disableOrientation,
        maskOrientation: maskOrientationFromState || orientation,
        productOptionId: productoption_id,
        showBackgroundPicker,
        boundaryRelativeMaxHeight,
        boundaryRelativeMaxWidth,
        mobileBoundaryRelativeMaxHeight,
        mobileBoundaryRelativeMaxWidth,
        version: Migrations.lastVersion,
        safeMarginText,
        dimensions: { halfBleed: parseInt(halfBleed, 10) },
      }));
    } catch (exception) {
      Sentry.captureException(exception);
      throwInitError(exception);
    }
  };

  /**
   * fetch saved scene and push it to store
   * turn on onboarding if param `id` is undefined
   * @param {Number} id canvas id to fetch. if undefined, then it will get canvas id using function
   * @returns server response data or null if param `id` is undefined
   * @throws `Cannot find saved canvas` if server's response is 401
   */
  fetchSavedScene = async (id = this.getCanvasId()) => {
    try {
      if (id) {
        const data = await this._call({ path: `/design${id}` });
        const { data: responseData } = data;
        responseData.id && store.dispatch(appActions.setCanvasId(responseData.id));

        const { photos, ...newScene } = Migrations.migrate(responseData.scene);
        photos && store.dispatch(addPrevPhotos(photos));
        responseData.scene && store.dispatch(setScene(newScene));
        // responseData.category_id && store.dispatch(setScene(responseData.scene));
        // responseData.product_id && store.dispatch(setScene(responseData.scene));
        return responseData;
      }
      store.dispatch(onboardingActions.show());
      return null;
    } catch (exception) {
      if (exception && exception.response && exception.response.status === 401) {
        // if id is not found or user does not have rights to see that canvas
        store.dispatch(onboardingActions.show());
        const error = new Error('Cannot find saved design');
        throwError(
          dismissible(error, () => updateURLParams({ id: undefined })),
          true,
        );
      }
      throw exception;
    }
  };

  /**
   * Save scene to Server
   * @returns {Object} - server response
   */
  saveDesign = async ({ title, description }) => {
    try {
      ReactGA.event(saveStateGAEvent);
      const { scene } = store.getState();
      const product_id = get(scene, 'config.productId');
      const productoption_id = get(scene, 'config.productOptionId');
      const admin = get(scene, 'config.admin');
      let svg = null;
      if (admin) {
        svg = downloadSvg(scene, 'string');
        const data = { objects: scene.objects, product_id, productoption_id, svg, title, description, currentBackground: scene.config.currentBackground };
        if (scene.config.mode === 'mask') {
          const maskedSvg = downloadSvg(scene, 'string', true);
          data.maskedSvg = maskedSvg;
        }
        const responseData = await this._call({ method: 'post', path: '/designs', data });
        store.dispatch(configActions.addDesign(responseData));
        store.dispatch(setDesign({ ...responseData }));
        return responseData;
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  deleteDesign = async (designId) => {
    try {
      ReactGA.event(saveStateGAEvent);
      const { scene } = store.getState();
      const admin = get(scene, 'config.admin');
      if (admin) {
        console.log('delete ', designId);
        const responseData = await this._call({ method: 'post', path: '/design/delete', data: { designId } });
        store.dispatch(configActions.deleteDesign(designId));
        return responseData;
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  /**
   * Save scene to Server
   * @returns {Object} - server response
   */
  saveState = async () => {
    try {
      ReactGA.event(saveStateGAEvent);
      const { scene, photos: { photos: _photos } } = store.getState();
      const id = this.getCanvasId();
      const product_id = get(scene, 'config.productId');
      const productoption_id = get(scene, 'config.productOptionId');
      const mode = get(scene, 'config.mode');
      const admin = get(scene, 'config.admin');
      const designId = get(scene, 'config.designId');
      let svg = null;
      let arraySVG = null;
      if (admin) {
        svg = downloadSvg(scene, 'string');
        const data = { objects: scene.objects, product_id, productoption_id, svg, currentBackground: scene.config.currentBackground };
        if (scene.config.mode === 'mask') {
          const maskedSvg = downloadSvg(scene, 'string', true);
          data.maskedSvg = maskedSvg;
        }
        if (designId) {
          data.id = designId;
        }
        const responseData = await this._call({ method: 'post', path: '/designs', data });
        store.dispatch(configActions.addDesign(responseData));
        store.dispatch(setDesign({ ...responseData }));
        return responseData;
      }
      if (mode === 'photo-print') {
        arraySVG = downloadSvg(scene, 'string');
      } else {
        svg = downloadSvg(scene, 'string');
      }
      const category_id = get(scene, 'config.categoryId');
      const imageIds = scene.objects.map((object) => object?.imageId || object?.image?.id).filter(Boolean);
      const photos = _photos.filter((photo) => includes(imageIds, photo.id));
      const data = { scene: { ...scene, photos }, svg, product_id, category_id, arraySVG, productoption_id };
      const responseData = await this._call({ method: 'post', path: `/design${id}`, data });
      const { id: newId } = responseData;
      this.setCanvasId(newId);
      return responseData;
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  /**
   * save scene and open URL to order
   */
  orderItem = async () => {
    try {
      ReactGA.event(orderGAEvent);
      const response = await this.saveState();
      if (!response) {
        const error = new Error('failed to save');
        Sentry.captureException(error);
        throwOrderError(error);
      } else if (!response.id) {
        const error = new Error('failed to order');
        Sentry.captureException(error);
        throwOrderError(error);
      } else {
        const { id } = response;
        const url = `${config.apiUrl}/api/v2/canvas/order/${id}`;
        if (!config.shouldOrderNewWindow) {
          disablePrevent();
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwOrderError(dismissible(exception));
    }
  };
}

export default new PixaPrints();
