import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getQuality } from 'utils/utils';
import { useIsCrop } from 'components/editors/Editor';
import useDimensions from 'hooks/useDimensions';
import { transformObject } from '../../utils';
import CropPhotoComponent from './CropPhotoComponent';


const CropPhotoContainer = ({ image, object, type, edgeWrap, sizes }) => {
  const [, toggleIsCrop] = useIsCrop();

  const [sceneMaxWidthPx, sceneMaxHeightPx, sceneContainerWidthPx, sceneContainerHeightPx] = useSelector(({ scene: { state } }) => [state.sceneWidthPx, state.sceneHeightPx, state.sceneContainerWidthPx, state.sceneContainerHeightPx]);
  const dimensions = useDimensions();
  const [editorWidth, editorHeight, frameWidth = 0] = useMemo(() => sizes || [dimensions.width, dimensions.height, dimensions.frame], [dimensions.frame, dimensions.height, dimensions.width, sizes]);
  const [newObject, editorWidthPx, editorHeightPx] = useMemo(() => {
    const newObject = transformObject(object, editorWidth + 2 * frameWidth, editorHeight + 2 * frameWidth);
    const scale = Math.min(sceneMaxWidthPx / newObject.width, sceneMaxHeightPx / newObject.height);
    return [newObject, newObject.width * scale, newObject.height * scale];
  },
  [object, editorWidth, editorHeight, sceneMaxWidthPx, sceneMaxHeightPx, frameWidth]);
  const newImage = useMemo(() => ({
    ...image,
  }), [image]);

  const quality = useMemo(() => getQuality(image, newObject),
    [image, newObject]);
  return (
    <CropPhotoComponent
      objectId={object.id}
      object={newObject}
      edgeWrap={edgeWrap}
      rotationMask={object.rotation}
      frameWidth={frameWidth}
      editorHeightPx={editorHeightPx} // TODO: rename to objectHeightPx
      editorWidthPx={editorWidthPx}
      editorWidth={editorWidth}
      editorHeight={editorHeight}
      image={newImage}
      quality={quality}
      toggleIsCrop={toggleIsCrop}
      viewWidth={sceneContainerWidthPx}
      viewHeight={sceneContainerHeightPx}
      type={type}
    />
  );
};

export default CropPhotoContainer;
