import React, { useState, useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import useIsMobile from 'hooks/useIsMobile';
import { faCheck, faSlidersH, faCrop, faTrashAlt, faLock, faLockOpen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';
import useUpdateDropZonePosition from 'hooks/useUpdateDropZonePosition';
import SliderStyle from '../components/SliderStyle';
import './EditFooterDropZone.scss';

export const EditFooterDropZone = ({ hide, toggleIsCrop, toggleIsEditing, object, deleteObject }) => {
  const { image } = object;
  const updateShapePosition = useUpdateDropZonePosition(object.id);
  const [showAdjust, setShowAdjust] = useState(false);
  const admin = useSelector(({ scene: { config: { admin } } }) => admin);
  const toggleShowAdjust = () => setShowAdjust(!showAdjust);
  const isMobile = useIsMobile();
  const updateObject = useUpdateObject();
  const lockObject = useCallback(() => { updateObject({ id: object.id, lock: !object.lock }); }, [object.id, object.lock, updateObject]);
  const variantsToolbar = useMemo(() => (
    isMobile ? {
      open: { opacity: 1, y: 0 },
      closed: { opacity: 0, y: '100%' },
      openTab: { y: 0 },
    } : {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '-100%' },
        openTab: { y: 0 },
      }
  ), [isMobile]);
  const variantsTab = useMemo(() => ({
    open: { opacity: 1, y: 0, height: 'auto' },
    closed: { opacity: 0, display: 'none', y: isMobile ? '100%' : '-100%', height: 0 },
  }), [isMobile]);

  return (
    <footer style={{ width: 0 }}>
      <div className="toolbar edit">
        <motion.div className="toolbar-view-container" initial="closed" variants={variantsToolbar} animate={showAdjust ? 'openTab' : 'open'} transition={{ duration: 0.15 }}>
          <div className={classnames('toolbar-view', { hide })}>
            <div className="done-button" onClick={toggleIsEditing}><FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1em', width: '1em' }} /></div>
            {image ? (
              <>
                <div onClick={toggleShowAdjust}><FontAwesomeIcon.Memo icon={faSlidersH} style={{ height: '1em', width: '1em' }} />Adjust</div>
                <div onClick={toggleIsCrop}><FontAwesomeIcon.Memo icon={faCrop} style={{ height: '1em', width: '1em' }} />Crop</div>
              </>
            ) : null }
            {admin ? (
              <>
                <div>
                  x:
                  <input
                    className="number-input"
                    type="number"
                    onChange={(e) => updateShapePosition({ x: parseFloat(e.target.value) || 0 })}
                    value={object.x}
                  />
                </div>
                <div>
                  y:
                  <input
                    className="number-input"
                    type="number"
                    onChange={(e) => updateShapePosition({ y: parseFloat(e.target.value) || 0 })}
                    value={object.y}
                  />
                </div>
                <div>
                  H:
                  <input
                    className="number-input"
                    type="number"
                    onChange={(e) => updateShapePosition({ height: parseFloat(e.target.value) })}
                    value={object.height}
                  />
                </div>
                <div>
                  W:
                  <input
                    className="number-input"
                    type="number"
                    onChange={(e) => updateShapePosition({ width: parseFloat(e.target.value) })}
                    value={object.width}
                  />
                </div>
              </>
              ) : null}
            {admin ? (<div onClick={() => lockObject()}><FontAwesomeIcon.Memo icon={object.lock ? faLock : faLockOpen} style={{ height: '1em', width: '1em' }} />{object.lock ? 'Locked' : 'Unlocked'}</div>) : null}
            {(admin || !object.lock) ? (<div onClick={() => deleteObject()}><FontAwesomeIcon.Memo icon={faTrashAlt} style={{ height: '1em', width: '1em' }} /></div>) : null}
          </div>
          {image ? (
            <>
              <motion.div className="display-table" initial="closed" variants={variantsTab} animate={showAdjust ? 'open' : 'closed'} transition={{ duration: 0.15 }}>
                <SliderStyle
                  key="sliderContrast"
                  objectId={object.id}
                  image={image}
                  label="Contrast"
                  parameter="contrast"
                  min={-50}
                  max={50}
                />
                <SliderStyle
                  key="sliderBrightness"
                  objectId={object.id}
                  image={image}
                  label="Brightness"
                  parameter="brightness"
                  min={-50}
                  max={50}
                />
                <SliderStyle
                  key="sliderSaturation"
                  objectId={object.id}
                  image={image}
                  label="Saturation"
                  parameter="saturation"
                  min={-100}
                  max={50}
                />
              </motion.div>
            </>
            ) : null }
        </motion.div>
      </div>
    </footer>
  );
};

export default EditFooterDropZone;
