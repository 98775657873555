import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import useDimensions from 'hooks/useDimensions';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDispatch } from 'react-redux';
import { removeObject } from 'actions/scene';
import { getHeightText } from 'utils/utils';
import useAllowArea from 'hooks/useAllowArea';
// import { documentToSVG, elementToSVG, inlineResources, formatXML } from 'dom-to-svg';
import { getCorners, redrawTransformer, handleDragTransformer, checkIsDraw, handleRotateTransformer } from './utils';
import useHandleDragCorner from './useHandleDragCorner';
import useChangeObject from './useChangeObject';
import { useDefaultPropsTransformer } from './constants';
import Circle from './Circle';
import { wrapDragFunction } from './wrapDragFunction';

const dragFunction = wrapDragFunction('text');

const dragCenterRight = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.centerLeft, endPoint: corners.centerRight }),
  getNewShape: ({ distance, shape }) => ({ ...shape, width: distance }),
});

const dragCenterLeft = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.topRightPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ projection, distance, shape }) => ({ ...shape, x: projection.x, y: projection.y, width: distance }),
});

// console.log('svgString:', 3 ?? 1);
const TextTransformer = ({ object, setIsEditing, textEditing, toggleTextEditing }) => {
  const dispatch = useDispatch();
  const objectId = useMemo(() => object.id, [object.id]);
  const deleteObject = useCallback(() => dispatch(removeObject({ id: objectId })), [dispatch, objectId]);
  const onChange = useChangeObject(object.id);
  const shapeTransformer = useRef();
  const { strokeWidth, stroke, rotationHeight, radius } = useDefaultPropsTransformer();
  const dimensions = useDimensions();
  useEffect(() => () => {
      // Capture specific element
      // const svgDocument = elementToSVG(document.querySelector('.toolbar'));
      // Get SVG string
      // const svgString = new XMLSerializer().serializeToString(svgDocument);
      // console.log('svgString:', svgString)
    }, [textEditing]);
  // const isEditing = useSelector(({ scene: { config: { isEditing } } }) => isEditing);
  // const defaultText = useMemo(() => ((textEditing && isEditing === objectId) ? '' : 'Enter text'), [isEditing, objectId, textEditing]);
  const editorWidth = dimensions.width + 2 * dimensions.frame;
  const editorHeight = dimensions.height + 2 * dimensions.frame;

  const allowArea = useAllowArea('text');

  const onDragEnd = useCallback(() => {
    if (shapeTransformer.current) {
      const shape = shapeTransformer.current;
      onChange({ x: shape.x, y: shape.y, width: shape.width, rotation: shape.rotation });
    }
  }, [onChange]);

  const redrawText = useCallback((shape) => {
    const text = d3.selectAll(`.text${objectId}`);
    text.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
    text.attr('width', shape.width);
  }, [objectId]);


  const drawTransform = useCallback(({ x, y, width, rotation }) => {
    const newHeight = getHeightText({ ...object, x, y, width, rotation });
    const shape = { x, y, width, height: newHeight, rotation };
    const { topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, rotationPos, topCenter, centerLeft, centerRight, bottomCenter, dragHandle } = getCorners({ ...shape, rotationHeight, radius });
    const [draw] = checkIsDraw({ topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, allowArea });
    if (draw) {
      redrawTransformer(object.id, shape, { topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, rotationPos, topCenter, centerLeft, centerRight, bottomCenter, dragHandle });
      redrawText(shape);
      shapeTransformer.current = shape;
    }
  }, [allowArea, object, radius, redrawText, rotationHeight]);

  useEffect(() => {
    const height = (object.height || object.fontSize);
    shapeTransformer.current = shapeTransformer.current || { x: 0, y: 0, width: 0, height: 0, rotation: 0 };
    const shape = { x: object.x, y: object.y, width: object.width, height, rotation: object.rotation };
    drawTransform({ ...shape });
  }, [drawTransform, object.fontSize, object.height, object.rotation, object.textArr, object.width, object.x, object.y]);

  const handleDragCorner = useHandleDragCorner(drawTransform, onDragEnd);
  useEffect(() => {
      const { rotationPos, centerLeft, centerRight } = getCorners({ ...shapeTransformer.current, rotationHeight, radius });
      handleDragTransformer(`#transformer${objectId}`, shapeTransformer.current, onDragEnd, drawTransform, allowArea);
      handleDragTransformer(`#dragHandle${objectId}`, shapeTransformer.current, onDragEnd, drawTransform, allowArea);
      handleRotateTransformer(`#rotate${objectId}`, shapeTransformer.current, drawTransform, onDragEnd, rotationPos);
      handleDragCorner(`#centerRight${objectId}`, dragCenterRight, shapeTransformer.current, centerRight);
      handleDragCorner(`#centerLeft${objectId}`, dragCenterLeft, shapeTransformer.current, centerLeft);
      d3.select(`#transformer${objectId}`)
      .on('mouseenter', () => {
        if (document.body.style.cursor === 'default') document.body.style.cursor = 'grab';
      })
      .on('mouseleave', () => {
        document.body.style.cursor = 'default';
      });
      // .on('click', (e) => {
      //   // if (!textEditing) {
      //   //   toggleTextEditing();
      //   //   e.stopPropagation();
      //   // }
      // });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawTransform, editorHeight, editorWidth, handleDragCorner, objectId, object.height, object.rotation, object.textArr, object.width, object.x, object.y, onDragEnd, radius]);

  // const objectHeight = useMemo(() => object.fontSize * (object.textArr?.length || 1), [object.fontSize, object.textArr]);
  // const { rotationPos, topCenter, centerRight, centerLeft } = useMemo(() => {
  //   const height = object.fontSize * (object.height || 1);
  //   return getCorners({ x: object.x, y: object.y, width: object.width, height, rotation: object.rotation, rotationHeight });
  // }, [object.fontSize, object.height, object.rotation, object.width, object.x, object.y, rotationHeight]);
  return (
    <>
      <KeyboardEventHandler
        handleKeys={['esc', 'up', 'right', 'down', 'left', 'ctrl+c', 'delete']}
        onKeyEvent={(key) => {
          key === 'esc' && setIsEditing(false);
          if (key === 'up') {
            drawTransform({ ...shapeTransformer.current, y: shapeTransformer.current.y - 1 });
            onDragEnd();
          }
          if (key === 'right') {
            drawTransform({ ...shapeTransformer.current, x: shapeTransformer.current.x + 1 });
            onDragEnd();
          }
          if (key === 'down') {
            drawTransform({ ...shapeTransformer.current, y: shapeTransformer.current.y + 1 });
            onDragEnd();
          }
          if (key === 'left') {
            drawTransform({ ...shapeTransformer.current, x: shapeTransformer.current.x - 1 });
            onDragEnd();
          }
          if (key === 'delete') {
            deleteObject();
          }
        }}
      />
      <g id="transform" opacity={textEditing ? 1 : 1}>
        <line id={`lineRotate${objectId}`} stroke={stroke} strokeWidth={`${strokeWidth}em`} />
        <rect
          id={`transformer${objectId}`}
          x={0}
          y={0}
          style={{ pointerEvents: textEditing ? 'none' : '' }}
          transform={`translate(${object.x}, ${object.y}) rotate(${object.rotation})`}
          width={object.width}
          fill="#ffffff00"
          strokeWidth={`${strokeWidth}em`}
          stroke={stroke}
          onClick={(e) => {
            if (!textEditing) {
              toggleTextEditing();
              e.stopPropagation();
            }
          }}
        />
        <Circle id={`rotate${objectId}`} rotate />
        <Circle id={`centerLeft${objectId}`} />
        <Circle id={`centerRight${objectId}`} />
        <Circle id={`dragHandle${objectId}`} hide={!textEditing} type="drag" />
      </g>
    </>
  );
};

export default TextTransformer;
