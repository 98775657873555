import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import CropFooter from 'components/footer/CropFooter';
import { pxToPct, pctToPx } from '../../utils';
import Image from '../../../NewEditor/components/Image';
import ZoomSlider from '../ZoomSlider';
import MaskCropPhoto from './components/MaskCropPhoto';
import EdgesCrop from './components/EdgesCrop';
import useUpdateObject from '../../hooks/useUpdateObject';
import MoveIndicator from './components/MoveIndicator';

const CropPhotoComponent = ({
  objectId,
  object,
  editorHeightPx,
  editorWidthPx,
  image,
  quality,
  frameWidth,
  editorWidth,
  editorHeight,
  toggleIsCrop,
  viewWidth,
  edgeWrap,
  viewHeight,
  type,
}) => {
  const [showMoveIndicator, setShowMoveIndicator] = useState(true);
  const updateEditorObject = useUpdateObject();
  const onDragStart = useCallback(() => {
      setShowMoveIndicator(false);
  }, []);
  const onDragEnd = useCallback(({ x, y }) => {
    const centerX = (object.width / 2 - x) / pctToPx(object.width, image.width);
    const centerY = (object.height / 2 - y) / pctToPx(object.width, image.height);
    updateEditorObject({ id: objectId, image: { centerX, centerY, x: pxToPct(object.width, x), y: pxToPct(object.width, y) } });
  }, [image.height, image.width, object.height, object.width, objectId, updateEditorObject]);
  const src = useSelector(({ photos: { photos, backgrounds, clipart } }) => {
    const photoId = image.imageId || image.id;
    return (find(photos, { id: photoId }) || find(backgrounds, { id: photoId }) || find(clipart, { id: photoId }) || {}).src;
  });
  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <svg width={`${editorWidthPx}px`} height={`${editorHeightPx}px`} viewBox={`0 0 ${object.width} ${object.height}`} style={{ overflow: 'visible', margin: 'auto' }}>
        <Image object={{ image, width: object.width, height: object.height }} src={src} onClick={toggleIsCrop} offClipPath draggable onDragEnd={onDragEnd} onDragStart={onDragStart} />
        <MaskCropPhoto type={type} object={object} showOverlay editorWidth={editorWidth} editorHeight={editorHeight} viewWidth={(viewWidth / editorWidthPx) * object.width} viewHeight={(viewHeight / editorHeightPx) * object.height} />
        <EdgesCrop object={object} frameWidth={frameWidth} type={type} edgeWrap={edgeWrap} />
        <MoveIndicator width={object.width} height={object.height} hide={!showMoveIndicator} />
      </svg>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', display: 'flex' }}>
        <ZoomSlider flat objectId={objectId} image={image} objectWidth={object.width} objectHeight={object.height} editorHeightPx={editorHeightPx} />
        <CropFooter quality={quality} toggleIsCrop={toggleIsCrop} />
      </div>
    </div>
  );
};

export default CropPhotoComponent;
