import { useCallback } from 'react';
import ReactGA from 'react-ga';
import { crop } from 'constants/GA';
import { useSelector, shallowEqual } from 'react-redux';
// import useUpdateConfig from 'hooks/useUpdateConfig';
import store from 'store';
import useUpdateIsEditing from 'hooks/useUpdateIsEditing';

export const useSetIsEditing = () => {
  const updateConfig = useUpdateIsEditing();
  const setIsEditing = useCallback((newValue) => {
    updateConfig(newValue);
    if (newValue) { ReactGA.event(crop); }
  }, [updateConfig]);
  return setIsEditing;
};
export const useToggleIsEditing = () => {
  const setIsEditing = useSetIsEditing();
  const toggleIsEditing = useCallback(() => { setIsEditing(!store.getState().scene.config.isEditing); }, [setIsEditing]);
  return toggleIsEditing;
};

const useIsEditing = () => {
  const isEditing = useSelector(({ scene }) => scene.config.isEditing, shallowEqual);
  const setIsEditing = useSetIsEditing();
  const toggleIsEditing = useToggleIsEditing();
  return [isEditing, toggleIsEditing, setIsEditing];
};

export default useIsEditing;
