import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faExpand, faImages, faSquare, faPaintRoller, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import PhotoLibrary from 'components/toolbar/PhotoLibrary';
import Customize from 'components/toolbar/Colors';
import Borders from 'components/toolbar/Borders';
import Edges from 'components/toolbar/Edges';
import Sizes from 'components/toolbar/Sizes';
import Layouts from 'components/toolbar/Layouts';
import Backgrounds from 'components/toolbar/Backgrounds';
import Toolbar from './Toolbar';
import Designs from '../Designs';

const RenderIfActive = ({ isActive, children }) => (isActive ? children : null);
const withRenderIfActive = (C) => ({ isActive, ...props }) => <RenderIfActive isActive={isActive}><C {...props} /></RenderIfActive>;

export const toolbarItems = ({ admin, mode, showLayouts, showBackgroundPicker, showDesignsTab }) => [
  includes(['mask', 'photo-print', 'canvas', 'poster'], mode) ? {
    id: 'photos',
    title: 'Photos',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faImages} size={size} />,
    component: withRenderIfActive(PhotoLibrary),
  } : null,
  includes(['mask', 'canvas', 'poster'], mode) && showDesignsTab ? {
    id: 'designs',
    title: 'Designs',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faPaintRoller} size={size} />,
    component: withRenderIfActive(Designs),
  } : null,
  showLayouts && includes(['mask', 'canvas', 'poster'], mode) ? {
    id: 'layouts',
    title: 'Layouts',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faColumns} size={size} />,
    component: withRenderIfActive(Layouts),
  } : null,
  {
    id: 'sizes',
    title: 'Sizes',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faExpand} size={size} />,
    component: withRenderIfActive(Sizes),
  },
  includes(['mask', 'canvas', 'poster'], mode) ? {
    id: 'customize',
    title: 'Customise',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faLayerPlus} size={size} />,
    component: withRenderIfActive(Customize),
  } : null,
  (admin || showBackgroundPicker) && includes(['photo-print'], mode) ? {
    id: 'Backgrounds',
    title: 'Backgrounds',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faLayerPlus} size={size} />,
    component: withRenderIfActive(Backgrounds),
  } : null,
  includes(['photo-print'], mode) ? {
    id: 'borders',
    title: 'Borders',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faSquare} size={size} />,
    component: withRenderIfActive(Borders),
  } : null,
  includes(['canvas'], mode) ? {
    id: 'edges',
    title: 'Frame',
    icon: ({ size }) => <FontAwesomeIcon.Memo icon={faSquare} size={size} />,
    component: withRenderIfActive(Edges),
  } : null,
].filter(Boolean);

export const useToolbarItems = () => {
  const showDesignsTab = useSelector(({ config: { designs }, scene: { config: { productId } } }) => designs?.filter((design) => design.product_id === productId).length || false);
  const [showLayouts, showBackgroundPicker, mode, admin] = useSelector(({ scene: { config: { layouts, mode, showBackgroundPicker, admin } } }) => [
    Array.isArray(layouts) && layouts.length > 1,
    showBackgroundPicker,
    mode,
    admin,
  ], isEqual);
  const items = useMemo(() => toolbarItems({ admin, mode, showLayouts, showBackgroundPicker, showDesignsTab }), [admin, mode, showBackgroundPicker, showDesignsTab, showLayouts]);
  return items;
};

export const ToolbarContainer = (props) => {
  const items = useToolbarItems();
  return <Toolbar items={items} {...props} />;
};

export default ToolbarContainer;
