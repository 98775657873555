/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { addClipart } from 'actions/scene';
import Grid, { BackButton } from 'components/common/Grid';
import classnames from 'classnames';
import './Clipart.css';

const Photo = ({ id, thumbnailSrc, isActive, onClick, photo }) => {
  const handleClick = useCallback(() => {
    store.dispatch(addClipart({ ...photo }));
  }, [photo]);
  return (
    <Grid.Item key={id} innerClassName={classnames({ checked: isActive })}>
      <div>
        <img
          alt={id}
          style={{ objectFit: 'contain' }}
          src={thumbnailSrc}
          onClick={onClick || handleClick}
        />
      </div>
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);


const Clipart = ({ onClickBackButton }) => {
  const photos = useSelector(({ photos: { clipart } }) => clipart || []);
  const sizeCalculated = photos.length ? '50%' : '100%';
  return (
    <div className="colors-container">
      <Grid doNotWrapIntoMotionDiv={photos.length > 20} size={sizeCalculated}>
        <BackButton onClick={onClickBackButton} />
        {
          photos.map((photo) => (
            <MemoPhoto
              key={photo.id}
              photo={photo}
              {...photo}
            />
          ))
        }
      </Grid>
    </div>
  );
};

export default Clipart;
