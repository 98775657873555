import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { edgeWrapOptions, frameWidthOptions } from 'constants/editor';
import { updateConfig, setEdgeWrap } from 'actions/scene';
import VerticalSelect from 'components/common/VerticalSelect';
import './Edges.css';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import filter from 'lodash/filter';

const useUpdateConfig = () => {
  const dispatch = useDispatch();
  return React.useCallback((config) => dispatch(updateConfig(config)), [dispatch]);
};

const useUpdateEdgeWrap = () => {
  const dispatch = useDispatch();
  return React.useCallback((config) => dispatch(setEdgeWrap(config)), [dispatch]);
};


const Edges = () => {
  const edgeWrap = useSelector(({ scene }) => scene.config.edgeWrap);
  const dimensions = useSelector(({ scene }) => scene.config.dimensions);
  const updateEdgeWrap = useUpdateEdgeWrap();

  const updateConfig = useUpdateConfig();
  const currentLayoutApertures = useSelector(({ scene: { objects } }) => filter(objects, (object) => object.type === 'layout'));

  const setEdgeWrap = React.useCallback((edgeWrap) => updateEdgeWrap({ edgeWrap }), [updateEdgeWrap]);
  const setFrameWidth = React.useCallback((frame) => updateConfig({ dimensions: { ...dimensions, frame } }), [dimensions, updateConfig]);
  return (
    <div className="edge-tab">
      {
        (currentLayoutApertures.length === 1)
          ? (
            <div>
              <VerticalSelect
                options={edgeWrapOptions}
                onChange={setEdgeWrap}
                value={edgeWrap}
                onlyInnerDelimiters
                preservePaddingForCheck
              />
            </div>
          ) : null
      }
      <div>
        <h2>Frame thickness</h2>
        <div>
          <MultiOptionButton options={frameWidthOptions} onChange={setFrameWidth} value={dimensions.frame} />
        </div>
      </div>
    </div>
  );
};

export default Edges;
