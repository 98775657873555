import { useCallback } from 'react';
import isNil from 'lodash/isNil';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';

export default (objectId) => {
  const updateObject = useUpdateObject();
  const onChange = useCallback(({ ...newRectProps }) => {
    const newProps = { id: objectId };
    if (!isNil(newRectProps.x)) newProps.x = newRectProps.x;
    if (!isNil(newRectProps.y)) newProps.y = newRectProps.y;
    if (newRectProps.fontSize) newProps.fontSize = newRectProps.fontSize;
    if (newRectProps.rotation) newProps.rotation = newRectProps.rotation;
    if (newRectProps.height) newProps.height = newRectProps.height;
    if (newRectProps.width) newProps.width = newRectProps.width;
    updateObject(newProps);
  }, [objectId, updateObject]);
  return onChange;
};
