
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import SVGPage from './components/SVGPage';
// import Base from './components/Base';


export default (scene) => {
  const rawSvg = ReactDOMServer.renderToStaticMarkup(<SVGPage {...scene} />);
  return rawSvg;
};
