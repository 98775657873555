import { useSelector, shallowEqual } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';
import { EDGE_WRAP } from 'constants/editor';

export const getDimensionsSync = ({ scene: { config: { dimensions, orientation, edgeWrap, mode, layouts, layoutId, layoutGutterType } } }) => {
  const layout = get(find(layouts, { id: layoutId }), 'layout', []);
  const isFullScreen = !(layoutGutterType === 'GUTTER_IN_OUT') || layout.length === 1;
  const newDimensions = { ...dimensions };
  newDimensions.originalFrame = mode === 'canvas' ? dimensions.frame : 0;
  newDimensions.frame = newDimensions.originalFrame && edgeWrap === EDGE_WRAP ? dimensions.frame : 0;
  if (orientation === 'portrait') {
    [newDimensions.width, newDimensions.height] = [newDimensions.height, newDimensions.width];
  }
  if (isFullScreen) {
    newDimensions.width += dimensions.halfBleed * 2;
    newDimensions.height += dimensions.halfBleed * 2;
  }
  return newDimensions;
};

const useDimensions = () => useSelector(getDimensionsSync, shallowEqual);

export default useDimensions;
