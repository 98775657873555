import * as Sentry from '@sentry/browser';
import config from 'config';
import LZString from 'lz-string';
import store, { ReduxActionsLogQueue } from './store';
import { ApiCallsLogQueue } from './api/api';

function chunkSubstr(str, size) {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
}
export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    // eslint-disable-next-line consistent-return
    return value;
  };
};

export const JSONStringifyCircular = (objects) => JSON.stringify(objects, getCircularReplacer());
let errorInBeforeSendLogged = false;
const logErrorInBeforeSend = (...params) => {
  errorInBeforeSendLogged || (errorInBeforeSendLogged = console.error(...params) || 1);
};

const maxCharsInOneContext = 8192 - 42; // 8150

// example request size is 170kb
// 10 => 94kb
// 21 => 188kb

const maxContexts = 20;

if (config.isProduction) {
  console.log('Sentry is enabled') || Sentry.init({
    dsn: config.sentryDsn,
    beforeSend: (data) => { // sentry max request size is 200kb
      try {
        const getChunks = (obj) => {
          const compressed = LZString.compressToEncodedURIComponent(JSONStringifyCircular(obj));
          return chunkSubstr(compressed, maxCharsInOneContext);
        };
        const store1 = store.getState();
        let smallData = {};
        try {
          smallData = { app: store1.app, scene: store1.scene };
        } catch (e) {
          logErrorInBeforeSend(e);
        }
        const bigData = {
          ...smallData,
          photos: store1.photos.photos,
          ApiCallsLogQueue: ApiCallsLogQueue.getElements(),
          ReduxActionsLogQueue: ReduxActionsLogQueue.getElements(),
        };

        try {
          bigData.body = document.querySelector('body').outerHTML;
        } catch (e) {
          logErrorInBeforeSend(e);
        }

        let chunks = getChunks(bigData);
        if (chunks.length > maxContexts) chunks = getChunks(smallData);

        if (!data.contexts) data.contexts = {};
        chunks.forEach((c, index) => {
          data.contexts[`JSON_LZString_${index}`] = { data: c };
        });
      } catch (e) { console.log(e); }
      return data;
    },
  });
}
