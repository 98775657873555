import React from 'react';
import isEqual from 'lodash/isEqual';
import './VerticalSelect.css';
import classnames from 'classnames';

const Select = ({ options, value, onChange, disabled, onlyInnerDelimiters, preserveDescriptionHeight, preservePaddingForCheck }) => (
  <div className={classnames('vertical-select-container', { 'only-inner-delimiters': onlyInnerDelimiters })}>
    {options.map((option) => (
      <Option
        key={JSON.stringify(option.value)}
        {...option}
        selected={isEqual(option.value, value)}
        onChange={onChange}
        disabled={disabled}
        preserveDescriptionHeight={preserveDescriptionHeight}
        preservePaddingForCheck={preservePaddingForCheck}
      />
    ))}
  </div>
);

const Option = ({ value, label, image, selected, preserveDescriptionHeight, description = preserveDescriptionHeight && ' ', onChange, preservePaddingForCheck, disabled }) => (
  <div className="vertical-select-option" onClick={() => { !disabled && onChange(value); }}>
    <div
      className={classnames(
        'vertical-select-option-content',
        'no-selection', {
        checked: selected,
        'disabled-cursor': disabled,
        // hasImage: !!image,
        'empty-description': !description,
      },
      )}
    >
      {image && <img alt={label} src={image} />}
      <div>
        <label>
          {label}
        </label>
        <div className={classnames('small', { 'preserve-padding-for-check': preservePaddingForCheck })}>
          <small>{description}</small>
        </div>
      </div>
    </div>
  </div>
);

export default Select;
