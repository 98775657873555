import useDimensions from 'hooks/useDimensions';
import React, { useMemo } from 'react';

const ImageOverlay = ({ Overlay, scaleOverlay, isRotateMask, object = {}, style = {}, mask }) => {
  const dimensions = useDimensions();
  const objectX = -(object.x || 0);
  const objectY = -(object.y || 0);
  const [x, y, width, height] = useMemo(() => {
    let x = -(dimensions.width * scaleOverlay - dimensions.width) / 2;
    let y = -(dimensions.height * scaleOverlay - dimensions.height) / 2;
    let width = dimensions.width * scaleOverlay;
    let height = dimensions.height * scaleOverlay;
    if (isRotateMask) {
      [x, y] = [y, x];
      [width, height] = [height, width];
    }
    return [x, y, width, height];
  }, [dimensions.height, dimensions.width, isRotateMask, scaleOverlay]);
  const transform = useMemo(() => (isRotateMask ? `rotate(${(-object.rotation || 0)}) translate(${objectX} ${objectY}) translate(${dimensions.width}, 0) rotate(${90}, ${0}, ${0}) ` : `rotate(${-object.rotation || 0})  translate(${objectX} ${objectY})`), [dimensions.width, isRotateMask, object.rotation, objectX, objectY]);
  return (
    <g mask={mask}>
      <image style={{ pointerEvents: 'none', ...style }} xlinkHref={Overlay} x={x} y={y} width={width} height={height} transform={transform} />
    </g>
  );
};


export default ImageOverlay;
