import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import useIsFullScreen from 'hooks/useIsFullScreen';
import isEqual from 'lodash/isEqual';
import { getCanvasDimensions } from '../utils';

const useEditorSizePx = () => {
  const dimensions = useSelector(({ scene }) => scene.config.dimensions, isEqual);
  const orientation = useSelector(({ scene }) => scene.config.orientation, shallowEqual);
  const scaleUnderlay = useSelector(({ scene: { config: { scaleUnderlay } } }) => scaleUnderlay);
  const mode = useSelector(({ scene }) => scene.config.mode, shallowEqual);
  const isFullScreen = useIsFullScreen();
  const [sceneMaxWidthPx, sceneMaxHeightPx] = useSelector(({ scene }) => [scene.state.sceneWidthPx, scene.state.sceneHeightPx], isEqual);
  const dimensionsPx = useMemo(
    () => getCanvasDimensions(dimensions, orientation, isFullScreen, mode, [sceneMaxWidthPx, sceneMaxHeightPx], scaleUnderlay),
    [dimensions, orientation, isFullScreen, mode, sceneMaxWidthPx, sceneMaxHeightPx, scaleUnderlay],
  );
  return dimensionsPx;
};

export default useEditorSizePx;
