import imageOnEdge from 'assets/image-on-edge.jpg';
import whiteEdges from 'assets/white-edges.jpg';

export const EDGE_WRAP = 'wrap';
export const EDGE_NOWRAP = 'nowrap';

export const edgeWrapOptions = [
  {
    value: EDGE_NOWRAP,
    label: 'Colour edges',
    description: 'Reduces cropping',
    image: whiteEdges,
  },
  {
    value: EDGE_WRAP,
    label: 'Image on edges',
    description: 'Known as gallery wrap',
    image: imageOnEdge,
  },
];

export const frameWidthOptions = [
  {
    value: 18,
    label: '18mm frame',
  },
  {
    value: 38,
    label: '38mm frame',
  },
];

export const orientationOptions = [
  {
    value: 'portrait',
    label: 'Portrait',
  },
  {
    value: 'landscape',
    label: 'Landscape',
  },
];

export const laminationOptions = [
  {
    value: 'gloss',
    label: 'Gloss paper',
  },
  {
    value: 'matt',
    label: 'Matt paper',
  },
];

export const layoutGutterTypeOptions = [
  {
    value: 'GUTTER_IN',
    label: 'Space between photos',
  },
  {
    value: 'GUTTER_IN_OUT',
    label: 'Space photos & edges',
  },

  {
    value: 'NO_GUTTER',
    label: 'No space',
  },
];

export const borderTypeOptions = [
  {
    value: 'border',
    label: 'Border',
    // description: 'Known as gallery wrap',
    // image: imageOnEdge,
  },
  {
    value: 'no-border',
    label: 'No border',
    // description: 'Known as gallery wrap',
    // image: imageOnEdge,
  },
  {
    value: 'retro',
    label: 'Retro',
    // description: 'Known as gallery wrap',
    // image: imageOnEdge,
  },
];

export const SINGLE_ONLY = 'SINGLE_ONLY';
export const MULTI_ONLY = 'MULTI_ONLY';
export const MIXED = 'MIXED';

// export const sizeOptions = [
//   {
//     id: '3',
//     label: '12" x 16"',
//     value: {
//       dimensions: {
//         height: inchesToMm(12),
//         width: inchesToMm(16),
//       },
//       xLabel: '16"',
//       yLabel: '12"',
//     },
//     description: '$12.00',
//     // TODO: define arrays
//     layouts: [
//       {
//         id: '1',
//         apertures: [{ id: 'a1', x: 0, y: 0, width: 123, height: 321 }],
//       },
//     ],
//   },
// ];

// https://bugzilla.mozilla.org/show_bug.cgi?id=1355389
// https://bugzilla.mozilla.org/show_bug.cgi?id=1456557
// https://caniuse.com/#feat=input-file-multiple
export const MozillaAndroidDoesNotSupportMultiFileSelect = navigator.userAgent.includes('Gecko/') && navigator.userAgent.includes('Android');


export const noPreviewImage = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAYGBgYHBgcICAcKCwoLCg8ODAwODxYQERAREBYiFRkVFRkVIh4kHhweJB42KiYmKjY+NDI0PkxERExfWl98fKcBBgYGBgcGBwgIBwoLCgsKDw4MDA4PFhAREBEQFiIVGRUVGRUiHiQeHB4kHjYqJiYqNj40MjQ+TERETF9aX3x8p//CABEIAGAAkAMBIgACEQEDEQH/xAAbAAEAAwEBAQEAAAAAAAAAAAAABAUGAwECB//aAAgBAQAAAAD9hAAAAAAAAAAFpde1dN8gdpVeXOhEPL8QXVzjE3WehGyfLpZ8a978/WvkgQMrrZiHmozQ3IBRXo552VeADz0AAAAAAAAH/8QAFwEBAQEBAAAAAAAAAAAAAAAAAAECA//aAAgBAhAAAADqAAiiWYa1lpiGsqgAD//EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAIAQMQAAAA5AAKgsu2c6ZbpnSKAA//xAAvEAACAQIDBgUDBQEAAAAAAAABAgMEEQAhMQUQEjJBURMgIjDBYWJxM1BgcoGx/9oACAEBAAE/AP4BSbMeYB5CUTp3OI6CkjGUKn6tmcPQ0jjOFf8AMsVGyGALQNf7TrhlZWKsCCNQfYgglnfgjFz17AYqNm1EEZclWUa26eTZtD4lppR6Ryjv5aujiqVzyYaNieCSCQo4sf8Avn2PIivKhNma1vrbFTIkUEjPpwkfnfQ0pqZrHkXNj8YAAAAFgPNVUsdTHwtkRyt2OJonhkZHFiN0cbyuERSWPQYXY85HqkQHtmcS7LqoxcAOPtwQQbHczu3MxP5N9yIzsqqLkmwGKWnWnhVBrqx7n2K6jFTHl+ovKfjBBBIIsRiipFporaueY76qihqB6hZujDXFTSy078LjI6MNDv2XR8C+O49RHpHYe1tWj1qEH9x8+WWJJUKOtwcT7KqEc+GONenQ4o9lFWDz2y0QfPtkAggi4P7v/8QAGREBAAMBAQAAAAAAAAAAAAAAAQAQESAw/9oACAECAQE/APbZvK0tjSwaeRsZvv8A/8QAGhEBAAIDAQAAAAAAAAAAAAAAAQAQERIgMP/aAAgBAwEBPwD2xMPIGKC0rWa0cpaQPf8A/9k=';
