import React from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import Image from './Image';

const Clipart = ({ object, onClick }) => {
  const src = useSelector(({ photos: { clipart } }) => object?.image && find(clipart, { id: object.image.id })?.src);
  return (
    <>
      <Image object={object} src={src} onClick={onClick} />
    </>
  );
};


export default Clipart;
