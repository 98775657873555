import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import './Modal.css';


const useLocalApiOptions = [
  {
    value: true,
    label: 'Use Local Api',
  },
  {
    value: false,
    label: 'Use default Api',
  },
];

const generateOptions = (array) => array.map((value) => ({ value, label: value.toString() }));

const apiOptions = [
  {
    key: 'mode',
    options: generateOptions(['canvas', 'photo-print', 'poster', 'mask']),
  },
  {
    key: 'enableMultiLayout',
    options: generateOptions([true, false]),
  },
  {
    key: 'borderType',
    options: generateOptions(['no-border', 'border', 'retro']),
  },
  {
    key: 'layoutMode',
    options: generateOptions(['SINGLE_ONLY', 'MULTI_ONLY', 'MIXED']),
  },
  {
    key: 'showBackgroundPicker',
    options: generateOptions([true, false]),
  },
  {
    key: 'defaultBackground',
    options: generateOptions(['white', 'black', 'yellow', 'green']),
  },
  {
    key: 'layoutGutterType',
    options: generateOptions(['NO_GUTTER', 'GUTTER_IN_OUT', 'GUTTER_IN']),
  },
  {
    key: 'showLayoutGutterSwitch',
    options: generateOptions([true, false]),
  },
  {
    key: 'printLimitMin',
    type: 'int',
  },
  {
    key: 'printLimitMax',
    type: 'int',
  },
];

const MultiOption = ({ label, options, value, onChange }) => (
  <div style={{ marginTop: '15px' }}>
    {label}:
    <MultiOptionButton options={options} onChange={onChange} value={value} />
  </div>
);

const IntOption = ({ label, value, onChange }) => (
  <div style={{ marginTop: '15px' }}>
    <div>{label}:</div>
    <div style={{ marginLeft: 'auto', width: '150px', marginRight: 'auto' }}>
      <input
        style={{ width: '150px' }}
        type="number"
        min="1"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </div>
  </div>
);

const Modal = ({ isMobile }) => {
  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      overflow: 'scroll',
      maxHeight: '80%',
      border: 'unset',
      borderRadius: 10,
      width: '80%',
      maxWidth: '600px',
      ...isMobile && { padding: '20px' },
      transform: 'translate(-50%, -50%)',
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const [useLocalApi, setUseLocalApi] = useStateWithLocalStorage('useLocalApi', false);
  const [localApi, setLocalApi] = useStateWithLocalStorage('localApi', {});
  const changeLocalApi = useCallback((key) => (value) => {
    const newLocalApi = { ...localApi };
    newLocalApi[key] = value;
    setLocalApi(newLocalApi);
  }, [localApi, setLocalApi]);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="download-button">
        <FontAwesomeIcon.Memo icon={faEdit} style={{ height: '1.4em', width: '1.4em' }} />
      </Button>
      <ReactModal
        shouldReturnFocusAfterClose={false} // after closing modal, will not select last used element
        style={modalStyle}
        isOpen={!!isOpen}
        ariaHideApp={false}
      >
        <MultiOptionButton options={useLocalApiOptions} onChange={setUseLocalApi} value={useLocalApi} />
        {useLocalApi && apiOptions.map(({ key, options, type }) => {
          if (options) {
            return (<MultiOption key={key} label={key} options={options} value={localApi[key]} onChange={changeLocalApi(key)} />);
          }
          if (type === 'int') {
            return (<IntOption key={key} label={key} value={localApi[key]} onChange={changeLocalApi(key)} />);
          }
          return null;
        })}
        <Button onClick={() => window.location.reload(false) && setIsOpen(false)}>
          Close
        </Button>
      </ReactModal>
    </>
  );
};
const MemoModal = React.memo(Modal);

export default MemoModal;
