export const crop = ({
  category: 'canvas_editor',
  action: 'Adjust crop',
});
export const saveState = ({
  category: 'shop',
  action: 'Save state',
});
export const order = ({
  category: 'shop',
  action: 'Order',
});
