import React from 'react';
import './Modal.css';
import Button from 'components/common/Button';
import Lottie from 'react-lottie';
import * as warningAnimationData from './12470-warning.json';

const warningAnimationOption = (isEdge) => ({
  loop: false,
  autoplay: !isEdge,
  animationData: warningAnimationData.default,
  rendererSettings: {
    viewBoxSize: '75 75 350 350',
  },
});

const LoadingModal = ({ onClose, title = ' Oops...', message, closeButtonText = 'OK' }) => (
  <div className="modal processing">
    <h2 className="modal-header">
      <div className="modal-header-icon">
        <Lottie
          options={warningAnimationOption()}
          height={50}
          width={50}
          isStopped={false}
          isPaused={false}
        />
      </div>
      <div className="modal-header-text">
        {title}
      </div>
    </h2>
    <div className="modal-text">
      {message}
    </div>
    <Button
      color="azure"
      rounded
      size="lg"
      onClick={onClose}
    >{closeButtonText}
    </Button>
  </div>
);


export default LoadingModal;
