export default class LogQueue {
  constructor(maxSize = 5) {
    this.maxSize = maxSize;
  }

  queue = [];

  enqueue = (el) => {
    try {
      this.queue.push([new Date(), el]);
      while (this.queue.length > this.maxSize) {
        this.queue.shift();
      }
    } catch (e) { console.error(e); }
  }

  getElements = () => this.queue
}
