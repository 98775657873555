import React, { useState, useCallback, useRef } from 'react';
import Slider from 'rc-slider';
import throttle from 'lodash/throttle';
import store from 'store';
import get from 'lodash/get';
import useUpdateObject from 'components/editors/Editor/hooks/useUpdateObject';
import { usePrevious } from 'components/editors/Editor/hooks/useDimensionsChangeEffect';

const SliderStyle = ({ objectId, image, label, parameter, min, max }) => {
  const [, rerender] = useState(0);
  const valueRef = useRef(0);

  const valueFromRedux = get(image, parameter);
  if (usePrevious({ valueFromRedux }).valueFromRedux !== valueFromRedux) valueRef.current = valueFromRedux;
  const value = valueRef.current;

  const updateEditorObject = useUpdateObject();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEditorObjectThrottle = useCallback(throttle((object) => updateEditorObject(object), 1000 / 10), [updateEditorObject]);
  const setValueToRedux = useCallback(() => window.setTimeout(() => {
    if (parameter) {
      const newObject = { id: objectId };
      newObject.image = { [parameter]: valueRef.current };
      updateEditorObjectThrottle(newObject);
    }
  }, 300), [objectId, parameter, updateEditorObjectThrottle]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newSetValue = useCallback(throttle((value, contrast, brightness, saturation, id) => {
    valueRef.current = value;
    rerender((p) => !p);
    if (parameter) {
      const imageFilters = { contrast, brightness, saturation };
      imageFilters[parameter] = value;
      const { mode } = store.getState().scene.config;
      if (mode === 'photo-print') {
        // contrast
        imageFilters.contrast = (imageFilters.contrast / 100 + 1) ** 2;
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(1) feFuncR`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(1) feFuncG`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(1) feFuncB`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        // brightness
        imageFilters.brightness = imageFilters.brightness / 100 + 1;
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(2) feFuncR`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(2) feFuncG`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        [...document.querySelectorAll(`.image${id} svg filter feComponentTransfer:nth-of-type(2) feFuncB`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        // saturation
        imageFilters.saturation += 100;
        [...document.querySelectorAll(`.image${id} svg filter feColorMatrix`)].forEach((e) => {
          const a = imageFilters.saturation / 100;
          const b = 0.3086 * (1 - a),
            c = 0.6094 * (1 - a),
            d = 0.082 * (1 - a);
          e.setAttribute('values', [b + a, c, d, 0, 0, b, c + a, d, 0, 0, b, c, d + a, 0, 0, 0, 0, 0, 1, 0]);
        });
      } else {
        // contrast
        imageFilters.contrast = (imageFilters.contrast / 100 + 1) ** 2;
        [...document.querySelectorAll(`#filter_${objectId} feComponentTransfer:nth-of-type(1) feFuncR`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        [...document.querySelectorAll(`#filter_${objectId} feComponentTransfer:nth-of-type(1) feFuncG`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        [...document.querySelectorAll(`#filter_${objectId} feComponentTransfer:nth-of-type(1) feFuncB`)].forEach((e) => {
          e.slope.baseVal = imageFilters.contrast;
          e.intercept.baseVal = -(0.5 * imageFilters.contrast) + 0.5;
        });
        // brightness
        imageFilters.brightness = imageFilters.brightness / 100 + 1;
        [...document.querySelectorAll(`#filter_${objectId}  feComponentTransfer:nth-of-type(2) feFuncR`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        [...document.querySelectorAll(`#filter_${objectId}  feComponentTransfer:nth-of-type(2) feFuncG`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        [...document.querySelectorAll(`#filter_${objectId}  feComponentTransfer:nth-of-type(2) feFuncB`)].forEach((e) => {
          e.slope.baseVal = imageFilters.brightness;
        });
        // saturation
        imageFilters.saturation += 100;
        [...document.querySelectorAll(`#filter_${objectId}  feColorMatrix`)].forEach((e) => {
          const a = imageFilters.saturation / 100;
          const b = 0.3086 * (1 - a),
            c = 0.6094 * (1 - a),
            d = 0.082 * (1 - a);
          e.setAttribute('values', [b + a, c, d, 0, 0, b, c + a, d, 0, 0, b, c, d + a, 0, 0, 0, 0, 0, 1, 0]);
        });
      }
    }
  }, 1000 / 60), []);

  const onChangeValue = useCallback((value) => newSetValue(value, image.contrast, image.brightness, image.saturation, image.id), [newSetValue, image.contrast, image.brightness, image.saturation, image.id]);
  return (
    <div className="tr">
      <div className="label-slider">
        {label}
      </div>
      <div className="slider-container">
        <Slider
          min={min}
          max={max}
          trackStyle={{ backgroundColor: 'black' }}
          handleStyle={{
            borderWidth: '0px',
            boxShadow: '0 0 5px grey',
            height: 16,
            width: 16,
            marginTop: -6,
            backgroundColor: 'white',
          }}
          railStyle={{ backgroundColor: 'var(--background-grey)', height: 4 }}
          value={value}
          onAfterChange={setValueToRedux}
          onChange={onChangeValue}
        />
      </div>
      <div>
        <div className="value-slider">
          {value}
        </div>
      </div>
    </div>
  );
};

export default SliderStyle;
