/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo, useCallback } from 'react';
import Button from 'components/common/Button';
import { setImage, addObject, addObjects, updateObject, updateScene, deleteImage } from 'actions/scene';
import * as photoActions from 'actions/photos';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useDrag } from 'react-dnd';
import ProgressBar from 'components/common/ProgressBar';
import Grid, { BackButton } from 'components/common/Grid';
import classnames from 'classnames';
import ReactFileReader from 'react-file-reader';
import filter from 'lodash/filter';
import uuid from 'uuid';
import ImageUploader from 'utils/image-upload/adapters/pixaprints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faHandPointUp } from '@fortawesome/pro-regular-svg-icons';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import store from 'store';
import GalleryExplorer from 'utils/gallery-explorer';
import { useSetIsEditing } from 'components/editors/Editor';
import last from 'lodash/last';
import { open, close } from 'actions/modal';
import LinesEllipsis from 'react-lines-ellipsis';
import find from 'lodash/find';
import chunk from 'lodash/chunk';
import { MozillaAndroidDoesNotSupportMultiFileSelect } from 'constants/editor';
import { generateImagePreview, getPrintLimitMaxMinAndObjectsLengthSync, idMap } from './utils';
import './PhotoLibrary.scss';

const MemoReactFileReader = React.memo(ReactFileReader);

const selectedOnboardingImage = { selected: false };
const handleClickOnImage = (image, { onClick, dispatch }) => {
  dispatch(addObject(image));
  onClick && onClick();
};

const onDragEnd = (photo, id, objects, dispatch, mode) => {
  let imageId = photo.id;
  if (idMap[imageId]) {
    imageId = idMap[imageId];
  }
  const newPhoto = find(store.getState().photos.photos, { id: imageId });
  if (mode === 'photo-print') {
    dispatch(addObject(newPhoto));
  } else {
    dispatch(setImage({ id, image: newPhoto }));
  }
};

const uploader = new ImageUploader();
const explorer = new GalleryExplorer();
export const uploadAndWriteToStore = async (files, onClick) => {
  const folderId = explorer.getCurrentFolder();
  const { objects, config: { mode } } = store.getState().scene;
  const isAnyImageLoaded = selectedOnboardingImage.selected || objects.length; // is any image loaded
  if (!selectedOnboardingImage.selected) selectedOnboardingImage.selected = true;
  const filesChunks = chunk(files, 50);
  for (const files of filesChunks) {
    const photosWithoutPreview = [];
    for (const file of files) {
      file.id = file.id || uuid();
      file.folderId = folderId;
      const photo = await generateImagePreview(file, { doNotCreateThumbnail: true });
      photosWithoutPreview.push(photo);
    }
    batch(() => {
      store.dispatch(photoActions.addToStart(photosWithoutPreview));
      if (!isAnyImageLoaded && mode !== 'photo-print') { store.dispatch(addObject(photosWithoutPreview[0])); onClick && onClick(photosWithoutPreview[0]); }
      if (mode === 'photo-print') { store.dispatch(addObjects(photosWithoutPreview)); }
    });
    for (const file of files) {
      uploader.enqueue(file, {
        onProgress: ({ progress }) => {
          store.dispatch(photoActions.updateUploadProgress({ id: file.id, progress }));
        },
        onComplete: ({ url, data }) => {
          const { naturalHeight, naturalWidth, width, height, thumbnailSrc, ...photo } = explorer.normalizePhoto(data);
          store.dispatch(updateObject({ id: file.id, modifier: { image: { id: photo.id, src: url, thumbnailSrc, naturalHeight, naturalWidth, width, height, initialized: false, uploaded: true } } }));
          store.dispatch(photoActions.uploadCompleted({ id: file.id, photo: { ...photo, src: url, thumbnailSrc, rotate: naturalHeight > naturalWidth, naturalHeight, naturalWidth, width, height } }));
          idMap[file.id] = photo.id;
        },
        onError: ({ error }) => {
          store.dispatch(photoActions.uploadError({ id: file.id, error }));
          store.dispatch(deleteImage({ id: file.id, error }));
          const handleClose = () => store.dispatch(close());
          const message = 'Photo upload failed. Please try again';
          store.dispatch(open({ type: 'warning', modalProps: { message, onClose: handleClose } }));
        },
      });
    }
  }
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const filesChunksForImageUpdate = chunk(files, 5);
  for (const files of filesChunksForImageUpdate) {
    const photosWithPreview = [];
    for (const file of files) photosWithPreview.push(await generateImagePreview(file, { canvas, context }));
    let newPreview = photosWithPreview.map(({ id: _id, naturalWidth, naturalHeight, width, height, src, thumbnailSrc, rotate }) => ({ _id, initialized: false, naturalWidth, naturalHeight, width, height, src, thumbnailSrc, rotate }));
    if (mode === 'photo-print') newPreview = newPreview.map(({ _id, ...props }) => ({ ...props, imageId: _id }));
    else if (mode === 'canvas' || mode === 'poster') newPreview = newPreview.map(({ _id, ...props }) => ({ ...props, id: _id }));
    batch(() => {
      store.dispatch(photoActions.update({ addFields: photosWithPreview, updatePreview: true }));
      store.dispatch(updateScene({ addFields: newPreview, updatePreview: true }));
    });
  }
};

const Folder = ({ id, name, maxLine = '3', columns, size }) => (
  <Grid.Item key={id} className="folder" innerClassName="folder" onClick={() => explorer.go(id)} columns={columns} size={size}>
    <FontAwesomeIcon.Memo icon={faFolder} />
    <LinesEllipsis
      text={name}
      maxLine={maxLine}
      ellipsis="..."
      trimRight
      basedOn="letters"
    />
  </Grid.Item>
);
const MemoFolder = React.memo(Folder);

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };
const fontAwesomeIconButtonStyle = { borderColor: 'var(--toolbar-bg)', float: 'right', display: 'flex' };
const Photo = ({ id, src, thumbnailSrc, progress, uploaded, alwaysShowWhiteRectangle, isActive, style, onClick, photo, ...rest }) => {
  const setIsEditing = useSetIsEditing();
  const dispatch = useDispatch();
  const [{ opacity }, dragRef] = useDrag({
    item: { type: 'image', photo },
    begin: () => {
      setIsEditing(false);
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        setIsEditing(false);
        const { objects } = store.getState().scene;
        onDragEnd(item.photo, dropResult.name, objects, dispatch, dropResult.mode);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });
  const handleClick = useCallback(() => {
    console.debug(`clicked on photo id:"${id}"`);
    const { scene, scene: { config: { layouts, layoutId, mode }, objects } } = store.getState();
    if (mode === 'photo-print') {
      const [printLimitMax, , photoCount] = getPrintLimitMaxMinAndObjectsLengthSync(scene);
      const isMultiAperture = ((find(layouts, { id: layoutId }) || {}).layout || []).length > 1;
      if (printLimitMax > photoCount) {
        (!isMultiAperture) && handleClickOnImage({ id, src, uploaded, ...rest }, { onClick, dispatch });
      } else {
        const handleClose = () => store.dispatch(close());
        const message = 'Print limit reached for this product';
        store.dispatch(open({ type: 'warning', modalProps: { message, onClose: handleClose } }));
      }
    } else {
      const layouts = filter(objects, { type: 'layout' });
      if (layouts?.length === 1) {
        const newPhoto = find(store.getState().photos.photos, { id });
        dispatch(setImage({ id: layouts[0].id, image: newPhoto }));
      }
    }
  }, [dispatch, id, onClick, rest, src, uploaded]);
  return (
    <Grid.Item key={id} innerClassName={classnames({ checked: isActive })}>
      <div ref={dragRef} draggable>
        <img
          alt={id}
          src={thumbnailSrc}
          opacity={opacity}
          onClick={handleClick}
          loading="lazy"
        />
      </div>
      {!uploaded && (
        <div className="progress-bar-container">
          {(typeof progress === 'number' && progress >= 99)
            ? (<div className="progress-text">Saving...</div>)
            : (<div className="progress-bar"><ProgressBar value={typeof progress === 'number' ? progress : 0} /></div>)}
        </div>
      )}
      {uploaded && alwaysShowWhiteRectangle && <div className="progress-bar-container" />}
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);


const ClickImport = () => (
  <div className="no-photos">
    <FontAwesomeIcon.Memo icon={faHandPointUp} />
    <span>Click upload photo's to begin</span>
  </div>
);
const fileTypes = ['.png', '.jpg', '.jpeg', '.heic'];
const PhotoLibrary = ({ columns, size, compact, alwaysShowWhiteRectangle, scrollToTop, maxLine }) => {
  const objects = useSelector(({ scene }) => scene.objects);
  const mode = useSelector(({ scene: { config: { mode } } }) => mode);
  const activePhotos = mode === 'photo-print' ? objects : filter(objects, { image: { initialized: true } }).map((object) => object.image);
  const activePhotoIds = useMemo(() => activePhotos.filter(Boolean).map(({ id, imageId }) => imageId || id), [activePhotos]);
  const photos = useSelector(({ photos: { photos } }) => photos);
  const showPhotos = useSelector(({ photos: { showPhotos } }) => showPhotos);
  const folders = useSelector(({ photos: { folders } }) => folders);
  const foldersHistory = useSelector(({ photos: { foldersHistory } }) => foldersHistory);
  const currentFolder = last(foldersHistory);
  const handleFileUpload = React.useCallback((files) => { uploadAndWriteToStore(files); }, []);
  const sizeCalculated = photos.length ? size : '100%';
  const scrollDeps = useMemo(() => [currentFolder], [currentFolder]);
  const fileReaderChildren = useMemo(() => (
    <Button type="button" size="lg" textSize="md" rounded style={fontAwesomeIconButtonStyle} color="azure">
      <FontAwesomeIcon.Memo icon={faUpload} style={fontAwesomeIconStyle} />
      <span>Upload photo's</span>
    </Button>
  ), []);
  return (
    <div className={classnames('photo-library', { compact })}>
      <div className="buttons-container">
        <MemoReactFileReader
          multipleFiles={!MozillaAndroidDoesNotSupportMultiFileSelect}
          fileTypes={fileTypes}
          handleFiles={handleFileUpload}
        >{fileReaderChildren}
        </MemoReactFileReader>
      </div>
      {
        !folders?.length && !photos?.length && !foldersHistory?.length
          ? (<ClickImport />)
          : null
      }
      <Grid scrollDeps={scrollDeps} scrollToTop={scrollToTop} doNotWrapIntoMotionDiv={photos.length > 20} columns={columns} size={sizeCalculated}>
        {foldersHistory?.length ? (<BackButton onClick={(() => explorer.goBack())} />) : null}
        {folders?.map((folder) => (
          <MemoFolder
            key={folder.id}
            scrollToTop={scrollToTop}
            maxLine={maxLine}
            {...folder}
          />
        ))}
        {
          showPhotos?.map((photo) => (
            <MemoPhoto
              key={photo.id}
              alwaysShowWhiteRectangle={alwaysShowWhiteRectangle}
              isActive={activePhotoIds.includes(photo.id)}
              photo={photo}
              {...photo}
            />
          ))
        }
      </Grid>
    </div>
  );
};

export default PhotoLibrary;
