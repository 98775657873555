import React from 'react';
import Button from 'components/common/Button';
import Grid from 'components/common/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };
const fontAwesomeIconButtonStyle = { borderColor: 'var(--toolbar-bg)', float: 'right', display: 'flex' };

const BackButton = ({ onClick }) => (
  <Grid.Item className="back-button" innerClassName="back-button">
    <Button type="button" size="lg" textSize="md" rounded outline onClick={onClick} style={fontAwesomeIconButtonStyle}>
      <FontAwesomeIcon.Memo icon={faChevronLeft} style={fontAwesomeIconStyle} />
      <span>Go back</span>
    </Button>
  </Grid.Item>
);

export default BackButton;
