import React from 'react';
import './MainLayout.css';
import Toolbar from 'components/toolbar/Toolbar';
import NavBar from 'components/layout/NavBar';
import { useOnboarding } from 'hooks';
import Loading from './Loading';

const MainLayout = ({ children, loading }) => {
  const [, completed] = useOnboarding();
  return (
    <div className="main-layout">
      <NavBar loading={loading} />
      <div className="content-wrapper">
        <Loading loading={loading}>
          <Toolbar />
          <main className={`content ${completed ? 'width-without-toolbar' : ''}`}>
            {children}
          </main>
        </Loading>
      </div>
      {/* <Footer {...rest} /> */}
    </div>
  );
};
export default MainLayout;
