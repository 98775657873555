import React from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { transformObject } from 'components/editors/Editor/utils';
import useDimensions from 'hooks/useDimensions';
import useTextIsEditing from 'components/editors/Editor/hooks/useTextEditing';
import Transformer from './Transformers/Transformer';
import TextTransformer from './Transformers/TextTransformer';

const TransformerContainer = ({ editorWidth, editorHeight, setIsEditing }) => {
  const object = useSelector(({ scene: { objects, config: { isEditing } } }) => {
    const object = find(objects, { id: isEditing });
    return object ? transformObject(object, editorWidth, editorHeight) : {};
  });
  const dimensions = useDimensions();
  const [textEditing, toggleTextEditing] = useTextIsEditing();
  if (object.type === 'layout') {
    return (
      <Transformer
        object={{ ...object, x: object.x + dimensions.frame, y: object.y + dimensions.frame, height: object.height - 2 * dimensions.frame, width: object.width - 2 * dimensions.frame }}
        setIsEditing={setIsEditing}
        enabledAnchors={[
          'top-left', 'top-right', 'bottom-left', 'bottom-right',
        ]}
      />
    );
  }
  if (object.type === 'drop-zone') {
    return (
      <Transformer
        object={object}
        setIsEditing={setIsEditing}
        draggable
        editable
        rotateEnabled
        enabledAnchors={[
          'top-left', 'top-center', 'top-right', 'center-left', 'center-right', 'bottom-left', 'bottom-center', 'bottom-right',
        ]}
      />
    );
  }
  if (object.type === 'clipart') {
    return (
      <Transformer
        object={object}
        setIsEditing={setIsEditing}
        draggable
        editable
        rotateEnabled
        enabledAnchors={[
          'top-left', 'top-right', 'bottom-left', 'bottom-right',
        ]}
      />
    );
  }
  if (object.type === 'text') {
    return (
      <TextTransformer
        object={object}
        setIsEditing={setIsEditing}
        toggleTextEditing={toggleTextEditing}
        textEditing={textEditing}
        draggable
        enabledAnchors={[
          'top-left', 'top-right', 'bottom-left', 'bottom-right',
        ]}
      />
    );
  }
  return null;
};


export default TransformerContainer;
