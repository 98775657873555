import min from 'lodash/min';
import max from 'lodash/max';
import round from 'lodash/round';
import * as d3 from 'd3';

export function rotateAroundPoint(shape, angleRad, point) {
  const x = point.x
    + (shape.x - point.x) * Math.cos((angleRad - shape.rotation) * (Math.PI / 180))
    - (shape.y - point.y) * Math.sin((angleRad - shape.rotation) * (Math.PI / 180));
  const y = point.y
    + (shape.x - point.x) * Math.sin((angleRad - shape.rotation) * (Math.PI / 180))
    + (shape.y - point.y) * Math.cos((angleRad - shape.rotation) * (Math.PI / 180));
  return {
    ...shape,
    rotation: angleRad,
    x,
    y,
  };
}
export const getAnger = (p) => {
  let anger = 0;
  if (p.x < 0) {
      p.x = -p.x;
      p.y = -p.y;
      anger += 180;
  }
  return anger + (Math.acos((p.y) / (Math.sqrt(p.x ** 2 + p.y ** 2))) * 180) / Math.PI;
};

export const pointProjection = (p1, p2, p3) => {
  const L = {};
  L.x1 = p2.x;
  L.y1 = p2.y;
  L.m = p1.x - L.x1;
  L.p = p1.y - L.y1;
  const t = (L.m * p3.x + L.p * p3.y - L.m * L.x1 - L.p * L.y1) / (L.m * L.m + L.p * L.p);
  const x = L.m * t + L.x1;
  const y = L.p * t + L.y1;
  return { x, y };
};

export const distanceBetweenPoints = (p1, p2) => Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

export function getCenter(shape) {
  return {
    x:
      shape.x
      + (shape.width / 2) * Math.cos(shape.rotation * (Math.PI / 180))
      + (shape.height / 2) * Math.sin(-shape.rotation * (Math.PI / 180)),
    y:
      shape.y
      + (shape.height / 2) * Math.cos(shape.rotation * (Math.PI / 180))
      + (shape.width / 2) * Math.sin(shape.rotation * (Math.PI / 180)),
  };
}

export const getCorners = ({ x, y, width, height, rotation = 0, rotationHeight: _rotationHeight = 0, radius = 0 }) => {
  const center = getCenter({ x, y, width, height, rotation });
  const rotationHeight = (height / 2) + _rotationHeight;

  const topRightPos = { x: x + Math.cos(rotation * (Math.PI / 180)) * width, y: y + Math.sin(rotation * (Math.PI / 180)) * width };
  const bottomLeftPos = { x: x + Math.cos((rotation + 90) * (Math.PI / 180)) * height, y: y + Math.sin((rotation + 90) * (Math.PI / 180)) * height };
  const bottomRightPos = { x: bottomLeftPos.x + Math.cos(rotation * (Math.PI / 180)) * width, y: bottomLeftPos.y + Math.sin(rotation * (Math.PI / 180)) * width };
  const topCenter = { x: center.x + (height / 2) * Math.cos((rotation - 90) * (Math.PI / 180)), y: center.y + (height / 2) * Math.sin((rotation - 90) * (Math.PI / 180)) };
  const bottomCenter = { x: center.x + (height / 2) * Math.cos((rotation + 90) * (Math.PI / 180)), y: center.y + (height / 2) * Math.sin((rotation + 90) * (Math.PI / 180)) };
  const centerRight = { x: center.x + (width / 2 + radius) * Math.cos(rotation * (Math.PI / 180)), y: center.y + (width / 2 + radius) * Math.sin(rotation * (Math.PI / 180)) };
  const centerLeft = { x: center.x + (width / 2 + radius) * Math.cos((rotation + 180) * (Math.PI / 180)), y: center.y + (width / 2 + radius) * Math.sin((rotation + 180) * (Math.PI / 180)) };

  const rotationPos = { x: center.x + rotationHeight * Math.cos((rotation - 90) * (Math.PI / 180)), y: center.y + rotationHeight * Math.sin((rotation - 90) * (Math.PI / 180)) };
  const dragHandle = { x: center.x + ((height + _rotationHeight) / 2) * Math.cos((rotation + 90) * (Math.PI / 180)), y: center.y + ((height + _rotationHeight) / 2) * Math.sin((rotation + 90) * (Math.PI / 180)) };// { x: x - Math.cos((rotation + 90) * (Math.PI / 180)) * (_rotationHeight / 2), y: y - Math.sin((rotation + 90) * (Math.PI / 180)) * (_rotationHeight / 2) };
  return { topLeftPos: { x, y }, topRightPos, bottomLeftPos, bottomRightPos, center, rotationPos, topCenter, centerRight, centerLeft, bottomCenter, dragHandle };
};

// let cacheResultFunction = [];

// export const getTextArr = ({ fontSize, fontFamily, text, isBold, width }, defaultText = 'Enter text') => {
//     return ['new text'];
//     const newTextArr = [];
//     const cacheKey = `1:${text || defaultText} 2:${fontSize} 3:${fontFamily} 4:${!!isBold} 5:${width}`;
//     if (find(cacheResultFunction, { key: cacheKey })) {
//       return cloneDeep(find(cacheResultFunction, { key: cacheKey }).value);
//     }
//     const svgContainer = d3.select('#g-text');
//     const svgText = svgContainer.append('text');
//     svgText.attr('x', 50).attr('y', 50).attr('font-size', fontSize).attr('font-family', fontFamily)
//           .attr('font-weight', isBold ? 'bold' : 'normal');
//     (text || defaultText).split('\n').forEach((line) => {
//       const wordArray = line.split(' ');
//       while (wordArray.length > 0) {
//         let currentText = wordArray[0];
//         svgText.text(wordArray[0]);
//         let currentWidth = svgText.node()?.getBBox().width;
//         if (currentWidth > width) {
//           const currentWord = wordArray[0];
//           let countSymbols = 1;
//           svgText.text(currentWord.slice(0, countSymbols));
//           currentWidth = svgText.node()?.getBBox().width;
//           while (currentWidth < width && countSymbols < currentWord.length) {
//             svgText.text(currentWord.slice(0, countSymbols + 1));
//             currentWidth = svgText.node()?.getBBox().width;
//             if (currentWidth < width) {
//               countSymbols += 1;
//             }
//           }
//           newTextArr.push(currentWord.slice(0, countSymbols));
//           wordArray[0] = currentWord.slice(countSymbols);
//           if (!wordArray[0]) {
//             wordArray.shift();
//           }
//         } else {
//           wordArray.shift();
//           while (currentWidth < width && wordArray.length) {
//             svgText.text(`${currentText} ${ wordArray[0]}`);
//             currentWidth = svgText.node()?.getBBox().width;
//             if (currentWidth < width) {
//               currentText += ` ${ wordArray[0]}`;
//               wordArray.shift();
//             }
//           }
//           if (wordArray.length) currentText += ' ';
//           newTextArr.push(currentText);
//         }
//       }
//     });
//     svgText.remove();
//     if (cacheResultFunction.length > 30) {
//       cacheResultFunction = [];
//     }
//     cacheResultFunction.push({ key: cacheKey, value: cloneDeep(newTextArr) });
//     return newTextArr;
// };

export const checkIsDraw = ({ topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, allowArea, shape }) => {
  let corners = { topRightPos, bottomLeftPos, topLeftPos, bottomRightPos };
  if (shape) {
    corners = getCorners({ ...shape });
  }
  const minX = min([corners.bottomLeftPos.x, corners.topRightPos.x, corners.topLeftPos.x, corners.bottomRightPos.x]);
  const maxX = max([corners.bottomLeftPos.x, corners.topRightPos.x, corners.topLeftPos.x, corners.bottomRightPos.x]);
  const minY = min([corners.bottomLeftPos.y, corners.topRightPos.y, corners.topLeftPos.y, corners.bottomRightPos.y]);
  const maxY = max([corners.bottomLeftPos.y, corners.topRightPos.y, corners.topLeftPos.y, corners.bottomRightPos.y]);
  const outside = {
    left: allowArea.x - round(minX, 10),
    right: round(maxX, 10) - round(allowArea.width, 10),
    top: allowArea.y - round(minY, 10),
    bottom: round(maxY, 10) - round(allowArea.height, 10),
  };
  let draw = true;
  if (allowArea.x - round(minX, 10) > Number.EPSILON) {
    draw = false;
  }
  if (allowArea.y - round(minY, 10) > Number.EPSILON) {
    draw = false;
  }
  if ((round(maxX, 10) > round(allowArea.width, 10))) {
    draw = false;
  }
  if (round(maxY, 10) > round(allowArea.height, 10)) {
    draw = false;
  }
  return [draw, outside];
};

export const setAnchorPosition = (ref, pos) => {
  let anchor = '';
  if (typeof ref === 'string') {
    anchor = d3.selectAll(ref);
  } else if (ref.current) {
    anchor = d3.select(ref.current);
  }
  if (anchor) {
    anchor.attr('cx', pos?.x);
    anchor.attr('cy', pos?.y);
  }
};

export const redrawTransformer = (objectId, shape, corners) => {
  setAnchorPosition(`.bottomLeft${objectId}`, corners.bottomLeftPos);
  setAnchorPosition(`.rotate${objectId}`, corners.rotationPos);
  setAnchorPosition(`.topCenter${objectId}`, corners.topCenter);
  setAnchorPosition(`.centerLeft${objectId}`, corners.centerLeft);
  setAnchorPosition(`.centerRight${objectId}`, corners.centerRight);
  setAnchorPosition(`.bottomCenter${objectId}`, corners.bottomCenter);
  setAnchorPosition(`.topLeft${objectId}`, corners.topLeftPos);
  setAnchorPosition(`.topRight${objectId}`, corners.topRightPos);
  setAnchorPosition(`.bottomRight${objectId}`, corners.bottomRightPos);
  setAnchorPosition(`.dragHandle${objectId}`, corners.dragHandle);
  const line = d3.select(`#lineRotate${objectId}`);
  line.attr('x1', corners.topCenter.x);
  line.attr('y1', corners.topCenter.y);
  line.attr('x2', corners.rotationPos.x);
  line.attr('y2', corners.rotationPos.y);
  const rectTransformer = d3.select(`#transformer${objectId}`);
  rectTransformer.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
  rectTransformer.attr('height', shape.height);
  rectTransformer.attr('width', shape.width);
};

export const calculatePosition = (e, object, allowArea) => {
  let { x, y } = e;
  const { width, height, rotation } = object;

  const { topRightPos, bottomLeftPos, bottomRightPos } = getCorners({ x, y, width, height, rotation });

  const minX = min([bottomLeftPos.x, topRightPos.x, x, bottomRightPos.x]);
  const maxX = max([bottomLeftPos.x, topRightPos.x, x, bottomRightPos.x]);
  const minY = min([bottomLeftPos.y, topRightPos.y, y, bottomRightPos.y]);
  const maxY = max([bottomLeftPos.y, topRightPos.y, y, bottomRightPos.y]);

  if (minX < allowArea.x) {
    x += allowArea.x - minX;
  }
  if (minY < allowArea.y) {
    y += allowArea.y - minY;
  }
  if (maxX > allowArea.width) {
    x -= maxX - allowArea.width;
  }
  if (maxY > allowArea.height) {
    y -= maxY - allowArea.height;
  }
  return { x, y };
};

export const handleDragTransformer = (selectTransformer, object, onDragEnd, drawTransform, allowArea) => {
  const handleDragRect = d3.drag()
    .subject(() => (object ? { x: object.x, y: object.y } : { x: 0, y: 0 }))
    .on('drag', (e) => {
      if (object) {
        const { width, height, rotation } = object;
        document.body.style.cursor = 'grabbing';
        const { x, y } = calculatePosition(e, object, allowArea);
        drawTransform({ x, y, width, height, rotation });
      }
    })
    .on('end', () => {
      document.body.style.cursor = 'grab';
      onDragEnd();
    });
  const select = typeof selectTransformer === 'string' ? selectTransformer : selectTransformer?.current;
  handleDragRect(d3.select(select));
};

export const handleRotateTransformer = (selectRotate, shape, drawTransform, onDragEnd, subject) => {
  const handleRotate = d3.drag()
    .subject(() => (subject))
    .on('drag', (e) => {
      const center = getCenter(shape);
      const newRotation = getAnger({ x: e.x - center.x, y: center.y - e.y });
      if (document.body.style.cursor !== 'crosshair') document.body.style.cursor = 'crosshair';
      drawTransform(rotateAroundPoint(shape, newRotation, center));
    }).on('end', () => {
      document.body.style.cursor = 'default';
      onDragEnd();
    });
  const select = typeof selectRotate === 'string' ? selectRotate : selectRotate?.current;
  d3.select(select).on('mouseover', () => {
    if (document.body.style.cursor !== 'crosshair') document.body.style.cursor = 'crosshair';
  })
  .on('mouseleave', () => {
    document.body.style.cursor = 'default';
  });
  handleRotate(d3.select(select));
};
