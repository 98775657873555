/* eslint-disable func-names */
let rotates = false;
(function () {
  // black 2x1 JPEG, with the following meta information set:
  // - EXIF Orientation: 6 (Rotated 90° CCW)
  const testImageURL = 'data:image/jpeg;base64,/9j/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAYAAAA'
    + 'AAAD/2wCEAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA'
    + 'QEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQE'
    + 'BAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAf/AABEIAAEAAgMBEQACEQEDEQH/x'
    + 'ABKAAEAAAAAAAAAAAAAAAAAAAALEAEAAAAAAAAAAAAAAAAAAAAAAQEAAAAAAAAAAAAAAAA'
    + 'AAAAAEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwA/8H//2Q==';
  const img = document.createElement('img');
  img.onload = function () {
    // Check if browser supports automatic image orientation:
    rotates = img.width === 1 && img.height === 2;
  };
  img.src = testImageURL;
}());
export const doesBrowserRotatesImageByExif = () => rotates;
export default doesBrowserRotatesImageByExif;
