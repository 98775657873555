import React from 'react';
import './MultiOptionButton.css';
import classnames from 'classnames';

const MultiOptionButton = ({ options = [], value, onChange, disabled, LabelComponent }) => (

  <div className="multi-option-button-container">
    {options.map(({ value: optionValue, label }, i) => (
      <div
        className={classnames(
          'multi-option-button', 'no-selection', {
          left: i === 0, // this can be done with css selectors but I forgot that this ability exists at the time I was writing this lines
          right: i === (options.length - 1),
          selected: value === optionValue,
          'disabled-cursor': disabled,
        },
        )}
        key={optionValue}
        onClick={() => { !disabled && onChange(optionValue); }}
      >
        {LabelComponent ? (<LabelComponent value={value} optionValue={optionValue} label={label} />) : label}
      </div>
    ))}
  </div>
);

export default MultiOptionButton;
