import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import './EditFooter.scss';
import useIsMobile from 'hooks/useIsMobile';
import { faCheck, faSlidersH, faCrop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQuality } from 'utils/utils';
import useDimensions from 'hooks/useDimensions';
import { transformObject } from 'components/editors/Editor/utils';
import ImageQualityToolbar from '../components/ImageQualityToolbar';
import SliderStyle from '../components/SliderStyle';

export const EditFooter = ({ hide, toggleIsCrop, toggleIsEditing, object }) => {
  const { image } = object;

  const dimensions = useDimensions();
  const [editorWidth, editorHeight, frameWidth = 0] = useMemo(() => [dimensions.width, dimensions.height, dimensions.frame], [dimensions.frame, dimensions.height, dimensions.width]);
  const [newObjectEdit] = useMemo(() => {
    const newObjectEdit = transformObject(object, editorWidth + 2 * frameWidth, editorHeight + 2 * frameWidth);
    return [newObjectEdit];
  },
  [object, editorWidth, editorHeight, frameWidth]);

  const isMobile = useIsMobile();
  const [showAdjust, setShowAdjust] = useState(false);
  const toggleShowAdjust = () => setShowAdjust(!showAdjust);
  const variantsToolbar = useMemo(() => (
    isMobile ? {
      open: { opacity: 1, y: 0 },
      closed: { opacity: 0, y: '100%' },
      openTab: { y: 0 },
    } : {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: '-100%' },
        openTab: { y: 0 },
      }
  ), [isMobile]);

  const variantsTab = useMemo(() => ({
    open: { opacity: 1, y: 0, height: 'auto' },
    closed: { opacity: 0, display: 'none', y: isMobile ? '100%' : '-100%', height: 0 },
  }), [isMobile]);

  const quality = useMemo(() => getQuality(image, newObjectEdit),
    [image, newObjectEdit]);

  return (
    <footer style={{ width: 0 }}>
      <div className="toolbar edit">
        <motion.div className="toolbar-view-container" initial="closed" variants={variantsToolbar} animate={showAdjust ? 'openTab' : 'open'} transition={{ duration: 0.15 }}>
          {image && isMobile ? (
            <>
              <div className="edit-footer-quality">
                <ImageQualityToolbar quality={quality} />
              </div>
            </>
            ) : null}
          <div className={classnames('toolbar-view', { hide })}>
            <div className="done-button" onClick={toggleIsEditing}><FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1em', width: '1em' }} /></div>
            {image ? (
              <>
                <div onClick={toggleShowAdjust}><FontAwesomeIcon.Memo icon={faSlidersH} style={{ height: '1em', width: '1em' }} />Adjust</div>
                <div onClick={toggleIsCrop}><FontAwesomeIcon.Memo icon={faCrop} style={{ height: '1em', width: '1em' }} />Crop</div>
                { !isMobile ? <ImageQualityToolbar quality={quality} /> : null }
              </>
            ) : null}
          </div>
          {image ? (
            <motion.div className="display-table" initial="closed" variants={variantsTab} animate={showAdjust ? 'open' : 'closed'} transition={{ duration: 0.15 }}>
              <SliderStyle
                key="sliderContrast"
                objectId={object.id}
                image={image}
                label="Contrast"
                parameter="contrast"
                min={-50}
                max={50}
              />
              <SliderStyle
                key="sliderBrightness"
                objectId={object.id}
                image={image}
                label="Brightness"
                parameter="brightness"
                min={-50}
                max={50}
              />
              <SliderStyle
                key="sliderSaturation"
                objectId={object.id}
                image={image}
                label="Saturation"
                parameter="saturation"
                min={-100}
                max={50}
              />
            </motion.div>
          ) : null}
        </motion.div>
      </div>
    </footer>
  );
};

export default EditFooter;
