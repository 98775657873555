import React, { useCallback, useMemo } from 'react';
import useBackgroundColor from 'hooks/useBackgroundColor';
import { Helmet } from 'react-helmet';
import uniq from 'lodash/uniq';
import replace from 'lodash/replace';
import find from 'lodash/find';
import { useSelector } from 'react-redux';
import useDimensions from 'hooks/useDimensions';
import useIsMobile from 'hooks/useIsMobile';
import ObjectContainer from './ObjectContainer';
import { sizeInPx } from '../utils';
import Rect from './Rect';
import TextInput from './TextInput';
import 'react-toggle/style.css';
import TransformerContainer from './TransformerContainer';
import { getCorners } from './Transformers/utils';
import ImageOverlay from './ImageOverlay';

const Editor = ({ width, height, setIsEditing, isEditing, type }) => {
  const frameColor = useBackgroundColor();
  const isMobile = useIsMobile();
  const objectIds = useSelector(({ scene: { objects } }) => objects.map((object) => object.id));
  const fonts = useSelector(({ scene: { objects } }) => uniq(objects.filter((object) => object.type === 'text').map((object) => object.fontFamily)));
  const dimensions = useDimensions();
  const widthSVG = useMemo(() => dimensions.width + 2 * dimensions.originalFrame, [dimensions.originalFrame, dimensions.width]);
  const heightSVG = useMemo(() => dimensions.height + 2 * dimensions.originalFrame, [dimensions.height, dimensions.originalFrame]);
  const currentSizeInPx = useCallback((size) => sizeInPx(size, widthSVG, width), [width, widthSVG]);
  const scaleOverlay = useSelector(({ scene: { config: { scaleOverlay, scaleOverlay3d, view3d, isEditing } } }) => (view3d && !isEditing ? scaleOverlay3d : scaleOverlay));
  const ClippingMask = useSelector(({ scene: { config: { clippingMaskUrl, clippingMask3dUrl, view3d, isEditing } } }) => (view3d && !isEditing ? clippingMask3dUrl : clippingMaskUrl));
  const Overlay = useSelector(({ scene: { config: { overlayUrl, overlay3dUrl, view3d, isEditing } } }) => (view3d && !isEditing ? overlay3dUrl : overlayUrl));
  const contentOffsetY = useSelector(({ scene: { config: { contentOffsetY } } }) => contentOffsetY);
  const Underlay = useSelector(({ scene: { config: { underlayUrl } } }) => (underlayUrl));
  const scaleUnderlay = useSelector(({ scene: { config: { scaleUnderlay } } }) => scaleUnderlay);
  const scaleText = useMemo(() => width / (widthSVG), [width, widthSVG]);
  const centerText = useSelector(({ scene: { config: { isEditing, textEditing }, objects } }) => {
    const editingObject = textEditing ? find(objects, { id: isEditing }) : false;
    if (!editingObject || editingObject.type !== 'text') return false;
    return getCorners({ ...editingObject }).center;
  });
  // const transformOrigin = centerText ? `${centerText.x * scaleText}px ${centerText.y * scaleText}px` : '0px 0px';
  const transform = isMobile && centerText ? `scale(1.25) translate(${(widthSVG / 2 - centerText.x) * scaleText}px, ${(heightSVG / 2 - centerText.y) * scaleText}px)` : '';
  const transformEditorContainer = useMemo(() => `translate(0, ${contentOffsetY * heightSVG})`, [contentOffsetY, heightSVG]);
  const isRotateMask = useSelector(({ scene: { config: { orientation, maskOrientation } } }) => orientation !== maskOrientation);
  const transitionDuration = isMobile && centerText ? '300ms' : 'none';
  return (
    <>
      <Helmet>
        {fonts.map((font) => (
          <link
            key={font}
            kay="font"
            href={`https://fonts.googleapis.com/css?family=${replace(font, ' ', '+')}`}
            rel="stylesheet"
          />
      ))}
      </Helmet>
      <div style={{ width: `${width}px`, height: `${height}px`, zIndex: 1000, position: 'relative', transform, transitionDuration }}>
        <svg
          style={{ overflow: 'visible' }}
          width={`${width}px`}
          height={`${height}px`}
          viewBox={`${-(dimensions.originalFrame - dimensions.frame - dimensions.originalFrame / 2)} ${-(dimensions.originalFrame - dimensions.frame)} ${widthSVG} ${heightSVG}`}
        >
          <defs>
            <mask id="mask-clip-path">
              <g>
                {type === 'mask'
                  ? (<image xlinkHref={ClippingMask} width={isRotateMask ? heightSVG : widthSVG} height={isRotateMask ? widthSVG : heightSVG} transform={isRotateMask ? `translate(${widthSVG}, 0) rotate(90, ${0}, ${0})` : null} />)
                  : (
                    <svg
                      width="100%"
                      height="100%"
                      viewBox={`0 0 ${widthSVG} ${heightSVG}`}
                    >
                      <rect x={dimensions.frame} y={dimensions.frame} width={dimensions.width} height={dimensions.height} fill="#fff" />
                    </svg>
                    )}
              </g>
            </mask>
            <mask id="mask-top">
              <svg
                width="100%"
                x={-(dimensions.originalFrame - dimensions.frame)}
                y={-(dimensions.originalFrame - dimensions.frame)}
                height="100%"
                viewBox={`0 0 ${widthSVG} ${heightSVG}`}
              >
                <rect x={dimensions.originalFrame} y={0} width={dimensions.width} height={dimensions.originalFrame} fill="#fff" />
              </svg>
            </mask>
            <mask id="mask-right">
              <svg
                width="100%"
                height="100%"
                x={-(dimensions.originalFrame - dimensions.frame)}
                y={-(dimensions.originalFrame - dimensions.frame)}
                viewBox={`0 0 ${widthSVG} ${heightSVG}`}
              >
                <rect x={dimensions.originalFrame + dimensions.width} y={dimensions.originalFrame} width={dimensions.originalFrame} height={dimensions.height} fill="#fff" />
              </svg>
            </mask>
          </defs>
          {Underlay ? <ImageOverlay Overlay={Underlay} scaleOverlay={scaleUnderlay} isRotateMask={isRotateMask} /> : null}
          <g className="editorContainer" transform={transformEditorContainer}>
            {type === 'canvas' ? (
              <>
                <use xlinkHref="#content" transform={`translate(0, ${dimensions.frame - 1}) skewX(-45) translate(0, -${dimensions.frame})`} mask="url(#mask-top)" />
                <use xlinkHref="#content" transform={`translate(${dimensions.frame + dimensions.width + 1}, ${dimensions.frame}) skewY(-45) translate(-${dimensions.frame + dimensions.width}, -${dimensions.frame})`} mask="url(#mask-right)" />
              </>
          ) : null}
            <g mask="url(#mask-clip-path)">
              <g id="content">
                <rect id="r4" x={-(dimensions.originalFrame - dimensions.frame)} y={-(dimensions.originalFrame - dimensions.frame)} width={widthSVG} height={heightSVG} fill={frameColor} />
                {objectIds.map((id) => (<ObjectContainer key={id} id={id} scaleText={scaleText} editorWidth={dimensions.width + 2 * dimensions.frame} editorHeight={dimensions.height + 2 * dimensions.frame} isEditing={isEditing} setIsEditing={setIsEditing} />))}
              </g>
            </g>
            {type === 'poster' ? (<Rect x={dimensions.halfBleed} y={dimensions.halfBleed} width={dimensions.width - 2 * dimensions.halfBleed} height={dimensions.height - 2 * dimensions.halfBleed} strokeWidth={currentSizeInPx(1)} strokeDasharray={`${currentSizeInPx(10)}, ${currentSizeInPx(10)}`} fill="#ffffff00" stroke="#ffffff" />) : null}
          </g>
          <ImageOverlay Overlay={Overlay} scaleOverlay={scaleOverlay} isRotateMask={isRotateMask} />
          <g className="transformerContainer" transform={transformEditorContainer}>
            <TransformerContainer editorWidth={dimensions.width + 2 * dimensions.frame} editorHeight={dimensions.height + 2 * dimensions.frame} isEditing={isEditing} setIsEditing={setIsEditing} />
          </g>
          <g className="textInput">
            <TextInput />
          </g>
        </svg>
      </div>
    </>
  );
};


export default Editor;
