import React from 'react';
import { useSelector } from 'react-redux';
import Switch from '../../../common/Switch';
import useView3d from '../hooks/useView3d';

const SwitchView3d = () => {
  const [view3d, toggleView3d] = useView3d();
  const showSwitchView3d = useSelector(({ scene: { config: { mode, overlay3dUrl } } }) => mode === 'mask' && overlay3dUrl);
  if (!showSwitchView3d) {
    if (view3d) {
      toggleView3d(false);
    }
    return null;
  }
  return (
    <Switch checked={view3d} onChange={toggleView3d} label="3D " switchStyle={{ position: 'absolute', right: 'var(--navbar-padding-horizontal)', top: '10px', zIndex: '1000' }} />
  );
};

export default SwitchView3d;
