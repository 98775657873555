import React, { useRef, useCallback } from 'react';
import uuid from 'uuid';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import { transformObject } from 'components/editors/Editor/utils';
import useAllowArea from 'hooks/useAllowArea';
import htmr from 'htmr';
import { checkIsDraw } from 'components/editors/NewEditor/components/Transformers/utils';
import { mmToInches } from './number';

const debug = false;

const placeholderText = 'Enter text';

export const isEdge = () => (/Edge/.test(navigator.userAgent));

export const mmToPx = (millimeters, dpi = 300) => { // dpi===ppi
  const centimeters = millimeters / 10;
  const inches = centimeters / 2.54;
  const pixels = inches * dpi;
  return pixels;
};

export const getQualityFromQuality = (props) => {
  const quality = props || 100;
  if (quality > 80) return ['Great', 4, true]; // name, index, isGoodDPI
  if (quality > 50 && quality <= 80) return ['Good', 3, true];
  if (quality > 35 && quality <= 50) return ['Average', 2, false];
  if (quality > 25 && quality <= 35) return ['Poor', 1, false];
  return ['Very poor', 0, false]; // quality <= 25
};

export const calculateQualityOfImage = (imageLayer) => {
  let q, imgW, imgH;

  // NOTE all of these measurements are the size they would be after printing it (not the on screen scaled size)
  // this would be from the exif if calculating whilst photo is uploading
  if (imageLayer.asset.width) {
    imgW = imageLayer.asset.width;
    imgH = imageLayer.asset.height;
    // these are values our API returns
  } else if (imageLayer.asset.photo_data_width) {
    imgW = imageLayer.asset.photo_data_width;
    imgH = imageLayer.asset.photo_data_height;
  }
  // work out the ratio of the image
  const imgR = imgW / imgH;
  // aperture is the photo area (e.g. the front of canvas or the whole of canvas inc edges if wrap)
  // OR it would be the size of one of the areas in multi layout
  const apertureR = imageLayer.width / imageLayer.height;
  // zoom relates to how zoomed in the photo is. 1 being not zoomed at all.
  if (imgR < apertureR) {
    // Compare width
    q = (imgW / imageLayer.image.zoom) / imageLayer.width;
  } else {
    q = (imgH / imageLayer.image.zoom) / imageLayer.height;
  }
  imageLayer.quality = q * 100;
};

export const getQuality = (image, object) => {
  const imageLayer = {
    asset: {
      photo_data_width: image.naturalWidth,
      photo_data_height: image.naturalHeight,
    },
    image: {
      zoom: image.width / image.minWidth,
    },
    width: (mmToInches(object.width) * 150),
    height: (mmToInches(object.height) * 150),
  };
  calculateQualityOfImage(imageLayer);
  const { quality } = imageLayer;
  debug && console.log('quality:', imageLayer.quality.toFixed(1), 'imageLayer:', imageLayer);

  return quality;
};

export const countLowQualityImage = ({ photos: { photos }, scene: { objects, config: { dimensions } } }) => objects.reduce((accumulator, object) => {
    if (!object?.image) return accumulator;
    const newObject = transformObject(object, dimensions.width, dimensions.height);
    const image = find(photos, { id: newObject.image.id });
    if (getQuality({ ...image, ...newObject.image }, newObject) <= 50) return accumulator + 1;
    return accumulator;
  }, 0);

export const addBorderToLayout = (layout, borderHorizontal, borderVertical, borderType) => layout.map((aperture) => {
  let { x, y, width, height } = aperture;
  if (borderType === 'border' || borderType === 'retro') {
    width -= borderHorizontal * 2;
    x += borderHorizontal;
    height -= borderVertical * 2;
    y += borderVertical;
  }
  return {
    x: x / 100,
    y: y / 100,
    width: width / 100,
    height: height / 100,
  };
});
export const getDimensions = ({ orientation, dimensions, isFullScreen }) => {
  const _dimensions = { ...dimensions };
  if (orientation === 'portrait') {
    [_dimensions.width, _dimensions.height] = [_dimensions.height, _dimensions.width];
  }

  // calculate bleed
  if (isFullScreen) {
    _dimensions.width += _dimensions.halfBleed * 2;
    _dimensions.height += _dimensions.halfBleed * 2;
  }
  return _dimensions;
};

// used to create a static reference to a changeable function
export const useFunctionToRefCB = (func) => {
  const ref = useRef(func);
  ref.current = func;
  return useCallback((...props) => ref.current(...props), []);
};

export const useSelectorMemo = (selector, isEqualPropsToTriggerRerender = isEqual, isEqualPropsToReturnNewValue = isEqual) => {
  const memoizedValue = useRef([]);
  const newValue = useSelector(selector, isEqualPropsToTriggerRerender);
  if (!isEqualPropsToReturnNewValue(newValue, memoizedValue.current)) { memoizedValue.current = newValue; }
  return memoizedValue.current;
};

export const alignTextSvg = (textAlign, width) => {
  let x = '0px', textAnchor = 'start';
  if (textAlign === 'center') {
    x = `${width / 2}px`;
    textAnchor = 'middle';
  } else if (textAlign === 'right') {
    x = `${width}`;
    textAnchor = 'end';
  }
  return { x, textAnchor };
};

export const isAllImageLoaded = ({ config: { mode }, objects }) => {
  const emptyObjects = objects.filter((object) => !object.image && object.type !== 'text');
  return mode === 'photo-print' || !emptyObjects.length;
};

export const getTextStyles = (object, scaleText = 1) => ({
  textAlign: object.textAlign || 'left',
  color: object.fill || '#000000',
  fontFamily: object.fontFamily,
  fontWeight: object.isBold ? 'bold' : 'normal',
  fontStyle: object.isItalic ? 'italic' : 'normal',
  caretColor: object.fill || '#000000',
  fontSize: `${object.fontSize * scaleText}px`,
  lineHeight: `${object.fontSize * scaleText}px`,
});

export const TextInSvg = ({ object, onClick, visibility = 'hidden', idDivContainer, defaultText, ...props }) => (
  <foreignObject
    className="node"
    transform={`translate(${object.x},${object.y}) rotate(${object.rotation})`}
    width={object.width}
    height="100%"
    overflow="visible"
    style={{ pointerEvents: 'none' }}
    {...props}
  >
    <div
      xmlns="http://www.w3.org/1999/xhtml"
      style={{
        background: '#0000',
        overflow: 'visible',
        ...getTextStyles(object, 1),
        ...(visibility === 'hidden' ? { color: 'transparent' } : {}),
      }}
    >
      <div
        id={idDivContainer}
        style={{ width: '100%', height: 'min-content', minHeight: `${object.fontSize}px`, pointerEvents: 'all' }}
        onClick={onClick}
      >
        <div className="textWrapper" style={{ wordBreak: 'break-word' }}>
          {(htmr(defaultText ? (object.text || placeholderText) : object.text))}
        </div>
      </div>
    </div>
  </foreignObject>
);

let cacheResultFunction = [];
/**
 * Returns the number of lines of text.
 *
 * @param {object} textObject The text object.
 * @return {number} number of lines of text.
 */
export const getHeightText = (textObject) => {
  const cacheKey = `1:${JSON.stringify(textObject.text)} 2:${textObject.fontSize} 3:${textObject.fontFamily} 4:${!!textObject.isBold} 5:${textObject.width}`;
  if (find(cacheResultFunction, { key: cacheKey })) {
    return cloneDeep(find(cacheResultFunction, { key: cacheKey }).value);
  }
  const tempDocument = document.getElementById(`calculation-element-${textObject.id}`);
  const id = uuid();
  const tempElement = renderToString(<TextInSvg object={textObject} visibility="hidden" idDivContainer={id} />);
  tempDocument.innerHTML = tempElement;
  const container = document.getElementById(id);
  const height = Math.round(container.offsetHeight);
  if (cacheResultFunction.length > 30) {
    cacheResultFunction = [];
  }
  cacheResultFunction.push({ key: cacheKey, value: height || 1 });
  return height || 1;
};

export const useNormalizeObject = () => {
  const allowArea = useAllowArea('text');
  const normalizeObject = useCallback((object) => {
    const newParamShape = {};
    const newHeight = getHeightText({ ...object, ...newParamShape });
    if (newHeight !== object.height) {
      newParamShape.height = newHeight;
    }
    const getParam = (param) => (get(newParamShape, param) || get(object, param));
    let [draw, outside] = checkIsDraw({ shape: { ...object, ...newParamShape }, allowArea });
    const f = () => {
      if (!draw) {
        if (outside.right > 0) {
          newParamShape.x = getParam('x') - outside.right;
          outside.left += outside.right;
          outside.right = 0;
        }
        if (outside.left > 0) {
          newParamShape.x = getParam('x') + outside.left;
          outside.right += outside.left;
          outside.left = 0;
        }
        if (outside.bottom > 0) {
          newParamShape.y = getParam('y') - outside.bottom;
          outside.top += outside.bottom;
          outside.bottom = 0;
        }
        if (outside.top > 0) {
          newParamShape.y = getParam('y') + outside.top;
          outside.bottom += outside.top;
          outside.top = 0;
        }
      }
    };
    if (object.type === 'text') {
      while (((outside.right + outside.left > 0) || (outside.bottom + outside.top > 0)) && (getParam('fontSize') > 1) && (!draw)) {
        newParamShape.fontSize = getParam('fontSize') - 1;
        newParamShape.height = getHeightText({ ...object, ...newParamShape });
        f();
        [draw, outside] = checkIsDraw({ shape: { ...object, ...newParamShape }, allowArea });
      }
    }
    f();
    [draw, outside] = checkIsDraw({ shape: { ...object, ...newParamShape }, allowArea });
    if (!draw) { return false; }
    return newParamShape;
  }, [allowArea]);
  return normalizeObject;
};
