import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { useToolbar } from 'hooks';
import Image from './Image';

const Background = ({ object }) => {
  const { openToolbar } = useToolbar();
  const onClick = useCallback(() => openToolbar('customize'), [openToolbar]);
  const src = useSelector(({ photos: { backgrounds } }) => object?.image && find(backgrounds, { id: object.image.id })?.src);
  return (
    <>
      <Image object={object} src={src} onClick={onClick} />
    </>
  );
};


export default Background;
