import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateObjectByIndex } from 'actions/scene';

const useUpdateObjectByIndex = () => {
  const dispatch = useDispatch();
  return useCallback(({ index, ...modifier }) => dispatch(updateObjectByIndex({ index, modifier })), [dispatch]);
};

export default useUpdateObjectByIndex;
