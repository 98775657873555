import React from 'react';
import { useToolbarItems } from 'components/toolbar/Toolbar/ToolbarContainer';
import Footer from './Footer';

export const FooterContainer = (props) => {
  const items = useToolbarItems();
  return <Footer items={items} {...props} />;
};

export default FooterContainer;
