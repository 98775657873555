import React from 'react';
import './EditorContainer.scss';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import useEditorSizePx from '../Editor/hooks/useEditorSizePx';
import Arrow from './components/Arrow';
import Editor from './components/Editor';
import { useIsCrop } from '../Editor';
import CropPhotoContainer from '../Editor/components/NewCropPhotoComponent';

const EditorContainer = ({ isEditing, setIsEditing, type }) => {
  const sizes = useEditorSizePx();
  const [isCrop] = useIsCrop();
  const frame = type === 'canvas' ? sizes[2] : 0;
  const objects = useSelector(({ scene: { objects } }) => objects);
  const edgeWrap = useSelector(({ scene }) => scene.config.edgeWrap);
  const [xLabel, yLabel, axisColor] = useSelector(({ scene: { config: { orientation, axisColor, xLabel, yLabel } } }) => (
    orientation === 'landscape' ? [xLabel, yLabel, axisColor] : [yLabel, xLabel, axisColor]
  ));
  if (isCrop) {
    const object = find(objects, { id: isEditing });
    if (object) {
      return (
        <CropPhotoContainer
          key={object.id}
          object={object}
          image={{ ...object.image }}
          type={type}
          edgeWrap={edgeWrap}
        />
      );
    }
    return null;
  }
  return (
    <div className="containerNewEditor" onClick={() => isEditing && setIsEditing(false)}>
      <div className="newEditor" style={{ margin: 'auto' }}>
        <div style={{ display: 'flex' }}>
          {type !== 'mask' ? (<div style={{ display: 'flex', alignItems: 'center' }}><Arrow vertical width={sizes[1] / 3} text={yLabel} color={axisColor} /></div>) : null}
          <Editor width={sizes[0] + 2 * frame} height={sizes[1] + 2 * frame} isEditing={isEditing} setIsEditing={setIsEditing} type={type} />
        </div>
        {type !== 'mask' ? (<div style={{ width: sizes[0], textAlign: 'center', marginLeft: 'auto' }}><Arrow width={sizes[0] / 3} text={xLabel} color={axisColor} /></div>) : null}
      </div>
    </div>
  );
};

export default EditorContainer;
