import axios from 'axios';
import get from 'lodash/get';
import store from 'store';
import config from 'config';
import * as photoActions from 'actions/photos';
import last from 'lodash/last';

const mockPhoto = config.isDevelopment ? [
  {
    photo_id: 4052087,
    photo_data_width: 640,
    photo_data_height: 360,
    images: {
      small: 'https://placekitten.com/640/360',
      large: 'https://placekitten.com/640/360',
      original: 'https://placekitten.com/640/360',
    },
  },
  {
    photo_id: 4051937,
    photo_data_width: 640,
    photo_data_height: 360,
    images: {
      small: 'https://placekitten.com/640/360',
      large: 'https://placekitten.com/640/360',
      original: 'https://placekitten.com/640/360',
    },
  },
  {
    photo_id: 4052082,
    photo_data_width: 640,
    photo_data_height: 360,
    images: {
      small: 'https://loremflickr.com/640/360',
      large: 'https://loremflickr.com/640/360',
      original: 'https://loremflickr.com/640/360',
    },
  },
  {
    photo_id: 4052083,
    photo_data_width: 640,
    photo_data_height: 360,
    images: {
      small: 'https://baconmockup.com/640/360',
      large: 'https://baconmockup.com/640/360',
      original: 'https://baconmockup.com/640/360',
    },
  },
  {
    photo_id: 4052085,
    photo_data_width: 640,
    photo_data_height: 360,
    images: {
      small: 'https://placebear.com/640/360',
      large: 'https://placebear.com/640/360',
      original: 'https://placebear.com/640/360',
    },
  },
] : [];

export class GalleryExplorer {
  constructor() {
    this.getData();
    this.getBackground();
    this.getClipart();
  }

  normalizePhoto = ({
    photo_id: id,
    images,
    photo_data_width: naturalWidth,
    photo_data_height: naturalHeight,
  }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth,
    naturalHeight,
    width: 100,
    height: (naturalHeight / naturalWidth) * 100,
  })

  normalizeBackground = ({
    id,
    images,
    width: naturalWidth,
    height: naturalHeight,
  }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth,
    naturalHeight,
    width: 100,
    height: (naturalHeight / naturalWidth) * 100,
  })

  normalizeClipart = ({
    filename: id,
    images,
    width,
    height,
  }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth: width,
    naturalHeight: height,
    width: 100,
    height: (height / width) * 100,
  })

  normalizeFolder = ({
    album_id: id,
    album_parentid: parent,
    album_name: name,
  }) => ({ id, parent, name })

  getCurrentFolder = (getStack) => { const stack = store.getState().photos.foldersHistory; return getStack ? (stack || []) : (last(stack) || 0); }; // currentFolder or stack of folders history

  getData = (page = this.getCurrentFolder()) => axios.get(`${process.env.REACT_APP_API_URL}/files/listphotos/${page}/json`, { withCredentials: true }).then((response) => {
    const folders = get(response, 'data.data.folders', []).map(this.normalizeFolder);
    const photos = get(response, 'data.data.photos', mockPhoto).map(this.normalizePhoto);
    photos.reverse();
    store.dispatch(photoActions.set(photos));
    store.dispatch(photoActions.setFolders(folders));
    return { folders, photos };
  });

  getBackground = () => axios.get(`${process.env.REACT_APP_API_URL}/api/v1/assets?theme_id=1312&type=background`, { withCredentials: true }).then((response) => {
    const photos = get(response, 'data', mockPhoto).map(this.normalizeBackground);
    photos.reverse();
    store.dispatch(photoActions.setBackgrounds(photos));
    return { photos };
  });

  getClipart = () => axios.get(`${process.env.REACT_APP_API_URL}/api/v1/assets?theme_id=1312&type=clipart`, { withCredentials: true }).then((response) => {
    const photos = get(response, 'data', mockPhoto).map(this.normalizeClipart);
    photos.reverse();
    store.dispatch(photoActions.setCliparts(photos));
    return { photos };
  });

  go = async (page) => {
    await this.getData(page);
    store.dispatch(photoActions.setFoldersHistory([...this.getCurrentFolder(true), page]));
  }

  goBack = async () => {
    const prevFolder = [...this.getCurrentFolder(true)];
    prevFolder.pop();
    await this.getData(last(prevFolder) || 0);
    store.dispatch(photoActions.setFoldersHistory(prevFolder));
  }
}

export default GalleryExplorer;
