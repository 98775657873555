import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { getCanvasDimensions, imagePctToPx } from 'components/editors/Editor/utils';
import SVGFilter from 'utils/svg-export/components/SVGFilter';
import { getQualityFromQuality } from 'utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import min from 'lodash/min';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { calculateIsFullScreen } from 'actions/utils';

const calculatePosition = (e, image, allowArea) => {
  let { x, y } = e;
  const { width, height } = image;

  const maxX = x + width;
  const maxY = y + height;

  if (x > allowArea.x) {
    x -= x - allowArea.x;
  }
  if (y > allowArea.y) {
    y -= y - allowArea.y;
  }
  if (maxX < allowArea.width) {
    x += allowArea.width - maxX;
  }
  if (maxY < allowArea.height) {
    y += allowArea.height - maxY;
  }
  return { x, y };
};

const Image = ({ object, src, onClick, offClipPath, draggable, onDragEnd, onDragStart, quality }) => {
  let { image } = object;
  if (image) {
    image = imagePctToPx(image, object.width);
  }
  useEffect(() => {
    d3.select(`.image${object.id}`).on('click', (e) => {
      document.body.style.cursor = 'default';
      onClick();
      e.stopPropagation();
    });
    if (draggable) {
      const handleDragRect = d3.drag()
        .subject(function subject() {
          const me = d3.select(this);
          return { x: me.attr('x'), y: me.attr('y') };
        })
        .on('start', () => {
          document.body.style.cursor = 'grabbing';
          onDragStart && onDragStart();
        })
        .on('drag', function drag(e) {
          const { x, y } = calculatePosition(e, image, { x: 0, y: 0, width: object.width, height: object.height });
          const me = d3.select(this);
          me.attr('x', x);
          me.attr('y', y);
        })
        .on('end', (e) => {
          document.body.style.cursor = 'grab';
          const { x, y } = calculatePosition(e, image, { x: 0, y: 0, width: object.width, height: object.height });
          onDragEnd && onDragEnd({ x, y });
        });
      handleDragRect(d3.select(`.image${object.id}`));
      d3.select(`.image${object.id}`)
        .on('mouseover', () => {
          if (document.body.style.cursor === 'default') document.body.style.cursor = 'grab';
        })
        .on('mouseleave', () => {
          document.body.style.cursor = 'default';
        });
    }
  }, [draggable, image, object.height, object.id, object.width, onClick, onDragEnd, onDragStart]);
  const maxQWidth = useSelector(({ scene: { config: { dimensions: { width }, dimensions, orientation, mode, scaleUnderlay }, state: { sceneWidthPx, sceneHeightPx } } }) => {
    const sizes = getCanvasDimensions(dimensions, orientation, true, mode, [sceneWidthPx, sceneHeightPx], scaleUnderlay);
    return 50 * (width / sizes[0]);
  });
  const dimensionsFrame = useSelector(({ scene: { config, config: { mode, dimensions: { frame } } } }) => {
    const isFullScreen = calculateIsFullScreen(config);
    if (isFullScreen && mode === 'canvas') {
      return frame;
    }
    return 0;
  });
  const qContainerWidth = min([maxQWidth, min([object.width, object.height]) / 5]);
  const iconRatio = 1.6;
  const qWidth = qContainerWidth / iconRatio;
  const qMargin = qContainerWidth / 6;
  const [, , isGoodQuality] = getQualityFromQuality(quality);
  return (
    <>
      {
        image ? (
          <>
            {!offClipPath ? (
              <defs>
                <clipPath id={`canvas-clip${object.id}`}>
                  <rect
                    x={0}
                    y={0}
                    width={object.width}
                    height={object.height}
                  />
                </clipPath>
              </defs>
            ) : null}
            <SVGFilter id={`filter_${object.id}`} contrast={image?.contrast} brightness={image?.brightness} saturation={image?.saturation} />
            <image
              className={`image${object.id}`}
              /* style={{ willChange: 'transform' }} */
              clipPath={offClipPath ? null : `url(#canvas-clip${object.id})`}
              transform={`translate(${object.x} ${object.y}) rotate(${object.rotation || 0})`}
              preserveAspectRatio="xMinYMin slice"
              x={image?.x}
              y={image?.y}
              width={image?.width}
              height={image?.height}
              xlinkHref={src}
              filter={`url(#filter_${object.id})`}
            />
            { !isGoodQuality ? (
              <g className={`image-quality-indicator${object.id}`} x="0" y="0" transform={`translate(${object.x} ${object.y}) rotate(${object.rotation || 0})`}>
                <rect x={qMargin + dimensionsFrame} y={object.height - qContainerWidth - qMargin - dimensionsFrame} width={qContainerWidth} height={qContainerWidth} fill="#fff7" rx={qContainerWidth / 4} />
                <FontAwesomeIcon.Memo icon={faExclamationTriangle} x={(qContainerWidth - qWidth) / 2 + qMargin + dimensionsFrame} y={object.height - qWidth - (qContainerWidth - qWidth) / 2 - qMargin - dimensionsFrame} width={qWidth} height={qWidth} color="red" />
              </g>
              ) : null}
          </>
        ) : null
      }
    </>
  );
};


export default React.memo(Image, isEqual);
