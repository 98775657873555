import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { addText, addDropZone } from 'actions/scene';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import find from 'lodash/find';
import './Customize.scss';
import Button from 'components/common/Button';
import { faTextSize, faImage, faCut, faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import Clipart from '../Clipart';
import Backgrounds from '../Backgrounds';

const fontAwesomeIconStyle = { height: '1.2em', width: '1.2em' };

const Customize = () => {
  const currentBackgroundImageId = useSelector(({ scene: { objects } }) => {
    const background = find(objects, { type: 'background' });
    if (background) {
      return background.image.id;
    }
    return false;
  });

  const showBackgroundPicker = useSelector(({ scene: { config: { showBackgroundPicker, admin } } }) => admin || showBackgroundPicker);

  const onClickAddText = useCallback(() => {
    store.dispatch(addText());
  }, []);
  const onClickAddDropZone = () => {
    store.dispatch(addDropZone());
  };

  const [selectClipart, setSelectClipart] = useState(false);
  const [selectBackground, setSelectBackground] = useState(!!currentBackgroundImageId);
  if (selectClipart) {
    return (<Clipart onClickBackButton={() => { setSelectClipart(false); }} />);
  }
  if (selectBackground) {
    return (<Backgrounds onClickBackButton={() => { setSelectBackground(false); }} />);
  }
  return (
    <div className="customize-container">
      <div className="customize-button-container">
        <Button type="button" size="lg" textSize="md" rounded color="secondary" onClick={() => { setSelectClipart(true); }}>
          <FontAwesomeIcon.Memo icon={faCut} style={fontAwesomeIconStyle} /><div>Add Clipart</div>
        </Button>
        <Button type="button" size="lg" textSize="md" rounded color="secondary" onClick={onClickAddText}><FontAwesomeIcon.Memo icon={faTextSize} style={fontAwesomeIconStyle} /><div>Add Text</div></Button>
        <Button type="button" size="lg" textSize="md" rounded color="secondary" onClick={onClickAddDropZone}><FontAwesomeIcon.Memo icon={faImage} style={fontAwesomeIconStyle} /><div>Add Image</div></Button>
        {showBackgroundPicker ? (
          <Button type="button" size="lg" textSize="md" rounded color="secondary" onClick={() => setSelectBackground(true)}><FontAwesomeIcon.Memo icon={faFillDrip} style={fontAwesomeIconStyle} /><div>Background</div></Button>
      ) : null}
      </div>
    </div>
  );
};

export default Customize;
