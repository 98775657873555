import React, { useMemo, useState, useEffect, useLayoutEffect } from 'react';
import useResize from 'use-resize';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from 'actions/scene';
import { useIsEditing } from 'components/editors/Editor';
import NewEditor from 'components/editors/NewEditor';
import useIsMobile from 'hooks/useIsMobile';
import debounce from 'lodash/debounce';
import { useToolbar } from 'hooks';
import get from 'lodash/get';
import PhotoPrint from 'components/editors/PhotoPrint';
import Footer from 'components/common/Footer';
import SwitchView3d from 'components/editors/NewEditor/components/SwitchView3d';
import { getSceneSize } from './utils';

// write scene width to the store
const useSceneWidthEffect = (sceneWidthPx, sceneHeightPx, sceneContainerWidthPx, sceneContainerHeightPx) => {
  const dispatch = useDispatch();
  const debouncedUpdateState = React.useMemo(() => debounce((params) => {
    dispatch(updateState(params));
  }, 100), [dispatch]);
  useEffect(() => {
    debouncedUpdateState({
      sceneWidthPx, sceneHeightPx, sceneContainerWidthPx, sceneContainerHeightPx,
    });
  }, [sceneWidthPx, sceneHeightPx, sceneContainerWidthPx, sceneContainerHeightPx, dispatch, debouncedUpdateState]);
};

const useViewSize = () => {
  const ref = React.useRef();
  const { width: viewportWidth, height: viewportHeight } = useResize();
  const [viewWidth, setViewWidth] = useState(viewportWidth);
  const [viewHeight, setViewHeight] = useState(viewportHeight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) return;
    const w = ref.current.clientWidth;
    const h = ref.current.clientHeight;
    if (w && w !== viewportWidth) {
      setViewWidth(w);
    }
    if (h && h !== viewportHeight) {
      setViewHeight(h);
    }
  });
  return [ref, viewWidth, viewHeight];
};

const Scene = () => {
  const [containerRef, viewWidth, viewHeight] = useViewSize();
  const isMobile = useIsMobile();
  const [isEditing, toggleIsEditing, setIsEditing] = useIsEditing(false);
  const [width, height] = useMemo(() => {
    const toolbarWidth = isMobile ? 0 : get(document.querySelector('.toolbar-view'), 'offsetWidth');
    return getSceneSize({ width: viewWidth - toolbarWidth, height: viewHeight, isMobile });
  }, [viewWidth, viewHeight, isMobile]);
  useSceneWidthEffect(width, height, viewWidth, viewHeight);
  const centerX = useMemo(() => viewWidth / 2, [viewWidth]);
  const centerY = useMemo(() => viewHeight / 2, [viewHeight]);
  const { openedPageToolbar: selectedItem } = useToolbar();
  const transform = useMemo(() => {
    if (isMobile) return '';
    return (
      selectedItem === null ? 'translateX(0px)'
      : `translateX(${132}px)`
    );
  }, [isMobile, selectedItem]);
  const mode = useSelector(({ scene: { config: { mode } } }) => mode);
  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', userSelect: 'none' }}>
      <SwitchView3d />
      <div style={{ width: '100%', height: '100%', transitionDuration: '0.3s', transform }}>
        {mode === 'photo-print' ? (
          <PhotoPrint
            viewWidth={viewWidth}
            viewHeight={viewHeight}
            centerX={centerX}
            centerY={centerY}
          />
        ) : null}

        {mode === 'poster' || mode === 'mask' || mode === 'canvas' ? (
          <NewEditor
            viewWidth={viewWidth}
            viewHeight={viewHeight}
            type={mode}
            centerX={centerX}
            centerY={centerY}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            toggleIsEditing={toggleIsEditing}
          />
        ) : null}
        { isEditing ? (
          <Footer key={isEditing} setIsEditing={setIsEditing} />
          ) : null}
      </div>
    </div>
  );
};

export default Scene;
