import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import { useToolbar } from 'hooks';
import { getQuality } from 'utils/utils';
import ImageWithDropZone from './ImageWithDropZone';

const Layout = ({ object, onClick }) => {
  const image = useSelector(({ photos: { photos } }) => object?.image && find(photos, { id: object.image.id }));
  const height = object?.height;
  const width = object?.width;
  const quality = useMemo(() => {
      if (!image) return null;
      return getQuality({ ...image, ...object.image }, { width, height });
    },
    [image, object, width, height]);
  const { openToolbar } = useToolbar();
  return (
    <>
      <ImageWithDropZone object={object} src={image?.src} onClick={object.image ? onClick : () => { openToolbar('photos'); }} quality={quality} />
    </>
  );
};


export default Layout;
