import maxBy from 'lodash/maxBy';
import uuid from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { EDGE_WRAP } from 'constants/editor';
import { pctToPx, pxToPct } from 'components/editors/Editor/utils';

class Migrations {
  #migrations = [
    {
      version: 1,
      up: (scene) => {
        const newScene = cloneDeep(scene);
        newScene.photos = newScene.photos || [];
        const { layouts, layoutId, mode, edgeWrap, dimensions } = newScene.config;
        const currentLayout = find(layouts, { id: layoutId });
        newScene.objects = currentLayout.layout.map((layout, index) => {
          const shape = { x: layout.x / 100, y: layout.y / 100, width: layout.width / 100, height: layout.height / 100, type: 'layout', id: uuid() };
          if (scene.objects[index]) {
            const image = scene.objects[index];
            const { id, uploaded, thumbnailSrc, src, originalSrc, naturalWidth, naturalHeight, width, height } = image;
            if (mode === 'canvas' && edgeWrap === EDGE_WRAP) {
              const convert = (x) => pxToPct(dimensions.width + 2 * dimensions.frame, pctToPx(dimensions.width, x));
              image.width = convert(image.width);
              image.height = convert(image.height);
              image.x = convert(image.x);
              image.y = convert(image.y);
            }
            shape.image = image;
            newScene.photos.push({ id, uploaded, thumbnailSrc, src, originalSrc, naturalWidth, naturalHeight, width, height });
          }
          return shape;
        });
        return newScene;
      },
    },
  ];

  get lastVersion() {
    return maxBy(this.#migrations, 'version').version;
  }

  migrate(scene) {
    const version = scene?.config?.version ?? 0;
    const migrations = this.#migrations.filter((migration) => migration.version > version);
    scene.config.version = this.lastVersion;
    const newScene = migrations.reduce((scene, migration) => migration.up(scene), scene);
    return newScene;
  }
}

export default new Migrations();
