/* eslint-disable react/no-array-index-key */
import React from 'react';
import PhotoLibrary from 'components/common/PhotoLibrary';
import useIsMobile from 'hooks/useIsMobile';

const ToolbarPhotoLibrary = ({ isActive, ...props }) => {
  const isMobile = useIsMobile();
  return (
    <PhotoLibrary {...props} compact size="50%" maxLine={isMobile ? '2' : '3'} />
  );
};

export default ToolbarPhotoLibrary;
