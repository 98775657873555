/* eslint-disable no-restricted-globals */
import { createAction, handleActions } from 'redux-actions';
import { EDGE_WRAP } from 'constants/editor';
import { inchesToMm } from 'utils/number';
import sumBy from 'lodash/sumBy';
import uuid from 'uuid';
import merge from 'lodash/merge';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import min from 'lodash/min';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import flattenDeep from 'lodash/flattenDeep';
import { getCorners, checkIsDraw } from 'components/editors/NewEditor/components/Transformers/utils';
import { isEmptyShape, isText } from 'utils/sceneObjects';
import DomHelper from 'utils/DomHelper';
import { calculateCurrentLayout, initImage, shapeToCenter } from './utils';


const defaultImage = {
  x: 0,
  y: 0,
  width: 1,
  height: 1,
  brightness: 0,
  contrast: 0,
  saturation: 0,
};

/**
 * @param dimensions - object. default unit is mm
 */
const config = {
  axisColor: '#c7c6c7', // TODO: fix
  // xLabel: '16"',
  // yLabel: '12"',
  clippingMaskUrl: 'mask.svg',
  contentOffsetY: 0,
  dimensions: {
    halfBleed: 3, // mm
    frame: 18, // mm
    border: 3, // mm
    retroBorder: 5, // mm
    retroBottom: 15, // mm
    // width: 0,
    // height: 0,
    width: inchesToMm(16),
    height: inchesToMm(12),
  },
  edgeWrap: EDGE_WRAP,
};

const state = {
  sceneWidthPx: 320,
  sceneHeightPx: 320,
};

// const yoda = {
//   id: 'yoda',
//   src: 'https://konvajs.org/assets/yoda.jpg',
//   type: 'image',
//   x: 0,
//   y: 0,
//   width: 213,
//   height: 236,
// };

/**
  * @param completed - if true, then we skip onboarding
  * @param step - holds the current step index
*/
const defaultState = {
  objects: [],
  config,
  state,
};

const prefix = 'SCENE_';

export const addObject = createAction(`${prefix}ADD_OBJECT`);
export const addObjects = createAction(`${prefix}ADD_OBJECTS`);
export const setBackground = createAction(`${prefix}SET_BACKGROUND`);
export const addText = createAction(`${prefix}ADD_TEXT`);
export const addClipart = createAction(`${prefix}ADD_CLIPART`);
export const updateIsEditing = createAction(`${prefix}UPDATE_IS_EDITING`);
export const updateTextEditing = createAction(`${prefix}UPDATE_TEXT_EDITING`);
export const setDesign = createAction(`${prefix}SET_DESIGN`);
export const setLayout = createAction(`${prefix}SET_LAYOUT`);
export const addDropZone = createAction(`${prefix}ADD_DROP_ZONE`);
export const unsetBackground = createAction(`${prefix}UNSET_BACKGROUND`);
export const setImage = createAction(`${prefix}SET_IMAGE`);
export const updateObject = createAction(`${prefix}UPDATE_OBJECT`);
export const updateDropZonePosition = createAction(`${prefix}UPDATE_DROP_ZONE_POSITION`);
export const updateObjectByIndex = createAction(`${prefix}UPDATE_OBJECT_BY_INDEX`);
export const updateScene = createAction(`${prefix}UPDATE_SCENE`);
export const removeObject = createAction(`${prefix}REMOVE_OBJECT`);
export const clearStage = createAction(`${prefix}CLEAR_STAGE`);
export const setObjects = createAction(`${prefix}SET_OBJECTS`);
export const updateConfig = createAction(`${prefix}UPDATE_CONFIG`);
export const updateState = createAction(`${prefix}UPDATE_STATE`);
export const deleteImage = createAction(`${prefix}DELETE_IMAGE`);
export const setScene = createAction(`${prefix}SET_SCENE`);
export const setEdgeWrap = createAction(`${prefix}SET_EDGE_WRAP`);
export const addTextArrToTextObjects = createAction(`${prefix}ADD_TEXT_ARR`);

const objectDefaults = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
};

const reducer = handleActions({
  [addObject]: (store, { payload }) => {
    const { id: imageId, src, uploaded, type = 'image', initialized = false, naturalHeight, naturalWidth, ...rest } = payload;
    if (store.config.mode === 'photo-print') {
      if (!store.config.printLimitMax || store.config.printLimitMax > store.objects.length) {
        const id = uuid();
        return {
          ...store,
          objects: [...store.objects, {
            ...objectDefaults,
            ...{ id, imageId, naturalHeight, naturalWidth, rotate: naturalHeight > naturalWidth, src, uploaded, type, initialized, ...rest },
          }],
        };
      }
      return store;
    }
    if (find(store.objects, { id: imageId })) {
      return store;
    }
    return {
      ...store,
      objects: [{
        ...objectDefaults,
        ...{ id: imageId, src, uploaded, type, initialized, naturalHeight, naturalWidth, ...rest },
      }],
    };
  },
  [addObjects]: (store, { payload }) => {
    if (store.config.mode === 'photo-print') {
      const newObjects = payload;
      const photoCount = sumBy(store.objects, (o) => o.quantity || 1);
      newObjects.length = Math.min(store.config.printLimitMax - photoCount, newObjects.length);
      const newObjectsMapped = newObjects.map((photo) => {
        const { id: imageId, src, uploaded, type = 'image', initialized = false, naturalHeight, naturalWidth, ...rest } = photo;
        const id = uuid();
        return { ...objectDefaults, id, imageId, rotate: naturalHeight > naturalWidth, src, uploaded, type, initialized, ...rest };
      });
      return {
        ...store,
        objects: [...store.objects, ...newObjectsMapped],
      };
    }
    return store;
  },
  [setBackground]: (store, { payload }) => {
    let { objects } = store;
    if (objects) {
      const id = uuid();
      const { id: imageId, naturalHeight, naturalWidth } = payload;
      const image = { ...defaultImage, id: imageId, initialized: false, naturalHeight, naturalWidth, width: naturalWidth, height: naturalHeight };
      const object = initImage({ id, x: 0, y: 0, width: 1, height: 1, type: 'background', image }, store.config);
      const newStore = store;
      const backgroundIndex = findIndex(objects, { type: 'background' });
      if (!(backgroundIndex + 1)) {
        objects = [object, ...objects];
        newStore.objects = objects;
      }
      newStore.objects[0].image = object.image;
      newStore.objects = [...newStore.objects];
      return newStore;
    }
    return store;
  },
  [addClipart]: (store, { payload }) => {
    const { objects, config: { dimensions: { width, height } }, config } = store;
    const { id: imageId, naturalHeight, naturalWidth } = payload;
    if (objects) {
      const id = uuid();
      const image = { ...defaultImage, id: imageId, initialized: false, naturalHeight, naturalWidth, width: naturalWidth, height: naturalHeight, rotation: 0 };
      const shapeWidth = min([width, height]) * 0.5;
      const shapeHeight = shapeWidth * (naturalHeight / naturalWidth);
      const { x, y } = shapeToCenter(shapeWidth, shapeHeight, config);
      const object = initImage({ id, x, y, rotation: 0, width: shapeWidth, height: shapeHeight, type: 'clipart', image }, config);
      const newStore = store;
      newStore.objects = [...objects, object];
      newStore.config.isEditing = id;
      newStore.config.isCrop = false;
      newStore.config.textEditing = false;
      return newStore;
    }
    return store;
  },
  [addDropZone]: (store) => {
    const { objects, config: { dimensions: { width, height } }, config } = store;
    if (objects) {
      const id = uuid();
      const shapeWidth = min([width, height]) * 0.5;
      const { x, y } = shapeToCenter(shapeWidth, shapeWidth, config);
      const object = { id, x, y, rotation: 0, width: shapeWidth, height: shapeWidth, type: 'drop-zone', image: null };
      const newStore = store;
      newStore.objects = [...objects, object];
      newStore.config.isEditing = id;
      newStore.config.isCrop = false;
      newStore.config.textEditing = false;
      return newStore;
    }
    return store;
  },
  [setDesign]: (store, { payload }) => {
    const newPayload = cloneDeep(payload);
    const { objects } = store;
    const images = objects.map((object) => ((object.type === 'drop-zone' || object.type === 'layout') && object.image)).filter(Boolean);
    if (objects) {
      const newStore = store;
      newStore.objects = newPayload?.objects?.map((object) => {
        if (object.type === 'drop-zone') {
          delete object.image;
          if (images.length) {
            return initImage({ ...object, image: images.pop() }, store.config);
          }
        }
        return object;
      });
      newStore.config.designId = newPayload.id;
      if (newPayload.currentBackground) newStore.config.currentBackground = newPayload.currentBackground;
      newStore.config.productMode = 'designs';
      newStore.config.layoutId = '';
      if (newStore.config.edgeWrap) {
        newStore.config.edgeWrap = 'nowrap';
      }
      return merge(newStore, { config: { isEditing: false, textEditing: false, isCrop: false } });
    }
    return store;
  },
  [setLayout]: (store, { payload }) => {
    const newConfig = merge({}, store.config, { layoutId: payload.layoutId, productMode: 'layout' });
    let newObjects = store.objects;
    const images = newObjects.map((object) => ((object.type === 'drop-zone' || object.type === 'layout') && object.image)).filter(Boolean);
    if (store.config.productMode === 'designs' && newConfig.productMode === 'layout' && !newConfig.admin) {
      newObjects = filter(newObjects, (element) => element.type === 'background');
      newConfig.designId = '';
    }
    if (newConfig.productMode === 'layout' && newConfig.mode) {
      if (newConfig.mode === 'photo-print') {
        newConfig.currentLayout = calculateCurrentLayout(newConfig, newObjects);
      } else {
        newObjects = calculateCurrentLayout(newConfig, newObjects);
      }
      if (newConfig.admin) {
        newConfig.layoutId = '';
      }
    }
    newObjects = newObjects?.map((object) => {
      if (images.length && object.type === 'layout') {
        return initImage({ ...object, image: images.pop() }, store.config);
      }
      return object;
    });
    return { ...store, objects: newObjects, config: newConfig };
  },
  [addTextArrToTextObjects]: (store) => {
    const { objects } = store;
    const newObjects = objects.map((object) => {
      if (isText(object) && !isEmptyShape(object)) {
        const textArr = flattenDeep(DomHelper.getTextLinesFromDomElement(document.querySelector(`.text${object.id}`)));
        return { ...object, textArr };
      }
      return object;
    });
    return { ...store, objects: newObjects };
  },
  [addText]: (store) => {
    const { objects, config, config: { dimensions: { width, height }, safeMarginText, orientation } } = store;
    if (objects) {
      const id = uuid();
      const currentWidth = (orientation === 'portrait') ? height : width;
      const shapeWidth = (currentWidth - 2 * safeMarginText) * 0.85;
      const fontSize = parseInt(shapeWidth / 12, 10);
      const { x, y } = shapeToCenter(shapeWidth, fontSize, config);
      const newStore = store;
      const text = '';
      const textObject = { id, x, y, width: shapeWidth, height: 1, rotation: 0, fontSize, fontFamily: 'ABeeZee', text, type: 'text', fill: '#000000', textArr: [], textAlign: 'center' };
      newStore.objects = [...objects, textObject];

      // set text is editing
      newStore.config.isEditing = id;
      newStore.config.isCrop = false;
      newStore.config.textEditing = false;
      return newStore;
    }
    return store;
  },
  [unsetBackground]: (store) => {
    const { objects } = store;
    if (objects) {
      let newStore = store;
      remove(newStore.objects, { type: 'background' });
      newStore.objects = [...newStore.objects];
      if (!find(newStore.objects, { id: newStore.config.isEditing })) newStore = merge(newStore, { config: { isEditing: false, textEditing: false, isCrop: false } });
      return newStore;
    }
    return store;
  },
  [setImage]: (store, { payload }) => {
    if (payload?.image) {
      const { id: imageId, naturalHeight, naturalWidth } = payload.image;
      const image = { ...defaultImage, id: imageId, initialized: false, naturalHeight, naturalWidth, width: naturalWidth, height: naturalHeight };
      return {
        ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }),
        objects: store.objects.map((object) => {
          if (object.id === payload.id) {
            return initImage({ ...object, image }, store.config);
          }
          return object;
        }),
      };
    }
    return store;
  },
  [updateObject]: (store, { payload }) => ({
    ...store,
    objects: store.objects.map((object) => {
      if (object && (object.id === payload.id || object.imageId === payload.id || (object.image && object.image.id === payload.id))) {
        const oldRatio = object.width / object.height;
        const newObject = store.config.mode === 'photo-print'
        ? merge(object, { ...payload.modifier.image, id: object.id, imageId: payload.modifier.image.id || object.image })
        : merge(object, { ...payload.modifier });
        if (payload.modifier.text) {
          newObject.text = payload.modifier.text;
        }
        const newRatio = newObject.width / newObject.height;
        if (newObject.image && ((Math.abs(oldRatio - newRatio) > 0.0000005) || newObject.image.initialized === false)) { // if changed ratio recalculate image
          return initImage(newObject, store.config);
        }
        return newObject;
      }
      return object;
    }),
  }),
  [updateDropZonePosition]: (store, { payload }) => ({
    ...store,
    objects: store.objects.map((object) => {
      if (object && object.type === 'drop-zone' && (object.id === payload.id)) {
        const minWidthHeight = 5;
        const newObject = merge(cloneDeep(object), { ...payload.modifier });
        if (newObject.width < minWidthHeight || isNaN(newObject.width)) newObject.width = minWidthHeight;
        if (newObject.height < minWidthHeight || isNaN(newObject.height)) newObject.height = minWidthHeight;
        if (isNaN(newObject.x)) newObject.x = 0;
        if (isNaN(newObject.y)) newObject.y = 0;

        const { dimensions, mode, edgeWrap } = store.config;
        let { frame } = dimensions;
        if (mode !== 'canvas' || edgeWrap !== EDGE_WRAP) { frame = 0; }
        const allowArea = { x: frame, y: frame, width: dimensions.width + frame, height: dimensions.height + frame };
        const corners = getCorners(newObject);
        const [draw] = checkIsDraw({ ...corners, allowArea });
        if (!draw) return object;

        const oldRatio = object.width / object.height;
        const newRatio = newObject.width / newObject.height;
        if (newObject.image && ((Math.abs(oldRatio - newRatio) > 0.0000005) || newObject.image.initialized === false)) { // if changed ratio recalculate image
          return initImage(newObject, store.config);
        }
        return newObject;
      }
      return object;
    }),
  }),
  [updateObjectByIndex]: (store, { payload }) => ({
    ...store,
    objects: store.objects.map((object, index) => {
      if (index === payload.index || (object && object.imageId === payload.imageId)) {
        return {
          ...object,
          ...payload.modifier,
        };
      }
      return object;
    }),
  }),
  [updateScene]: (state, action) => {
    const { addFields, replace, updatePreview } = action.payload;
    const objects = state.objects.map((object) => {
      const ifFound = (obj) => (object && obj.id && (obj.id === object.id)) || (object && obj.imageId && (obj.imageId === object.imageId));
      let addFieldsObject = find(addFields, ifFound);
      if (updatePreview && (object.uploaded || object.image?.uploaded)) addFieldsObject = false; // if photo uploaded not replace preview
      const replaceObject = find(replace, ifFound);
      if (replaceObject) return replaceObject;
      if (addFieldsObject) return { ...object, ...addFieldsObject };
      return object;
    });
    return ({ ...state, objects });
  },

  [removeObject]: (store, { payload }) => ({ ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }), objects: store.objects.filter(({ id, type }) => payload.id !== id || type === 'layout') }),
  [clearStage]: (store) => ({ ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }), objects: [...defaultState.objects] }),
  [setObjects]: (store, { payload }) => ({ ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }), objects: payload.map((o) => (o ? { ...objectDefaults, ...o } : null)) }),
  [updateConfig]: (store, { payload }) => {
    const newConfig = merge({}, store.config, payload);
    let newObjects = store.objects;
    if (store.config.productMode === 'designs' && newConfig.productMode === 'layout' && !newConfig.admin) {
      newObjects = [];
      newConfig.designId = '';
    }
    if (newConfig.productMode === 'layout' && newConfig.mode) {
      if (newConfig.mode === 'photo-print') {
        newConfig.currentLayout = calculateCurrentLayout(newConfig, newObjects);
      } else {
        newObjects = calculateCurrentLayout(newConfig, newObjects);
      }
      if (newConfig.admin) {
        newConfig.layoutId = '';
      }
    }
    newObjects && newObjects.forEach((object, index) => {
      if (object.image
        && (object.image.initialized === false || store.config.productId !== newConfig.productId || store.config.orientation !== newConfig.orientation || store.config.edgeWrap !== newConfig.edgeWrap)) {
        newObjects[index] = initImage(object, newConfig);
      }
    });
    return { ...store, objects: newObjects, config: newConfig };
  },
  [updateIsEditing]: (store, { payload }) => {
    // if (store.config.isEditing !== payload.isEditing)
    store.config.isEditing = payload.isEditing;
    if (!payload.isEditing) {
      store.config.textEditing = false;
      store.config.isCrop = false;
    }
    return store;
  },
  [updateTextEditing]: (store, { payload }) => {
    // if (store.config.isEditing !== payload.isEditing)
    store.config.textEditing = payload.textEditing;
    return store;
  },
  [deleteImage]: (store, { payload }) => ({ ...merge(store, { config: { isEditing: false, textEditing: false, isCrop: false } }),
  objects: store.objects.map((object) => {
    const { id, image, imageId } = object;
    if (image?.id === payload.id) return { ...object, image: null };
    if (imageId === payload.id || id === payload.id) return null; // for photo print
    return object;
  }).filter(Boolean) }),
  [updateState]: (store, { payload }) => {
    const newState = merge({}, store.state, payload);
    if (newState.sceneContainerWidthPx < 500 && newState.sceneContainerWidthPx === store.state.sceneContainerWidthPx && newState.sceneContainerHeightPx < store.state.sceneContainerHeightPx) {
      newState.sceneContainerHeightPx = store.state.sceneContainerHeightPx;
      newState.sceneHeightPx = store.state.sceneHeightPx;
    }
    return { ...store, state: newState };
  },
  [setScene]: (store, { payload }) => (merge({}, store, payload)),
  [setEdgeWrap]: (store, { payload }) => {
    if (payload.edgeWrap !== store.config.edgeWrap) {
      const newConfig = merge({}, store.config, payload);
      return {
        ...store,
        config: newConfig,
        objects: store.objects.map((object) => {
        if (object && object.type === 'layout') {
          if (object.image) return initImage({ ...object }, newConfig);
          return object;
        }
        if (newConfig.edgeWrap === EDGE_WRAP) {
          return { ...object, x: object.x + newConfig.dimensions.frame, y: object.y + newConfig.dimensions.frame };
        }
        return { ...object, x: object.x - newConfig.dimensions.frame, y: object.y - newConfig.dimensions.frame };
      }) };
    }
    return store;
  },
}, defaultState);

export default reducer;
