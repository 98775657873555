export default [
  {
    value: 'ABeeZee',
    label: 'ABeeZee',
  },
  {
    value: 'Abel',
    label: 'Abel',
  },
  {
    value: 'AbrilFatface',
    label: 'Abril Fatface',
  },
  {
    value: 'Acme',
    label: 'Acme',
  },
  {
    value: 'Actor',
    label: 'Actor',
  },
  {
    value: 'Adamina',
    label: 'Adamina',
  },
  {
    value: 'AdventPro',
    label: 'Advent Pro',
  },
  {
    value: 'Aladin',
    label: 'Aladin',
  },
  {
    value: 'Aldrich',
    label: 'Aldrich',
  },
  {
    value: 'Alef',
    label: 'Alef',
  },
  {
    value: 'Alegreya',
    label: 'Alegreya',
  },
  {
    value: 'AlegreyaSC',
    label: 'Alegreya SC',
  },
  {
    value: 'AlexBrush',
    label: 'Alex Brush',
  },
  {
    value: 'AlfaSlabOne',
    label: 'Alfa Slab One',
  },
  {
    value: 'Alice',
    label: 'Alice',
  },
  {
    value: 'Alike',
    label: 'Alike',
  },
  {
    value: 'Allan',
    label: 'Allan',
  },
  {
    value: 'Allerta',
    label: 'Allerta',
  },
  {
    value: 'Allura',
    label: 'Allura',
  },
  {
    value: 'Amarante',
    label: 'Amarante',
  },
  {
    value: 'Amaranth',
    label: 'Amaranth',
  },
  {
    value: 'AmaticSC',
    label: 'Amatic SC',
  },
  {
    value: 'Amethysta',
    label: 'Amethysta',
  },
  {
    value: 'Andada',
    label: 'Andada',
  },
  {
    value: 'Andika',
    label: 'Andika',
  },
  {
    value: 'AnnieUseYourTelescope',
    label: 'Annie Use Your Telescope',
  },
  {
    value: 'AnonymousPro',
    label: 'Anonymous Pro',
  },
  {
    value: 'Antic',
    label: 'Antic',
  },
  {
    value: 'AnticDidone',
    label: 'Antic Didone',
  },
  {
    value: 'AnticSlab',
    label: 'Antic Slab',
  },
  {
    value: 'Anton',
    label: 'Anton',
  },
  {
    value: 'Arapey',
    label: 'Arapey',
  },
  {
    value: 'Arbutus',
    label: 'Arbutus',
  },
  {
    value: 'ArbutusSlab',
    label: 'Arbutus Slab',
  },
  {
    value: 'ArchitectsDaughter',
    label: 'Architects Daughter',
  },
  {
    value: 'ArchivoBlack',
    label: 'Archivo Black',
  },
  {
    value: 'ArchivoNarrow',
    label: 'Archivo Narrow',
  },
  {
    value: 'Arimo',
    label: 'Arimo',
  },
  {
    value: 'Arizonia',
    label: 'Arizonia',
  },
  {
    value: 'Armata',
    label: 'Armata',
  },
  {
    value: 'Artifika',
    label: 'Artifika',
  },
  {
    value: 'Asul',
    label: 'Asul',
  },
  {
    value: 'AtomicAge',
    label: 'Atomic Age',
  },
  {
    value: 'Audiowide',
    label: 'Audiowide',
  },
  {
    value: 'AutourOne',
    label: 'Autour One',
  },
  {
    value: 'Average',
    label: 'Average',
  },
  {
    value: 'BadScript',
    label: 'Bad Script',
  },
  {
    value: 'Balthazar',
    label: 'Balthazar',
  },
  {
    value: 'Bangers',
    label: 'Bangers',
  },
  {
    value: 'Baumans',
    label: 'Baumans',
  },
  {
    value: 'Belleza',
    label: 'Belleza',
  },
  {
    value: 'BenchNine',
    label: 'Bench Nine',
  },
  {
    value: 'Bentham',
    label: 'Bentham',
  },
  {
    value: 'BerkshireSwash',
    label: 'Berkshire Swash',
  },
  {
    value: 'Bevan',
    label: 'Bevan',
  },
  {
    value: 'Bilbo',
    label: 'Bilbo',
  },
  {
    value: 'BilboSwashCaps',
    label: 'Bilbo Swash Caps',
  },
  {
    value: 'Bitter',
    label: 'Bitter',
  },
  {
    value: 'Boogaloo',
    label: 'Boogaloo',
  },
  {
    value: 'BreeSerif',
    label: 'BreeSerif',
  },
  {
    value: 'BubblegumSans',
    label: 'Bubblegum Sans',
  },
  {
    value: 'BubblerOne',
    label: 'BubblerOne',
  },
  {
    value: 'Buenard',
    label: 'Buenard',
  },
  {
    value: 'ButterflyKids',
    label: 'Butterfly Kids',
  },
  {
    value: 'CabinCondensed',
    label: 'Cabin Condensed',
  },
  {
    value: 'CabinSketch',
    label: 'Cabin Sketch',
  },
  {
    value: 'CaesarDressing',
    label: 'Caesar Dressing',
  },
  {
    value: 'Calligraffitti',
    label: 'Calligraffitti',
  },
  {
    value: 'Cambo',
    label: 'Cambo',
  },
  {
    value: 'Candal',
    label: 'Candal',
  },
  {
    value: 'Cantarell',
    label: 'Cantarell',
  },
  {
    value: 'CantoraOne',
    label: 'Cantora One',
  },
  {
    value: 'Capriola',
    label: 'Capriola',
  },
  {
    value: 'Cardo',
    label: 'Cardo',
  },
  {
    value: 'CarterOne',
    label: 'CarterOne',
  },
  {
    value: 'ChauPhilomeneOne',
    label: 'Chau Philomene One',
  },
  {
    value: 'ChelaOne',
    label: 'ChelaOne',
  },
  {
    value: 'ChelseaMarket',
    label: 'Chelsea Market',
  },
  {
    value: 'Chewy',
    label: 'Chewy',
  },
  {
    value: 'Chicle',
    label: 'Chicle',
  },
  {
    value: 'Chivo',
    label: 'Chivo',
  },
  {
    value: 'CinzelDecorative',
    label: 'Cinzel Decorative',
  },
  {
    value: 'ClickerScript',
    label: 'Clicker Script',
  },
  {
    value: 'Coda',
    label: 'Coda',
  },
  {
    value: 'Combo',
    label: 'Combo',
  },
  {
    value: 'Comfortaa',
    label: 'Comfortaa',
  },
  {
    value: 'Condiment',
    label: 'Condiment',
  },
  {
    value: 'ContrailOne',
    label: 'Contrail One',
  },
  {
    value: 'Convergence',
    label: 'Convergence',
  },
  {
    value: 'Cookie',
    label: 'Cookie',
  },
  {
    value: 'Copse',
    label: 'Copse',
  },
  {
    value: 'Corben',
    label: 'Corben',
  },
  {
    value: 'Courgette',
    label: 'Courgette',
  },
  {
    value: 'CraftyGirls',
    label: 'Crafty Girls',
  },
  {
    value: 'Damion',
    label: 'Damion',
  },
  {
    value: 'DancingScript',
    label: 'Dancing Script',
  },
  {
    value: 'DaysOne',
    label: 'DaysOne',
  },
  {
    value: 'Delius',
    label: 'Delius',
  },
  {
    value: 'DeliusSwashCaps',
    label: 'Delius Swash Caps',
  },
  {
    value: 'DidactGothic',
    label: 'Didact Gothic',
  },
  {
    value: 'Domine',
    label: 'Domine',
  },
  {
    value: 'DoppioOne',
    label: 'Doppio One',
  },
  {
    value: 'Dosis',
    label: 'Dosis',
  },
  {
    value: 'EBGaramond',
    label: 'EB Garamond',
  },
  {
    value: 'EagleLake',
    label: 'Eagle Lake',
  },
  {
    value: 'Elsie',
    label: 'Elsie',
  },
  {
    value: 'EmilysCandy',
    label: 'Emilys Candy',
  },
  {
    value: 'Enriqueta',
    label: 'Enriqueta',
  },
  {
    value: 'Ewert',
    label: 'Ewert',
  },
  {
    value: 'Exo',
    label: 'Exo',
  },
  {
    value: 'FanwoodText',
    label: 'Fanwood Text',
  },
  {
    value: 'FasterOne',
    label: 'Faster One',
  },
  {
    value: 'FaunaOne',
    label: 'Fauna One',
  },
  {
    value: 'Felipa',
    label: 'Felipa',
  },
  {
    value: 'FingerPaint',
    label: 'Finger Paint',
  },
  {
    value: 'FjallaOne',
    label: 'Fjalla One',
  },
  {
    value: 'Flamenco',
    label: 'Flamenco',
  },
  {
    value: 'Fondamento',
    label: 'Fondamento',
  },
  {
    value: 'FreckleFace',
    label: 'Freckle Face',
  },
  {
    value: 'FrederickatheGreat',
    label: 'Frederickathe Great',
  },
  {
    value: 'Fresca',
    label: 'Fresca',
  },
  {
    value: 'Frijole',
    label: 'Frijole',
  },
  {
    value: 'FugazOne',
    label: 'Fugaz One',
  },
  {
    value: 'Gabriela',
    label: 'Gabriela',
  },
  {
    value: 'Gafata',
    label: 'Gafata',
  },
  {
    value: 'Galdeano',
    label: 'Galdeano',
  },
  {
    value: 'GentiumBasic',
    label: 'Gentium Basic',
  },
  {
    value: 'GildaDisplay',
    label: 'Gilda Display',
  },
  {
    value: 'GiveYouGlory',
    label: 'Give You Glory',
  },
  {
    value: 'GlassAntiqua',
    label: 'Glass Antiqua',
  },
  {
    value: 'GloriaHallelujah',
    label: 'Gloria Hallelujah',
  },
  {
    value: 'GochiHand',
    label: 'Gochi Hand',
  },
  {
    value: 'Gorditas',
    label: 'Gorditas',
  },
  {
    value: 'GoudyBookletter1911',
    label: 'Goudy Bookletter1911',
  },
  {
    value: 'Graduate',
    label: 'Graduate',
  },
  {
    value: 'Gruppo',
    label: 'Gruppo',
  },
  {
    value: 'Habibi',
    label: 'Habibi',
  },
  {
    value: 'Handlee',
    label: 'Handlee',
  },
  {
    value: 'HappyMonkey',
    label: 'Happy Monkey',
  },
  {
    value: 'HennyPenny',
    label: 'Henny Penny',
  },
  {
    value: 'Imprima',
    label: 'Imprima',
  },
  {
    value: 'Inconsolata',
    label: 'Inconsolata',
  },
  {
    value: 'IndieFlower',
    label: 'Indie Flower',
  },
  {
    value: 'Inika',
    label: 'Inika',
  },
  {
    value: 'Italianno',
    label: 'Italianno',
  },
  {
    value: 'JacquesFrancois',
    label: 'Jacques Francois',
  },
  {
    value: 'JollyLodger',
    label: 'Jolly Lodger',
  },
  {
    value: 'JosefinSans',
    label: 'Josefin Sans',
  },
  {
    value: 'JosefinSlab',
    label: 'Josefin Slab',
  },
  {
    value: 'JuliusSansOne',
    label: 'Julius Sans One',
  },
  {
    value: 'Junge',
    label: 'Junge',
  },
  {
    value: 'Jura',
    label: 'Jura',
  },
  {
    value: 'JustAnotherHand',
    label: 'Just Another Hand',
  },
  {
    value: 'KaushanScript',
    label: 'Kaushan Script',
  },
  {
    value: 'Kavoon',
    label: 'Kavoon',
  },
  {
    value: 'KellySlab',
    label: 'Kelly Slab',
  },
  {
    value: 'KiteOne',
    label: 'Kite One',
  },
  {
    value: 'KronaOne',
    label: 'KronaOne',
  },
  {
    value: 'LaBelleAurore',
    label: 'LaBelle Aurore',
  },
  {
    value: 'Ledger',
    label: 'Ledger',
  },
  {
    value: 'LifeSavers',
    label: 'Life Savers',
  },
  {
    value: 'LindenHill',
    label: 'Linden Hill',
  },
  {
    value: 'LoveYaLikeASister',
    label: 'Love YaLike ASister',
  },
  {
    value: 'Macondo',
    label: 'Macondo',
  },
  {
    value: 'Marcellus',
    label: 'Marcellus',
  },
  {
    value: 'MarckScript',
    label: 'Marck Script',
  },
  {
    value: 'Marmelad',
    label: 'Marmelad',
  },
  {
    value: 'McLaren',
    label: 'McLaren',
  },
  {
    value: 'MedulaOne',
    label: 'MedulaOne',
  },
  {
    value: 'Merienda',
    label: 'Merienda',
  },
  {
    value: 'MeriendaOne',
    label: 'MeriendaOne',
  },
  {
    value: 'Merriweather',
    label: 'Merriweather',
  },
  {
    value: 'Monoton',
    label: 'Monoton',
  },
  {
    value: 'Montez',
    label: 'Montez',
  },
  {
    value: 'MysteryQuest',
    label: 'Mystery Quest',
  },
  {
    value: 'Neucha',
    label: 'Neucha',
  },
  {
    value: 'Neuton',
    label: 'Neuton',
  },
  {
    value: 'NewRocker',
    label: 'New Rocker',
  },
  {
    value: 'Nobile',
    label: 'Nobile',
  },
  {
    value: 'Nosifer',
    label: 'Nosifer',
  },
  {
    value: 'NothingYouCouldDo',
    label: 'Nothing You Could Do',
  },
  {
    value: 'Nunito',
    label: 'Nunito',
  },
  {
    value: 'OpenSans',
    label: 'OpenSans',
  },
  {
    value: 'Oregano',
    label: 'Oregano',
  },
  {
    value: 'Overlock',
    label: 'Overlock',
  },
  {
    value: 'OverlockSC',
    label: 'OverlockSC',
  },
  {
    value: 'Ovo',
    label: 'Ovo',
  },
  {
    value: 'Paprika',
    label: 'Paprika',
  },
  {
    value: 'Parisienne',
    label: 'Parisienne',
  },
  {
    value: 'PatrickHand',
    label: 'Patrick Hand',
  },
  {
    value: 'PatrickHandSC',
    label: 'Patrick HandSC',
  },
  {
    value: 'PatuaOne',
    label: 'Patua One',
  },
  {
    value: 'PermanentMarker',
    label: 'Permanent Marker',
  },
  {
    value: 'Philosopher',
    label: 'Philosopher',
  },
  {
    value: 'PoiretOne',
    label: 'Poiret One',
  },
  {
    value: 'Pompiere',
    label: 'Pompiere',
  },
  {
    value: 'PontanoSans',
    label: 'Pontano Sans',
  },
  {
    value: 'ProstoOne',
    label: 'Prosto One',
  },
  {
    value: 'PurplePurse',
    label: 'Purple Purse',
  },
  {
    value: 'Quando',
    label: 'Quando',
  },
  {
    value: 'Radley',
    label: 'Radley',
  },
  {
    value: 'Raleway',
    label: 'Raleway',
  },
  {
    value: 'Rancho',
    label: 'Rancho',
  },
  {
    value: 'Risque',
    label: 'Risque',
  },
  {
    value: 'Ruluko',
    label: 'Ruluko',
  },
  {
    value: 'RumRaisin',
    label: 'Rum Raisin',
  },
  {
    value: 'Salsa',
    label: 'Salsa',
  },
  {
    value: 'SansitaOne',
    label: 'Sansita One',
  },
  {
    value: 'Schoolbell',
    label: 'Schoolbell',
  },
  {
    value: 'ShadowsIntoLight',
    label: 'Shadows Into Light',
  },
  {
    value: 'Shojumaru',
    label: 'Shojumaru',
  },
  {
    value: 'ShortStack',
    label: 'Short Stack',
  },
  {
    value: 'Simonetta',
    label: 'Simonetta',
  },
  {
    value: 'Slackey',
    label: 'Slackey',
  },
  {
    value: 'Snippet',
    label: 'Snippet',
  },
  {
    value: 'SofadiOne',
    label: 'Sofadi One',
  },
  {
    value: 'Spinnaker',
    label: 'Spinnaker',
  },
  {
    value: 'SueEllenFrancisco',
    label: 'SueEllen Francisco',
  },
  {
    value: 'Sunshiney',
    label: 'Sunshiney',
  },
  {
    value: 'SwankyandMooMoo',
    label: 'Swankyand Moo Moo',
  },
  {
    value: 'TheGirlNextDoor',
    label: 'TheGirl Next Door',
  },
  {
    value: 'Ubuntu',
    label: 'Ubuntu',
  },
  {
    value: 'Unkempt',
    label: 'Unkempt',
  },
  {
    value: 'WaitingfortheSunrise',
    label: 'Waitingforthe Sunrise',
  },
  {
    value: 'WendyOne',
    label: 'WendyOne',
  },
  {
    value: 'WireOne',
    label: 'WireOne',
  },
  {
    value: 'Yellowtail',
    label: 'Yellowtail',
  },
];
