import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateObject } from 'actions/scene';

const useUpdateObject = () => {
  const dispatch = useDispatch();
  return useCallback(({ id, ...modifier }) => dispatch(updateObject({ id, modifier })), [dispatch]);
};

export default useUpdateObject;
