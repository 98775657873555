import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import './Modal.css';
import { faSave } from '@fortawesome/pro-solid-svg-icons';

const InputOption = ({ label, value, onChange }) => (
  <div style={{ marginTop: '15px' }}>
    <div style={{ fontSize: '1.3em' }}>{label}:</div>
    <div style={{ marginLeft: 'auto', width: '150px', marginRight: 'auto' }}>
      <input
        style={{ width: '150px' }}
        min="1"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </div>
  </div>
);

const SaveModal = ({ onClose, onConfirm, confirmText = 'Save', closeButtonText = 'Cancel' }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  return (
    <div className="modal order">
      <h2 className="modal-header">
        <div className="modal-header-text">
          Save as..
        </div>
      </h2>
      <div className="modal-text">
        <InputOption value={title} label="Title" onChange={setTitle} />
        <InputOption value={description} label="Short description" onChange={setDescription} />
      </div>
      <div className="buttons">

        <Button
          color="azure"
          rounded
          size="lg"
          onClick={() => onConfirm({ title, description })}
        >
          <FontAwesomeIcon.Memo icon={faSave} style={{ height: '1em', width: '1em', marginRight: '0.8em' }} />
          {confirmText}
        </Button>
        <Button
          outline
          rounded
          size="lg"
          onClick={onClose}
        >
          {closeButtonText}
        </Button>
      </div>
    </div>
);
  };


export default SaveModal;
