import he from 'he';
import { inchesToMm } from 'utils/number';
import exportToSvg from 'utils/svg-export';
import find from 'lodash/find';
import first from 'lodash/first';
import { setLayout, updateConfig } from 'actions/scene';
import store from 'store';
import { EDGE_WRAP, EDGE_NOWRAP, SINGLE_ONLY, MULTI_ONLY } from 'constants/editor';
import { defaultColors } from 'api/colors';
import { layouts, fullScreenLayout } from './layouts';

const selectLayouts = (enableMultiLayout, layoutMode) => {
  if (layoutMode) {
    if (layoutMode === SINGLE_ONLY) {
      return fullScreenLayout;
    }
    if (layoutMode === MULTI_ONLY) {
      return layouts;
    }
    return [...fullScreenLayout, ...layouts];
  }
  if (enableMultiLayout) {
    return layouts;
  }
  return fullScreenLayout;
};

export const processSizes = (sizes, enableMultiLayout, layoutMode, defaultBackground, backgroundOptions,
  layoutGutterType, showLayoutGutterSwitch, printLimitMin, printLimitMax, borderType) => sizes.map((size) => {
  let height = inchesToMm(size.value.dimensions.height);
  let width = inchesToMm(size.value.dimensions.width);
  let { xLabel, yLabel } = size.value;
  const widthLessThanHeight = width < height; // width is always wider than height
  if (widthLessThanHeight) {
    [width, height] = [height, width];
    [xLabel, yLabel] = [yLabel, xLabel];
  }
  return ({
    ...size,
    description: he.decode(size.description),
    value: {
      ...size.value,
      dimensions: {
        width,
        height,
      },
      layouts: selectLayouts(enableMultiLayout, layoutMode),
      xLabel,
      yLabel,
      overlay3dUrl: size.value.overlay3dUrl || '',
      scaleOverlay: size.value.scaleOverlay || 1,
      scaleOverlay3d: size.value.scaleOverlay3d || 1,
      borderType: borderType || 'no-border',
      lamination: 'gloss',
      backgroundOptions: backgroundOptions || defaultColors,
      currentBackground: defaultBackground || find(defaultColors, { default: true }) || first(defaultColors),
      layoutGutterType,
      showLayoutGutterSwitch,
      printLimitMin,
      printLimitMax,
    },
  });
});

const downloadS = (scene, download) => {
  const svgString = exportToSvg(scene);
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  if (download === 'string') { return svgString; }

  if (download) {
    const a = document.createElement('a');
    a.download = `${new Date().getTime()}.svg`;
    a.type = 'image/svg+xml';
    a.href = url;
    a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  } else {
    window.open(url, '_blank');
  }
  return null;
};

export const downloadSvg = (scene, download, renderMask) => {
  const { objects, config: { mode } } = scene;
  if (mode === 'photo-print') {
    return objects.map((object) => {
      const newScene = { ...scene };
      const { quantity = 1 } = object;
      newScene.config.orientation = object.rotate ? 'portrait' : 'landscape';
      newScene.objects = [object];
      return { svg: downloadS(newScene, download), quantity };
    });
  }
  const newScene = { ...scene };
  newScene.config.renderMask = renderMask;
  return downloadS(newScene, download);
};


export const setLayoutId = (layoutId, layouts, objects, edgeWrap, updateConfig, mode) => {
  const currentLayout = find(layouts, { id: layoutId });
  if (currentLayout) {
    // store.dispatch(setObjects(take(objects, currentLayout.layout.length))); // remove unused by layout images
    if ((currentLayout.layout.length > 1 && edgeWrap === EDGE_WRAP) || mode === 'poster') updateConfig({ edgeWrap: EDGE_NOWRAP });// if it has more than 1 image,remove edge wrap
    store.dispatch(setLayout({ layoutId: currentLayout.id })); // set new layout id to config
  } else console.warn('unknown size option with id ', layoutId);
};

export const smartSetLayoutId = (layoutId, mode) => {
  const { scene: { config: { layouts, layoutId: currentLayoutId, edgeWrap } }, scene: { objects } } = store.getState();
  const updateConfigValues = (values) => store.dispatch(updateConfig(values));
  if (layoutId !== currentLayoutId) setLayoutId(layoutId, layouts, objects, edgeWrap, updateConfigValues, mode);
};

export const setCurrentBackground = (value) => {
  const { scene: { config: { backgroundOptions } } } = store.getState();
  const newValues = find(backgroundOptions, { value });
  newValues && store.dispatch(updateConfig({ currentBackground: newValues }));
};
