import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useCurrentLayout from 'hooks/useCurrentLayout';

const useUpdateObjectByIndex = () => {
  const [borderType, dimensions] = useSelector(({ scene: { config: { borderType, dimensions } } }) => [borderType, dimensions], (prev, curr) => prev[0] === curr[0] && prev[1] === curr[1]);
  const [apertures] = useCurrentLayout();
  return useCallback(({ image }) => {
    let aperture = apertures[0];
    if (image.rotate) {
      aperture = { ...aperture, x: aperture.y, y: aperture.x, width: aperture.height, height: aperture.width };
      if (borderType === 'retro') {
        aperture.height -= (dimensions.retroBottom / dimensions.width);
      }
    } else if (borderType === 'retro') {
      aperture = { ...aperture };
      aperture.height -= (dimensions.retroBottom / dimensions.height);
    }
    return aperture;
  }, [apertures, dimensions.height, dimensions.retroBottom, dimensions.width, borderType]);
};

export default useUpdateObjectByIndex;
