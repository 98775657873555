import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
// import { useSelector } from 'react-redux';
import './Footer.css';
import CloseIcon from 'assets/X.svg';
import useToolbar from 'hooks/useToolbar';
import { useIsEditing } from 'components/editors/Editor';
import { motion } from 'framer-motion';

const ToolbarItem = ({ children, onClick, isActive, icon: Icon }) => (
  <li>
    <button type="button" onClick={onClick} className={classnames({ active: isActive })}>
      <div className="button-content">
        <div className="toolbar-icon">
          <Icon />
        </div>
        {children}
      </div>
    </button>
  </li>
);

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 100 },
};

const Wrapper = ({ item, handleClose, style, animate }) => (item ? (
  <motion.div className={`toolbar-view-header ${item.id}`} initial="closed" style={style} variants={variants} animate={animate}>
    <span>{item.title}</span>
    <img role="presentation" alt="Close" src={CloseIcon} onClick={handleClose} />
  </motion.div>
) : null);

const Footer = ({ items }) => {
  // const scene = useSelector((state) => state.scene);
  const { openedPageFooter: selectedItem, openFooter, closeFooter } = useToolbar();
  const [{ canDrop }] = useDrop({
    accept: 'image',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });
  const style = useMemo(() => {
    if (canDrop) {
      return { display: 'none' };
    }
    return {};
  }, [canDrop]);
  const [isEditing] = useIsEditing();
  const animate = useCallback((id) => (selectedItem !== null && selectedItem === id && !isEditing ? 'open' : 'closed'), [isEditing, selectedItem]);
  const toolbarViewStyle = useCallback((id) => ({ ...selectedItem !== id && { pointerEvents: 'none' }, ...style }), [selectedItem, style]);
  return (
    <footer>
      <div className={classnames('toolbar', selectedItem)} style={{ ...isEditing && { display: 'none' } }}>
        {items.map(({ id, component: C, ...rest }) => (
          <>
            <Wrapper item={items.find((item) => item.id === selectedItem)} handleClose={closeFooter} style={style} animate={animate(id)} />
            <motion.div className={classnames('toolbar-view', selectedItem)} style={toolbarViewStyle(id)} variants={variants} animate={animate(id)}>
              <C key={id} id={id} isActive={selectedItem === id} {...rest} />
            </motion.div>
          </>
        ))}
        <div className="phantom-div" />
        <motion.div className="toolbar-inner" variants={variants} animate={!isEditing ? 'open' : 'closed'}>
          <ul>
            {items.map(({ id, title, ...rest }) => (
              <ToolbarItem
                key={id}
                {...rest}
                isActive={selectedItem === id}
                onClick={() => (selectedItem === id ? closeFooter() : openFooter(id))}
              >
                {title}
              </ToolbarItem>
            ))}
          </ul>
        </motion.div>
      </div>
    </footer>
  );
};
export default Footer;
