import React, { useEffect } from 'react';
import Scene from 'containers/Scene';
import PixaPrints from 'api';
import { open, close } from 'actions/modal';
import store from 'store';
// import useOnboarding from 'hooks/useOnboarding';
// import PhotoImport from 'containers/PhotoImport';
// import last from 'lodash/last';

// const onboardingComponentsQueue = [];
const onClose = () => store.dispatch(close());

const showError = (error) => store.dispatch(open({ type: 'loading', modalProps: { onClose: () => { onClose(); error.onDismiss && error.onDismiss(); }, success: false, message: error.fakeMessage || 'Initializing error', cancellable: error.dismissible } }));

const waitForErrorDismiss = (error) => new Promise((resolve) => {
  const oldOnDismiss = error.onDismiss || (() => { });
  error.onDismiss = () => { oldOnDismiss(); resolve(); };
  showError(error);
});

const initApp = async (setLoading) => {
  setLoading(true);
  try { await PixaPrints.fetchSavedScene(); } catch (error) { await waitForErrorDismiss(error); }
  try { await PixaPrints.fetchEditorConfig(); } catch (error) { await waitForErrorDismiss(error); }
  setLoading(false);
};

/* const getComponentForStep = (step) => {
  const component = onboardingComponentsQueue[step];
  if (!component) return last(onboardingComponentsQueue);
  return component;
}; */

const Editor = ({ loading, setLoading }) => {
  // const [step, completed, next] = useOnboarding(onboardingComponentsQueue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { initApp(setLoading).catch(showError); }, []);
  // const OnboardingStep = React.useMemo(() => getComponentForStep(step), [step]);
  return (
    !loading && (<Scene />)
    // completed ? (<Scene />) : (<OnboardingStep next={next} />)
  );
};

export default Editor;
