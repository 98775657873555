import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import classnames from 'classnames';

export const Loading = ({ children, loading }) => (
  <LoadingOverlay
    active={loading}
    spinner
    text="Loading..."
    fadeSpeed={0}
    className={classnames('loading-wrapper', loading && 'loading')}
  > {children}
  </LoadingOverlay>
);
export default Loading;
