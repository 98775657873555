import React, { useCallback } from 'react';
import useUpdateConfig from 'hooks/useUpdateConfig';
import { useSelector } from 'react-redux';
import { borderTypeOptions } from 'constants/editor';
import VerticalSelect from 'components/common/VerticalSelect';
import './Borders.scss';


const Borders = () => {
  const updateConfig = useUpdateConfig();
  const borderType = useSelector(({ scene: { config: { borderType } } }) => borderType);
  const setBorder = useCallback((borderType) => updateConfig({ borderType }), [updateConfig]);
  return (
    <div className="borders-container">
      <div className="border-button">
        <VerticalSelect
          options={borderTypeOptions}
          onChange={setBorder}
          value={borderType}
          onlyInnerDelimiters
          preservePaddingForCheck
        />
      </div>
    </div>
  );
};

export default Borders;
