import React from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal';
import LoadingModal from './LoadingModal';
import OrderModal from './OrderModal';
import WarningModal from './WarningModal';
import SaveModal from './SaveModal';
import DeleteModal from './DeleteModal';

export const ConfirmModal = (props) => {
  const { isMobile } = props;
  const { isOpen, type, modalProps } = useSelector(({ modal }) => modal || {});

  return (
    <Modal isOpen={isOpen} isMobile={isMobile}>
      {type === 'loading' ? <LoadingModal {...modalProps} /> : null}
      {type === 'order' ? <OrderModal {...modalProps} /> : null}
      {type === 'warning' ? <WarningModal {...modalProps} /> : null}
      {type === 'save-design' ? <SaveModal {...modalProps} /> : null}
      {type === 'delete-design' ? <DeleteModal {...modalProps} /> : null}
    </Modal>
  );
};

export default ConfirmModal;
