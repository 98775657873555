import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import useIsMobile from 'hooks/useIsMobile';

const toolbarViewWidth = 265;

const useEditorSizePx = (viewWidth) => {
  const dimensionsRatio = useSelector(({ scene: { config: { borderType, dimensions: { width, height, halfBleed } } } }) => (
      borderType !== 'no-border' ? height / width : (height + 2 * halfBleed) / (width + 2 * halfBleed)
    ), shallowEqual);
  const isMobile = useIsMobile();
  const dimensionsPx = useMemo(
    () => {
      let width = viewWidth;
      if (isMobile) {
        width *= 0.44;
      } else {
        width -= toolbarViewWidth;
        width *= 0.2;
      }
      return { width, height: width * dimensionsRatio };
    },
    [dimensionsRatio, isMobile, viewWidth],
  );
  return dimensionsPx;
};

export default useEditorSizePx;
